import { createContext, FC, MutableRefObject, PropsWithChildren, useContext, useRef } from "react";
import PQueue from "p-queue";
import pTimeout from "p-timeout";

const TASK_TIMEOUT = 15_000;
/**
 * This context is responsible for tracking focussed states and the side effects it causes.
 * Basically, ensure everything is handled correctly on focus/un-focus.
 *
 * Every task has a maximum duration after which it is cancelled. This is to prevent one hanging process blocking the entire submit.
 */
export const FocusProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const focussed = useRef<string>();
  const focussedFieldQueue = useRef<PQueue>(new PQueue({ concurrency: 10 }));

  const addTask = <T extends any>(task: Promise<T>): Promise<T> => {
    focussedFieldQueue.current?.add(() => pTimeout(task, { milliseconds: TASK_TIMEOUT }));
    return task;
  };

  return (
    <FocusContext.Provider
      value={{
        focussed,
        onIdle: () => focussedFieldQueue.current.onIdle(),
        addTask,
      }}
    >
      {children}
    </FocusContext.Provider>
  );
};

interface FocusState {
  focussed: MutableRefObject<string | undefined>;
  onIdle: () => Promise<void>;
  addTask: <T>(task: Promise<T>) => Promise<T>;
}

export const FocusContext = createContext<FocusState>({} as FocusState);
export const useFocussedField = (): FocusState => useContext(FocusContext);
