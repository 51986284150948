import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetHeader from "./WidgetHeader";

export interface WidgetBorderlessHeaderProperties {
  header: string;
  size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const WidgetBorderlessHeader: Widget<WidgetBorderlessHeaderProperties, WidgetResult<void>> = (props) => {
  const field = {
    uid: props.field.uid,
    widget: props.field.widget,
    properties: {
      label_text: props.field.properties.header,
      size: props.field.properties.size,
    },
  };

  return <WidgetHeader {...props} field={field} />;
};

WidgetBorderlessHeader.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "borderlessHeader",
    ...defaultMeta,
  },
});

WidgetBorderlessHeader.validate = (): undefined => undefined;

export default WidgetBorderlessHeader;
