import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import HtmlContent from "../HtmlContent";
import WidgetError from "./WidgetError";

export interface WidgetRichTextProperties {
  html_code?: string;
  markdown_code?: string;
}

const WidgetRichText: Widget<WidgetRichTextProperties, WidgetResult<void>> = (props) => {
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetRichText.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field) || !props.field.properties.html_code) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return <HtmlContent html={props.field.properties.html_code} />;
};

WidgetRichText.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "richText",
    ...defaultMeta,
  },
});

WidgetRichText.validate = (): undefined => undefined;

export default WidgetRichText;
