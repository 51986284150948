import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import HtmlContent from "../HtmlContent";
import WidgetError from "./WidgetError";

export interface WidgetInstructionRichTextProperties {}

const WidgetInstructionRichText: Widget<WidgetInstructionRichTextProperties, WidgetResult<string>> = (props) => {
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetInstructionRichText.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field) || !field.result?.rawValue) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return <HtmlContent html={field.result?.rawValue} />;
};

WidgetInstructionRichText.defaultValue = (_field, defaultMeta: any): WidgetResult<string> => ({
  type: "string",
  meta: {
    widget: "instructionRichText",
    ...defaultMeta,
  },
});

WidgetInstructionRichText.validate = (): undefined => undefined;

export default WidgetInstructionRichText;
