import { ChangeEvent, FC, MutableRefObject, Ref, useRef } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash-es";
import { AnimatePresence, motion } from "framer-motion";
import { Label } from "../Label/Label";
import { Feedback } from "../Feedback/Feedback";
import { Icon } from "../Icon/Icon";

export interface CheckboxOption {
  value: string;
  label: string;
}

export interface CheckboxListProps {
  className?: string;
  name: string;
  label: string;
  ariaLabel: string;
  placeholder?: string;
  required?: boolean;
  value?: string[];
  disabled?: boolean;
  expanded?: boolean;
  options: CheckboxOption[];
  scroll?: boolean;
  onChange: (result: string[]) => void;
  onBtnClick?: () => void;
  onClear?: () => void;
  clearLabel?: string;
  errorMessage?: string;
  inputRef?: Ref<any>;
}

export const CheckboxList: FC<CheckboxListProps> = ({
  required = false,
  name,
  label = "",
  ariaLabel,
  placeholder = "",
  value = [],
  disabled = false,
  expanded = false,
  options = [],
  onChange = (): void => {},
  onBtnClick = (): void => {},
  onClear,
  clearLabel,
  errorMessage = "",
  className,
  inputRef,
  ...props
}) => {
  const optionWrapperRef: MutableRefObject<any> = useRef();
  const firstOptionRef: MutableRefObject<any> = useRef();

  const isSelected = (option: CheckboxOption): boolean => value.includes(option.value);

  const getLabelClasses = (option: CheckboxOption): string =>
    classNames(
      "group relative flex min-h-12 max-w-full flex-1 select-none rounded-lg border-1.5 p-3",
      isSelected(option) && {
        "text-gray-500": disabled,
        "peer-focus-visible:border-brand-400": !disabled,
      },

      !isSelected(option) && {
        "text-gray-500": true,
        "hover:text-gray-600 active:text-gray-700 bg-white border-gray-200 hover:border-gray-300 active:border-brand-300 active:bg-brand-100":
          !disabled,
      },
      disabled
        ? { "border-gray-300 bg-gray-100 cursor-not-allowed": true }
        : {
            "cursor-pointer": true,
            "pointer:first-of-type:peer-focus-visible:border-brand-400": !value,
          },
    );

  const getCheckboxClasses = (option: CheckboxOption): string =>
    classNames(
      "mr-4 mt-1 size-4 shrink-0 rounded border-1.5",
      isSelected(option) && {
        "border-brand-500 bg-brand-500 group-active:border-gray-200 group-active:bg-white": !disabled,
        "border-gray-500 bg-gray-500": disabled,
      },
      !isSelected(option) && {
        "border-gray-300": true,
        "bg-gray-100": disabled,
      },
    );

  const getBtnClasses = (): string =>
    classNames(
      "group relative flex w-full items-start justify-between rounded-lg bg-gray-100 py-2 pl-4 pr-5 text-left outline-none ring-inset",
      errorMessage && "border border-red-500 bg-red-100 ",
    );

  const optionWrapperClasses = classNames("flex flex-col gap-y-1");

  const onSelected = (e: ChangeEvent<HTMLInputElement>): void => {
    const checkboxValue = e.target.value;
    const isChecked = e.target.checked;

    let updatedValue = [...value];

    if (isChecked) {
      if (!updatedValue.includes(checkboxValue)) {
        updatedValue = [...updatedValue, checkboxValue];
      }
    } else {
      updatedValue = updatedValue.filter((item) => item !== checkboxValue);
    }
    onChange(updatedValue);
  };

  return (
    <div className={classNames(className, "relative")} ref={inputRef} {...props}>
      {(label || label === "") && (
        <Label
          as="legend"
          showClearBtn={!isEmpty(value) && !disabled}
          onClear={onClear}
          clearLabel={clearLabel}
          label={label}
          required={required}
          clickFocus={firstOptionRef}
        >
          <div className={optionWrapperClasses} ref={optionWrapperRef}>
            <button type="button" aria-label={ariaLabel} className={getBtnClasses()} onClick={onBtnClick}>
              {isEmpty(value) ? (
                <span className="min-w-0 truncate break-words leading-loose text-gray-400">{placeholder}</span>
              ) : (
                <span
                  className={classNames("min-w-0 truncate break-words leading-loose", {
                    "text-gray-400": expanded,
                    "text-gray-700": !expanded,
                  })}
                >
                  {value?.map((v) => options.find((o) => o.value === v)?.label).join(", ") ?? ""}
                </span>
              )}

              {!expanded && <Icon name="ChevronDownIcon" className="ml-2 mt-1 shrink-0 text-gray-700" />}
              {expanded && <Icon name="ChevronUpIcon" className="ml-2 mt-1 shrink-0 text-gray-700" />}
            </button>
            <AnimatePresence initial={false}>
              {expanded &&
                options.map((option, i) => (
                  <motion.div
                    className="flex w-full"
                    key={option.value}
                    initial={{ opacity: 0 }}
                    transition={{ ease: [0.0, 0.0, 0.3, 1], duration: 0.25 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { ease: [0.4, 0.14, 1, 1], duration: 0.25 } }}
                  >
                    <input
                      aria-label={option.label}
                      className="peer size-0 border-none opacity-0"
                      type="checkbox"
                      id={`${name}-${i}`}
                      disabled={disabled}
                      name={name}
                      value={option.value}
                      checked={isSelected(option)}
                      onChange={onSelected}
                      tabIndex={(i === 0 && !value) || isSelected(option) ? 0 : -1}
                    />
                    <label
                      className={getLabelClasses(option)}
                      htmlFor={`${name}-${i}`}
                      {...(i === 0 && { ref: firstOptionRef })}
                    >
                      <span className={getCheckboxClasses(option)}>
                        <Icon name="CheckIcon" className="pointer-events-none size-full text-white" />
                      </span>
                      <span className="min-w-0 break-words text-gray-500">{option.label}</span>
                    </label>
                  </motion.div>
                ))}
            </AnimatePresence>
          </div>
          {errorMessage && <Feedback status="error" message={errorMessage} />}
        </Label>
      )}
    </div>
  );
};
