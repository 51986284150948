import { TFunction } from "i18next";
import { ChangeEvent, FocusEvent, Ref, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useController,
  UseControllerReturn,
  useFormContext,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { defaults, isEmpty, isNil } from "lodash-es";
import { RxDocument } from "rxdb";
import { noop } from "rxjs";
import { SubformEntry } from "../components/widgets/WidgetSubform";
import logger from "../utils/logger";
import useDeviceInfo from "./useDeviceInfo";
import { Field, rxToForm, UploadStatus, WidgetResult } from "../types/Field";
import { findInvalidEntries, getCalculatedFieldId, getEntriesWithError } from "../utils/formUtil";
import { FormField } from "../types/FormVersion";
import { SubmissionFormData } from "../components/Form";
import { WidgetContext, WidgetEntryProp } from "../types/Widget";
import useAuth from "./useAuth";
import { useFocussedField } from "../context/FocusContext";
import { getNestedEntries } from "../utils/submissionUtil";
import { compress } from "../utils/compressUtil";
import { isFieldChanged } from "../utils/fieldUtil";

type SaveOptions<T> = {
  onChange: "set" | "persist" | "none";
  onBlur: "persist" | "none" | "persist-on-unmount";
  valueFormat?: (value?: WidgetResult<T>) => any;
  shouldPersist?: (value?: WidgetResult<T>) => boolean;
};

export type ControlledField<T> = {
  props: {
    name: string;
    value?: any;
    errorMessage?: string;
    onChange: (e: ChangeEvent<any>) => void;
    onBlur: (e: FocusEvent<any, any>) => void;
    onFocus: (e: FocusEvent<any, any>) => void;
  };
  inputRef: Ref<any>;
  controller: UseControllerReturn;
  result?: T;
};

type ValidateFn<T, P> = (
  value: T | undefined,
  properties: P,
  t: TFunction,
  meta: WidgetResult<T>["meta"],
  entries?: SubformEntry<unknown>[],
) => string | undefined;

export const INITIAL_DATE = new Date(0).toISOString();
export type PersistOptions = {
  humanEdit?: boolean;
  shouldWait?: boolean;
};

export type PersistWithUploadStatusFn<T> = (
  rawValue?: T,
  uploadStatus?: UploadStatus,
  options?: PersistOptions,
) => Promise<void>;

export type WidgetHelpers<T> = {
  getValues: UseFormGetValues<SubmissionFormData>;
  trigger: UseFormTrigger<SubmissionFormData>;
  persist: (rawValue?: T, options?: PersistOptions) => Promise<void>;
  persistWithUploadStatus: PersistWithUploadStatusFn<T>;
  setValue: (rawValue?: T) => void;
  addEntry: (entry: SubformEntry<any>) => Promise<void>;
  updateEntry: (entry: SubformEntry<unknown>) => Promise<void>;
  removeEntry: (entry: SubformEntry<unknown>) => Promise<void>;
};

const useWidget = <T, P>(
  context: WidgetContext,
  formField: FormField<any>,
  validator: ValidateFn<T, P>,
  saveMode: SaveOptions<T> = {
    onChange: "set",
    onBlur: "persist",
  },
  fieldRx?: RxDocument<Field>,
  entry?: WidgetEntryProp,
  // This option is used to compress the rawValue. This is only tested with the drawing widget
  compressData: boolean = false,
): {
  isDisabled: boolean;
  helpers: WidgetHelpers<T>;
  field: ControlledField<WidgetResult<T>>;
} => {
  const { authorization } = useAuth();
  const { t } = useTranslation();
  const { focussed, addTask } = useFocussedField();
  const { control, setValue, getValues, trigger } = useFormContext<SubmissionFormData>();
  const { id: deviceId } = useDeviceInfo();
  const fieldId = getCalculatedFieldId(formField.uid, context.submission.id, entry?.id, entry?.parentId);
  const isValid = (value?: WidgetResult<T>): string | undefined => {
    if (!value || value.meta.hidden || context.validationMode === "disabled") {
      return undefined;
    }
    return validator(value.rawValue, formField.properties, t, value.meta, value.entries);
  };

  const isValidEntries = async (value?: WidgetResult<T>): Promise<string | undefined> => {
    const invalidEntries = await validateEntries(context.submission.id, value);
    return invalidEntries ? JSON.stringify(invalidEntries) : undefined;
  };
  const controller = useController({
    name: fieldId,
    control,
    rules: { validate: { validation: isValid, entries: isValidEntries } },
  });

  const isFinalized = context.submission?.status === "final" || fieldRx?.status === "final" || context.readOnly;
  const isDisabled =
    context.submission?.status === "final" ||
    !isNil(context.submission?.submittedAt) ||
    fieldRx?.status === "final" ||
    (context.readOnly ?? false);

  const persistField = async (rawValue?: T, options?: PersistOptions): Promise<void> => {
    const persistOptions: PersistOptions = defaults(options, { humanEdit: true, shouldWait: true });
    return persistOptions.shouldWait ? addTask(persist(rawValue, persistOptions)) : persist(rawValue, persistOptions);
  };

  const unfocus = (): void => {
    if (focussed.current === fieldId) {
      focussed.current = undefined;
    }
  };

  useBeforeUnmount(saveMode, fieldId, persistField, controller, unfocus, getValues, setValue, fieldRx, isFinalized);

  const persistWithUploadStatus = async (
    rawValue?: T,
    uploadStatus?: UploadStatus,
    options?: PersistOptions,
  ): Promise<void> => {
    const current = getValues(fieldId);
    if (!isFinalized) {
      controller.field.onChange({
        ...current,
        rawValue,
        meta: { ...current.meta, uploadStatus },
        updatedAt: new Date().toISOString(),
      });
    }
    if (isFinalized || !fieldRx) {
      return;
    }
    await trigger(fieldId);
    await fieldRx.incrementalPatch({
      updatedAt: new Date().toISOString(),
      deviceId,
      error: isValid(getValues(fieldId)) ?? (await isValidEntries(getValues(fieldId))),
      uploadStatus,
    });
    await persistField(rawValue, options);
  };

  const persist = async (rawValue?: T, options?: PersistOptions): Promise<void> => {
    if (!isFinalized) {
      controller.field.onChange({
        ...getValues(fieldId),
        rawValue,
        updatedAt: new Date().toISOString(),
      });
    }
    if (isFinalized || !fieldRx) {
      return;
    }
    if (options?.humanEdit && context.setHumanEdited) {
      context.setHumanEdited(true);
    }
    await trigger(fieldId);
    const shouldCompress = compressData && !isNil(rawValue); // Do not compress null or undefined data
    await fieldRx.incrementalPatch({
      updatedAt: new Date().toISOString(),
      data: shouldCompress ? compress(rawValue) : rawValue,
      deviceId,
      error: isValid(getValues(fieldId)) ?? (await isValidEntries(getValues(fieldId))),
      compressed: shouldCompress,
      ...(options?.humanEdit ? { updatedBy: authorization.userId } : {}),
    });
  };

  const mutateEntry = async (subformEntry: SubformEntry<unknown>, remove = false): Promise<void> => {
    if (fieldRx) {
      const entries = fieldRx
        .getLatest()
        .entries?.map((e) =>
          e.id === subformEntry.id ? { ...subformEntry, ...(remove ? { deleted: true } : {}) } : e,
        ) as [SubformEntry<any>];
      const { rawValue, meta } = controller.field.value;
      const error = validator(rawValue, formField.properties, t, meta, entries);
      await fieldRx.incrementalPatch({
        entries,
        deviceId,
        updatedAt: new Date().toISOString(),
        error,
      });
      setValue(fieldId, { ...getValues(fieldId), entries });
    } else {
      logger.warn(`Tried to modify a non-existing entry with id ${subformEntry.id}`);
    }
  };

  const onChange = (): ((e: ChangeEvent<any>) => void) => {
    switch (saveMode.onChange) {
      case "none":
        return noop;
      case "persist":
        return async (e: ChangeEvent<any>) => {
          controller.field.onBlur();
          controller.field.onChange({
            ...getValues(fieldId),
            rawValue: e.target.value,
          });
          await persistField(e.target.value);
        };
      case "set":
      default:
        return (e: ChangeEvent<any>) => {
          controller.field.onChange({
            ...controller.field.value,
            rawValue: e.target.value,
          });
        };
    }
  };

  const onBlur = (): (() => void) => {
    switch (saveMode.onBlur) {
      case "persist-on-unmount":
      case "none":
        return () => {
          unfocus();
        };
      case "persist":
      default:
        return async () => {
          controller.field.onBlur();
          try {
            await persistField(controller.field.value?.rawValue);
          } finally {
            unfocus();
          }
        };
    }
  };

  const validateEntries = async (submissionId: string, value?: WidgetResult<T>): Promise<string[] | undefined> => {
    if (!value || isEmpty(value?.entries)) {
      // Only check nested entries if available
      return undefined;
    }

    const fields = await context.fieldsCollection?.find().where("submissionId").eq(submissionId).exec();
    if (fields && value.entries) {
      // Get all entries that are invalid, to show in the UI
      const allErrors = getEntriesWithError(fields);
      // Only return error entries that belong to this subform/pin widget
      const invalidEntries = findInvalidEntries(value.entries, allErrors);
      if (invalidEntries && invalidEntries?.length > 0) {
        return invalidEntries;
      }
    }
    return undefined;
  };

  return {
    isDisabled,
    helpers: {
      persist: persistField,
      persistWithUploadStatus,
      setValue: (rawValue?: T): void => {
        controller.field.onChange({
          ...controller.field.value,
          rawValue: rawValue || undefined,
        });
      },
      addEntry: async (subformEntry): Promise<void> => {
        if (isFinalized) {
          return;
        }
        if (context.setHumanEdited) {
          context.setHumanEdited(true);
        }
        if (fieldRx) {
          const { rawValue, meta } = controller.field.value;

          // Revalidate based on new entries
          const error = validator(rawValue, formField.properties, t, meta, [
            ...fieldRx.getLatest().entries,
            subformEntry,
          ]);

          await fieldRx.incrementalModify((oldData) => ({
            ...oldData,
            updatedAt: new Date().toISOString(),
            entries: [...oldData.entries, subformEntry],
            error,
            updatedBy: authorization.userId,
            deviceId,
          }));
        }
      },
      updateEntry: async (subformEntry): Promise<void> => {
        if (isFinalized) {
          return;
        }
        await mutateEntry(subformEntry);
      },
      removeEntry: async (subformEntry): Promise<void> => {
        if (isFinalized) {
          return;
        }
        const fields = await context.fieldsCollection?.find().where("submissionId").eq(context.submission.id).exec();

        if (fields) {
          const nestedEntryIds = getNestedEntries(fields, subformEntry.id);
          await context.fieldsCollection?.find().where("entryId").in(nestedEntryIds).remove();
        }
        await mutateEntry(subformEntry, true);
      },
      getValues,
      trigger,
    },
    field: {
      controller,
      props: {
        errorMessage: controller.fieldState.error?.message,
        onChange: onChange(),
        onBlur: onBlur(),
        onFocus: (): void => {
          focussed.current = fieldId;
        },
        name: controller.field.name,
        value: saveMode.valueFormat ? saveMode.valueFormat(controller.field.value) : controller.field.value,
      },
      inputRef: controller.field.ref,
      result: controller.field.value,
    },
  };
};

const useBeforeUnmount = <T>(
  saveMode: SaveOptions<T>,
  fieldId: string,
  persistField: (rawValue?: T, options?: PersistOptions) => Promise<void>,
  controller: UseControllerReturn<SubmissionFormData, string>,
  unfocus: () => void,
  getValues: UseFormGetValues<SubmissionFormData>,
  setValue: UseFormSetValue<SubmissionFormData>,
  fieldRx?: RxDocument<Field>,
  isFinalized?: boolean,
): void => {
  useEffect(
    () => (): void => {
      if (saveMode.onBlur === "none" || !fieldRx || isFinalized) {
        return;
      }

      const formValue = getValues(fieldId);

      // Check optional function to avoid invalid widget state
      if (saveMode.shouldPersist && !saveMode.shouldPersist(formValue)) {
        // Revert to original state to avoid mismatching states
        setValue(fieldId, rxToForm(fieldRx.getLatest()));
        return;
      }
      const latestField = fieldRx.getLatest();
      if (isFieldChanged(latestField, formValue)) {
        persistField(formValue.rawValue)
          .catch((e) =>
            logger.error("Couldn't persist value for field", e, { extra: { fieldName: controller.field.name } }),
          )
          .finally(() => {
            unfocus();
          });
      }
    },
    [fieldId, fieldRx, getValues], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export default useWidget;
