import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { toIsoDate } from "../../utils/dateUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { DateTimeInput } from "../../storybook/components/DateTimeInput/DateTimeInput";

export interface WidgetDateProperties {
  required: boolean;
  label_text: string;
  now_as_default: boolean;
}

const WidgetDate: Widget<WidgetDateProperties, WidgetResult<string>> = (props) => {
  const { t } = useTranslation();

  const { helpers, isDisabled, field } = useWidget(
    props.context,
    props.field,
    WidgetDate.validate,
    { onChange: "none", onBlur: "none", valueFormat: (value) => ({ date: value?.rawValue }) },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("DATE_FIELD")}`}>
      <DateTimeInput
        {...field.props}
        inputRef={field.inputRef}
        type="date"
        name={props.field.uid}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        disabled={isDisabled}
        showClearBtn={!!field.result?.rawValue && !isDisabled}
        clearLabel={t("CLEAR")}
        onClear={async () => {
          await helpers.persist(undefined);
        }}
        onChange={async (value) => {
          await helpers.persist(value.date);
          field.controller.field.onBlur();
        }}
      />
    </article>
  );
};

WidgetDate.defaultValue = (field, defaultMeta: any): WidgetResult<string> => ({
  type: "date",
  rawValue: field.properties.now_as_default ? toIsoDate(new Date()) : undefined,
  meta: {
    widget: "date",
    ...defaultMeta,
  },
});

WidgetDate.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetDate;
