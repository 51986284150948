/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { FileResult, Widget } from "../../types/Widget";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { validateUpload } from "../../utils/validationUtil";
import { WidgetResult } from "../../types/Field";
import FileUploadButton from "../FileUploadButton";
import WidgetError from "./WidgetError";
import FileFeedback from "./file/FileFeedback";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";

export interface WidgetFileProperties {
  allowed_file_type: string;
  label_text: string;
  required: boolean;
}

const WidgetFile: Widget<WidgetFileProperties, WidgetResult<FileResult>> = (props) => {
  const { t } = useTranslation();
  const { allowed_file_type, required, label_text } = props.field.properties;
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
  const { field, helpers, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetFile.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("FILE_FIELD")}`}>
      <FileUploadButton
        labelText={label_text}
        required={required}
        allowedMimeType={allowed_file_type}
        isDisabled={isDisabled}
        readOnly={!!props.context.readOnly}
        helpers={helpers}
        field={field}
        submission={props.context.submission}
        showPermissionsModal={() => setShowPermissionsModal(true)}
      />
      <FileFeedback field={field} />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setShowPermissionsModal(false)} />
      )}
    </article>
  );
};

WidgetFile.defaultValue = (_field, defaultMeta: any): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "file",
    ...defaultMeta,
  },
});

WidgetFile.validate = (val, properties, t, meta): string | undefined => {
  if (properties.required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return validateUpload(val, meta.uploadStatus, t);
};

export default WidgetFile;
