import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFormVersion } from "../../../utils/pinUtil";
import TemplateContent from "../../TemplateContent";
import { widgetResultByDataName } from "../../../utils/formUtil";
import { ControlledField, WidgetHelpers } from "../../../hooks/useWidget";
import { WidgetResult } from "../../../types/Field";
import { DeleteIntent, FileResult, PinResultMeta, WidgetContext } from "../../../types/Widget";
import { PinConfig } from "../WidgetPin";
import { SubformEntry } from "../WidgetSubform";
import { WidgetContentButton } from "../../../storybook/components/WidgetContentButton/WidgetContentButton";
import { MenuItem } from "../../../storybook/components/DropdownMenu/DropdownMenu";
import { Modal } from "../../../storybook/components/Modal/Modal";
import { sortEntries } from "../../../utils/entryUtil";

interface PinEntriesProps {
  field: ControlledField<WidgetResult<FileResult>>;
  context: WidgetContext;
  pins: PinConfig[];
  helpers: WidgetHelpers<FileResult>;
  isDisabled: boolean;
  onEdit: (value: SubformEntry<PinResultMeta>) => void;
  onMove: (value: SubformEntry<PinResultMeta>) => void;
  onDelete: (value: DeleteIntent) => void;
}

const PinEntries: FC<PinEntriesProps> = ({ field, context, pins, helpers, isDisabled, onEdit, onMove, onDelete }) => {
  const { t } = useTranslation();
  const [removeEntry, setRemoveEntry] = useState<DeleteIntent | undefined>();
  const getPinConfig = (formId: string): PinConfig | undefined =>
    pins.find((pinConf) => formId === pinConf.target_form_id || formId === pinConf.form?.uid);

  const getOptionalEditButton = (pin: SubformEntry<PinResultMeta>): { label: string; onClick: () => void }[] =>
    pin.meta?.scope?.target ? [{ label: t("OPEN"), onClick: () => onEdit(pin) }] : [];

  return (
    <>
      <div className="mt-2 flex flex-col space-y-2">
        {(field.result?.entries || [])
          .filter((entry) => !entry.deleted)
          .sort(sortEntries)
          .map((pin, index) => {
            const fieldError = field.controller.fieldState.error;
            const entryError = fieldError?.type === "entries" && fieldError.message?.indexOf(pin.id) !== -1;
            const pinFormVersion = getFormVersion(pin.meta.scope.target, pins, context.fieldProperties);

            return (
              <WidgetContentButton
                key={index}
                appearance={entryError ? "danger" : "default"}
                items={[
                  ...getOptionalEditButton(pin),
                  ...(!isDisabled
                    ? ([
                        { label: t("MOVE"), onClick: () => onMove(pin) },
                        {
                          label: t("DELETE"),
                          onClick: () => setRemoveEntry({ id: pin.id, type: "DELETE_BUTTON" }),
                          variant: "destructive",
                        },
                      ] as MenuItem[])
                    : []),
                ]}
              >
                <span className="flex" data-testid="pin-entry-description">
                  {`${pin.meta.order}.`}&nbsp;
                  <TemplateContent
                    template={getPinConfig(pin.meta.scope.target)?.itemMarkup || ""}
                    inputData={widgetResultByDataName(
                      pinFormVersion?.fields ?? [],
                      helpers.getValues(),
                      context.submission.id,
                      pin.id,
                      field.result?.meta.fieldId,
                    )}
                    submissionId={context.submission.id}
                    allowNewLines
                  />
                </span>
              </WidgetContentButton>
            );
          })}
      </div>
      {removeEntry && (
        <Modal
          title={removeEntry?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_TITLE") : t("PIN_DELETE_MODAL_TITLE")}
          content={{
            kind: "message",
            message:
              removeEntry?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_DESCRIPTION") : t("PIN_DELETE_MODAL_DESCRIPTION"),
          }}
          open
          onClose={() => setRemoveEntry(undefined)}
          buttons={[
            { label: t("CANCEL"), onClick: () => setRemoveEntry(undefined) },
            {
              label: t("DELETE"),
              variant: "destructive",
              onClick: (): void => {
                onDelete(removeEntry);
                setRemoveEntry(undefined);
              },
            },
          ]}
        />
      )}
    </>
  );
};
export default PinEntries;
