import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { to24hTime } from "../../utils/dateUtil";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { DateTimeInput } from "../../storybook/components/DateTimeInput/DateTimeInput";

export interface WidgetTimeProperties {
  required: boolean;
  label_text: string;
  now_as_default: boolean;
}

const WidgetTime: Widget<WidgetTimeProperties, WidgetResult<string>> = (props) => {
  const { t } = useTranslation();

  const { field, isDisabled, helpers } = useWidget(
    props.context,
    props.field,
    WidgetTime.validate,
    { onChange: "persist", onBlur: "persist", valueFormat: (value) => ({ time: value?.rawValue }) },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("TIME_FIELD")}`}>
      <DateTimeInput
        {...field.props}
        inputRef={field.inputRef}
        type="time"
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        disabled={isDisabled}
        showClearBtn={!!field.result?.rawValue && !isDisabled}
        clearLabel={t("CLEAR")}
        onClear={() => helpers.persist(undefined)}
        onChange={(value) => helpers.persist(value.time)}
        onBlur={field.controller.field.onBlur}
      />
    </article>
  );
};

WidgetTime.defaultValue = (field, defaultMeta): WidgetResult<string> => ({
  type: "time",
  rawValue: field.properties.now_as_default ? to24hTime(new Date()) : undefined,
  meta: {
    widget: "time",
    ...defaultMeta,
  },
});

WidgetTime.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return undefined;
};

export default WidgetTime;
