import { useTranslation } from "react-i18next";
import { LocationResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetLocation from "./WidgetLocation";
import useWidget from "../../hooks/useWidget";
import { isFieldVisible } from "../../utils/formUtil";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { Label } from "../../storybook/components/Label/Label";

export interface WidgetCurrentLocationProperties {
  required: boolean;
  label_text: string;
}

const WidgetCurrentLocation: Widget<WidgetCurrentLocationProperties, WidgetResult<LocationResult>> = (props) => {
  const { t } = useTranslation();
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetLocation.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  const locationField = {
    uid: props.field.uid,
    widget: props.field.widget,
    properties: {
      ...props.field.properties,
      initial_current_location: true,
    },
  };

  /**
   * The Current location widget is saved as a String in the backend.
   * So we can only show the formatted string when the form is Sent/copied, as all the other data is unavailable.
   *
   * When location is available or empty, we can continue to the normal Location widget.
   */
  const showFallback =
    field.result?.rawValue && !field.result.rawValue.coordinates && field.result?.rawValue?.formattedValue;
  if (isFieldVisible(field) && showFallback) {
    return (
      <article aria-label={`${props.field.properties.label_text} - ${t("LOCATION_FIELD")}`}>
        <Label
          htmlFor={field.props.name}
          label={props.field.properties.label_text}
          required={props.field.properties.required}
          showClearBtn={!!field.result?.rawValue && !isDisabled}
          onClear={async () => helpers.persist(undefined)}
          clearLabel={t("RESET")}
        />
        <WidgetContentButton className="mt-1 rounded-t-none" disabled={isDisabled}>
          {field.result?.rawValue?.formattedValue}
        </WidgetContentButton>
      </article>
    );
  }
  return <WidgetLocation {...props} field={locationField} />;
};

WidgetCurrentLocation.defaultValue = (_field, defaultMeta: any): WidgetResult<LocationResult> => ({
  type: "location",
  meta: {
    widget: "currentLocation",
    ...defaultMeta,
  },
});

WidgetCurrentLocation.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetCurrentLocation;
