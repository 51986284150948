import { createContext, FC, PropsWithChildren, useMemo, useState } from "react";

export const DrawerContext = createContext<DrawerState>({} as DrawerState);

export const DrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [drawers, setDrawers] = useState<string[]>([]);

  const values = useMemo(
    () => ({
      open: (id: string) => setDrawers((xs) => (xs.includes(id) ? xs : [...xs, id])),
      close: (id: string): void => {
        if (!drawers.includes(id)) {
          return; // Drawer wasn't open to begin with
        }
        setDrawers(
          (xs) =>
            xs.reduceRight(
              ({ found, result }: { found: boolean; result: string[] }, x) => ({
                found: found || x === id,
                result: found ? [x, ...result] : result,
              }),
              { found: false, result: [] },
            ).result,
        );
      },
      isOpen: (id: string) => drawers.filter((x) => x === id).length > 0,
      closeLast: (): boolean => {
        let result = false;

        setDrawers((old) => {
          if (old.length === 0) {
            return old;
          }
          result = true;
          return old.slice(0, -1);
        });

        return result;
      },
      reset: () => setDrawers([]),
      isOnTop: (id: string) => (drawers.length > 0 ? drawers[drawers.length - 1] === id : false),
      activeDrawer: drawers.length > 0,
    }),
    [drawers],
  );

  return <DrawerContext.Provider value={values}>{children}</DrawerContext.Provider>;
};

export interface DrawerState {
  open: (id: string) => void;
  close: (id: string) => void;
  isOpen: (id: string) => boolean;
  closeLast: () => boolean;
  reset: () => void;
  isOnTop: (id: string) => boolean;
  activeDrawer: boolean;
}
