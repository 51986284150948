import { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import useFormVersion from "../hooks/useFormVersion";
import SentSubmissionDrawer from "./SentSubmissionDrawer";
import RecentSubmissions from "./search/RecentSubmissions";
import SearchSubmissionList from "./search/SearchSubmissionList";
import useOnlineStatus from "../hooks/useOnlineStatus";
import OfflineContent from "../pages/errorpages/OfflineContent";
import { FocusProvider } from "../context/FocusContext";
import SearchSubmissionFields, { SearchSubmissionFieldsMethods } from "./search/SearchSubmissionFields";
import { getStaticDefaultValues } from "../utils/submissionUtil";
import uuidv4 from "../utils/uuid";
import { SubmissionDocument } from "../utils/databaseUtil";
import { DrawerFooterProps } from "../storybook/components/Drawer/DrawerFooter";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { Segment } from "../storybook/components/Segment/Segment";

type SearchSubmissionDrawerProps = {
  formVersionId: string | undefined;
  formId: string | undefined;
  open: boolean;
  onClose: () => void;
};

const SearchSubmissionDrawer: FC<SearchSubmissionDrawerProps> = ({ formId, formVersionId, open, onClose }) => {
  const { t } = useTranslation();
  const { customerId } = useAuth();
  const { data: formVersion, isFetching, refetch } = useFormVersion(formVersionId, customerId, formId);
  const { isOnline } = useOnlineStatus();
  const [previewSubmission, setPreviewSubmission] = useState<string>();
  const [mode, setMode] = useState<"recent" | "fields">("recent");
  const [submission, setSubmission] = useState<any>();
  const submitRef = useRef<SearchSubmissionFieldsMethods>(null);

  const searchFormVersion = useMemo(() => {
    if (!formVersion) {
      return undefined;
    }
    return {
      ...formVersion,
      fields: formVersion.fields.filter((item) => formVersion?.settings.searchSettings?.fields?.[item.uid]),
    };
  }, [formVersion]);

  const searchSubmission = useMemo(() => {
    if (!formVersion) {
      return undefined;
    }
    return {
      id: uuidv4(),
      customerId,
      formId,
      formVersionId: formVersion.id,
      status: "draft",
      meta: {},
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      type: "draft",
    } as SubmissionDocument;
  }, [customerId, formVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultValues = useMemo(() => {
    if (!searchSubmission || !searchFormVersion) {
      return undefined;
    }
    return getStaticDefaultValues(searchFormVersion, searchSubmission);
  }, [searchFormVersion, searchSubmission]);

  const handleOnClose = (): void => {
    onClose();
    setMode("recent");
    setSubmission(undefined);
  };

  const footer = (): DrawerFooterProps | undefined => {
    if (!isOnline || mode !== "fields") {
      return undefined;
    }

    return !submission
      ? {
          kind: "default",
          primaryButton: {
            label: t("SEARCH_SUBMISSIONS_BUTTON"),
            onClick: () => submitRef.current?.onSubmit(),
          },
          secondaryButton: {
            label: t("CLEAR"),
            onClick: async (): Promise<void> => {
              await refetch();
              setSubmission(undefined);
            },
          },
        }
      : {
          kind: "default",
          primaryButton: {
            label: t("CLEAR"),
            onClick: () => setSubmission(undefined),
          },
        };
  };

  const searchSubmissionFieldContent = (): JSX.Element => {
    if (!searchSubmission || !searchFormVersion || !defaultValues) {
      return <Spinner className="mx-auto mt-4" />;
    }
    return (
      <SearchSubmissionFields
        formVersion={searchFormVersion}
        defaultValues={defaultValues}
        setResult={setSubmission}
        submission={searchSubmission as SubmissionDocument}
        ref={submitRef}
      />
    );
  };

  const fieldDrawerContent = (
    <>
      {!submission && <div className="px-4 py-6">{searchSubmissionFieldContent()}</div>}
      {submission && <SearchSubmissionList searchSubmission={submission} onClick={setPreviewSubmission} />}
    </>
  );

  const mainContent =
    isFetching || !searchFormVersion || !customerId ? (
      <Spinner />
    ) : (
      <FocusProvider>
        {mode === "recent" && formId && formVersionId ? (
          <RecentSubmissions
            formId={formId}
            formVersionId={formVersionId}
            customerId={customerId}
            onClick={setPreviewSubmission}
          />
        ) : (
          fieldDrawerContent
        )}
        {previewSubmission && (
          <SentSubmissionDrawer id={previewSubmission} onClose={() => setPreviewSubmission(undefined)} />
        )}
      </FocusProvider>
    );

  return (
    <Drawer
      header={{
        kind: "simple",
        title: t("SEARCH_SUBMISSIONS"),
        button: { kind: "icon", icon: "XIcon", onClick: handleOnClose },
        content: formVersion?.settings.searchSettings?.enabled ? (
          <Segment
            className="mx-5 mb-6"
            selected={mode}
            onSelect={(e) => (e === "recent" ? setMode("recent") : setMode("fields"))}
            options={[
              { id: "recent", label: t("HISTORY_RECENT") },
              { id: "fields", label: t("HISTORY_SEARCH") },
            ]}
          />
        ) : undefined,
      }}
      footer={footer()}
      open={open}
      onClose={handleOnClose}
      contentPadding={false}
    >
      {!isOnline ? <OfflineContent centered /> : mainContent}
    </Drawer>
  );
};

export default SearchSubmissionDrawer;
