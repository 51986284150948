import React, { FC, PropsWithChildren } from "react";
import classNames from "classnames";

export interface LabelProps {
  label: string;
  required?: boolean;
  as?: "label" | "legend";
  htmlFor?: string;
  clickFocus?: React.MutableRefObject<HTMLElement | null>;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  className?: string;
  id?: string;
}

export const Label: FC<PropsWithChildren<LabelProps>> = ({
  label,
  required = false,
  as = "label",
  htmlFor,
  clickFocus,
  onClear = (): void => {},
  showClearBtn = false,
  clearLabel = "clear",
  className,
  children,
  id,
}) => {
  const classes = classNames(
    "mb-1 cursor-default text-sm text-gray-500",
    as === "label" && "flex justify-between gap-x-1 file:items-start",
    className,
  );

  const content = (
    <div className="min-w-0 flex-1 overflow-hidden break-words font-semibold">
      {label}
      {required && <span className="ml-1 text-red-400">*</span>}
    </div>
  );

  const handleOnClear = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    onClear();
  };

  const clearBtn = showClearBtn && clearLabel && (
    <button
      type="button"
      onClick={(event) => handleOnClear(event)}
      className="select-none rounded px-0.5 text-brand-600 outline-none focus-visible:ring active:!text-gray-900 pointer:hover:text-brand-800"
    >
      <span className="text-sm font-semibold lowercase">{clearLabel}</span>
    </button>
  );

  if (as === "legend") {
    if (clickFocus === undefined) {
      throw new Error("`clickFocus` is required when rendering as `legend`");
    }

    return (
      <fieldset className="min-w-0">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
        <legend className={`${classes} w-full`} onClick={() => clickFocus.current?.focus({ preventScroll: true })}>
          <div className="flex justify-between gap-x-1">
            {content}
            {clearBtn}
          </div>
        </legend>
        {children}
      </fieldset>
    );
  }

  return (
    <label className={classes} htmlFor={htmlFor} id={id}>
      {content}
      {clearBtn}
    </label>
  );
};
