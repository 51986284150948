import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCollection } from "rxdb";
import { ActiveEntry, DeleteIntent } from "../../../types/Widget";
import Form, { FormMethods } from "../../Form";
import { FieldProperties, SubForm } from "../../../types/FormVersion";
import { RememberedFieldDocument, SubmissionDocument } from "../../../utils/databaseUtil";
import { Field } from "../../../types/Field";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { Modal } from "../../../storybook/components/Modal/Modal";

interface FormDrawerProps {
  closeForm: (entryId: string, formVersion?: SubForm, discard?: boolean, enforceValidation?: boolean) => any;
  removeForm: () => any;
  label: string;
  formVersion?: SubForm;
  fieldProperties: FieldProperties;
  parentId: string;
  activeEntry: ActiveEntry;
  setActiveEntry: (entry: ActiveEntry) => void;
  submission: SubmissionDocument;
  rememberedFields?: RememberedFieldDocument[];
  fieldsCollection?: RxCollection<Field>;
  readOnly?: boolean;
}

const FormDrawer = forwardRef<FormMethods, FormDrawerProps>(
  (
    {
      closeForm,
      removeForm,
      label,
      formVersion,
      fieldProperties,
      parentId,
      activeEntry,
      setActiveEntry,
      submission,
      rememberedFields,
      fieldsCollection,
      readOnly = false,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [removeEntry, setRemoveEntry] = useState<DeleteIntent | null>(null);

    const onClose = (): void => {
      if (readOnly || activeEntry.isHumanEdited) {
        closeForm(activeEntry.id, formVersion);
        return;
      }

      // Remove pin when form is closed without touching anything
      removeForm();
      closeForm(activeEntry.id, formVersion, true);
    };

    return (
      <Drawer
        open
        header={{
          kind: "extended",
          title: label,
          rightButton: !readOnly
            ? {
                kind: "textAndIcon",
                label: t("OPTIONS"),
                icon: "ChevronDownIcon",
                dropDownMenu: {
                  items: [
                    {
                      label: t("SAVE"),
                      onClick: (): any => closeForm(activeEntry.id, formVersion, false, true),
                    },
                    {
                      label: t("DELETE"),
                      type: "destructive",
                      onClick: (): void => setRemoveEntry({ type: "DELETE_BUTTON", id: activeEntry.id }),
                    },
                  ],
                },
              }
            : undefined,
          leftButton: {
            kind: "text",
            label: activeEntry?.isHumanEdited ? t("SAVE_AND_CLOSE") : t("BACK"),
            onClick: onClose,
          },
        }}
        footer={
          !readOnly
            ? {
                kind: "default",
                primaryButton: {
                  label: t("SAVE"),
                  onClick: (): any => closeForm(activeEntry.id, formVersion, false, true),
                },
              }
            : undefined
        }
        onClose={() => setRemoveEntry(null)}
        className="flex flex-col gap-y-6 !px-4 py-6"
      >
        {formVersion && (
          <Form
            ref={ref}
            formVersion={formVersion}
            fieldProperties={fieldProperties}
            submission={submission}
            rememberedFields={rememberedFields}
            entryId={activeEntry.id}
            parentId={parentId}
            readOnly={readOnly}
            fieldsCollection={fieldsCollection}
            validationMode={activeEntry.isHumanEdited ? "trigger_immediately" : "default"}
            setHumanEdited={(isHumanEdited) => setActiveEntry({ ...activeEntry, isHumanEdited })}
          />
        )}
        <Modal
          title={removeEntry?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_TITLE") : t("PIN_DELETE_MODAL_TITLE")}
          content={{
            kind: "message",
            message:
              removeEntry?.type === "BACK_BUTTON" ? t("DISCARD_MODAL_DESCRIPTION") : t("PIN_DELETE_MODAL_DESCRIPTION"),
          }}
          open={!!removeEntry}
          onClose={() => setRemoveEntry(null)}
          buttons={[
            { label: t("CANCEL"), onClick: (): void => setRemoveEntry(null) },
            {
              label: t("DISCARD"),
              variant: "destructive",
              onClick: (): void => {
                removeForm();
                closeForm(activeEntry.id, formVersion, true);
              },
            },
          ]}
        />
      </Drawer>
    );
  },
);
FormDrawer.displayName = "FormDrawer";

export default FormDrawer;
