import { useTranslation } from "react-i18next";
import { useState } from "react";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import useWidget from "../../hooks/useWidget";
import { WidgetResult } from "../../types/Field";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import HtmlContent from "../HtmlContent";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Modal } from "../../storybook/components/Modal/Modal";

export interface WidgetHelpProperties {
  required: boolean;
  title: string;
  explanation: string;
  use_help_text: boolean;
  use_help_text_value?: string;
}

const WidgetHelp: Widget<WidgetHelpProperties, WidgetResult<void>> = (props) => {
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetHelp.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <div className="-mb-4 mt-3 flex justify-end">
      {props.field.properties.use_help_text ? (
        <IconAndTextButton
          label={props.field.properties.use_help_text_value ?? ""}
          variant="transparentBrand"
          size="md"
          icon="QuestionMarkCircleIcon"
          iconAlign="right"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <IconButton
          aria-label={t("OPEN_HELP_EXPLANATION")}
          variant="transparentBrand"
          size="md"
          icon="QuestionMarkCircleIcon"
          onClick={() => setShowModal(true)}
        />
      )}

      <Modal
        title={props.field.properties.title}
        content={{
          kind: "freestyle",
          children: <HtmlContent html={props.field.properties.explanation} />,
        }}
        open={showModal}
        onClose={() => setShowModal(false)}
        buttons={[
          {
            variant: "default",
            label: t("CLOSE"),
            onClick: () => setShowModal(false),
          },
        ]}
      />
    </div>
  );
};

WidgetHelp.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "help",
    ...defaultMeta,
  },
});

WidgetHelp.validate = (): undefined => undefined;

export default WidgetHelp;
