import { TFunction } from "i18next";
import { v5 as uuidv5, validate } from "uuid";
import { ErrorOption, UseFormSetError } from "react-hook-form";
import { isEmpty, isEqual, last } from "lodash-es";
import { FieldError } from "../context/MoreAppContext";
import { legacySubmissionId, removeWidgetVersionNumber } from "./stringUtil";
import { Submission } from "../types/Submission";
import { SubmissionFormData } from "../components/Form";
import { Widget } from "../types/Widget";
import WidgetText from "../components/widgets/WidgetText";
import WidgetTextarea from "../components/widgets/WidgetTextarea";
import WidgetNumber from "../components/widgets/WidgetNumber";
import WidgetEmail from "../components/widgets/WidgetEmail";
import WidgetHeader from "../components/widgets/WidgetHeader";
import WidgetLabel from "../components/widgets/WidgetLabel";
import WidgetPhone from "../components/widgets/WidgetPhone";
import WidgetRadio from "../components/widgets/WidgetRadio";
import WidgetDate from "../components/widgets/WidgetDate";
import WidgetDateTime from "../components/widgets/WidgetDateTime";
import WidgetTime from "../components/widgets/WidgetTime";
import WidgetSubform, { SubformEntry, WidgetSubformProperties } from "../components/widgets/WidgetSubform";
import WidgetCheckbox from "../components/widgets/WidgetCheckbox";
import WidgetSearch from "../components/widgets/WidgetSearch";
import WidgetBarcode from "../components/widgets/WidgetBarcode";
import WidgetLookup from "../components/widgets/WidgetLookup";
import WidgetSlider from "../components/widgets/WidgetSlider";
import WidgetRDW from "../components/widgets/WidgetRDW";
import WidgetImage from "../components/widgets/WidgetImage";
import WidgetSignature from "../components/widgets/WidgetSignature";
import WidgetPin, { WidgetPinProperties } from "../components/widgets/WidgetPin";
import WidgetPhoto from "../components/widgets/WidgetPhoto";
import WidgetRichText from "../components/widgets/WidgetRichText";
import WidgetPrice from "../components/widgets/WidgetPrice";
import WidgetFile from "../components/widgets/WidgetFile";
import WidgetHelp from "../components/widgets/WidgetHelp";
import WidgetRating from "../components/widgets/WidgetRating";
import WidgetStopwatch from "../components/widgets/WidgetStopwatch";
import WidgetSmiley from "../components/widgets/WidgetSmiley";
import WidgetTimeDifference from "../components/widgets/WidgetTimeDifference";
import WidgetIBAN from "../components/widgets/WidgetIBAN";
import WidgetPostcode from "../components/widgets/WidgetPostcode";
import WidgetCalculation from "../components/widgets/WidgetCalculation";
import WidgetCatalogue from "../components/widgets/WidgetCatalogue";
import { RememberedFieldDocument } from "./databaseUtil";
import WidgetDrawing from "../components/widgets/WidgetDrawing";
import WidgetVideo from "../components/widgets/WidgetVideo";
import WidgetReadOnlyText from "../components/widgets/WidgetReadOnlyText";
import WidgetBorderlessHeader from "../components/widgets/WidgetBorderlessHeader";
import WidgetParagraph from "../components/widgets/WidgetParagraph";
import WidgetCurrentLocation from "../components/widgets/WidgetCurrentLocation";
import { AbstractForm, FieldProperties, FormField, SubForm, WidgetProperties } from "../types/FormVersion";
import WidgetWeekNumber from "../components/widgets/WidgetWeekNumber";
import WidgetInstructionRichText from "../components/widgets/WidgetInstructionRichText";
import { Entry, Field, RememberedField, WidgetResult, WidgetResultMeta } from "../types/Field";
import WidgetDecimal from "../components/widgets/WidgetDecimal";
import WidgetLocation from "../components/widgets/WidgetLocation";
import { ControlledField } from "../hooks/useWidget";
import { Form } from "../types/Folder";
import { getParentEntries } from "./submissionUtil";
import WidgetError from "../components/widgets/WidgetError";

type WidgetInfo = {
  widgetKey: string;
  component: Widget<any, any>;
};
export const WidgetComponents: Record<string, WidgetInfo> = {
  "com.moreapps:text": { widgetKey: "text", component: WidgetText },
  "com.moreapps:text_area": { widgetKey: "textarea", component: WidgetTextarea },
  "com.moreapps:number": { widgetKey: "number", component: WidgetNumber },
  "com.moreapps:email": { widgetKey: "email", component: WidgetEmail },
  "com.moreapps:header": { widgetKey: "header", component: WidgetHeader },
  "com.moreapps:label": { widgetKey: "label", component: WidgetLabel },
  "com.moreapps:phone": { widgetKey: "phone", component: WidgetPhone },
  "com.moreapps:radio": { widgetKey: "radio", component: WidgetRadio },
  "com.moreapps:date": { widgetKey: "date", component: WidgetDate },
  "com.moreapps:datetime": { widgetKey: "datetime", component: WidgetDateTime },
  "com.moreapps:time": { widgetKey: "time", component: WidgetTime },
  "com.moreapps:detail": { widgetKey: "subform", component: WidgetSubform },
  "com.moreapps:checkbox": { widgetKey: "checkbox", component: WidgetCheckbox },
  "com.moreapps:search": { widgetKey: "search", component: WidgetSearch },
  "com.moreapps:barcode": { widgetKey: "barcode", component: WidgetBarcode },
  "com.moreapps:lookup": { widgetKey: "lookup", component: WidgetLookup },
  "com.moreapps:slider": { widgetKey: "slider", component: WidgetSlider },
  "com.moreapps:rdw": { widgetKey: "rdw", component: WidgetRDW },
  "com.moreapps:image": { widgetKey: "image", component: WidgetImage },
  "com.moreapps:signature": { widgetKey: "signature", component: WidgetSignature },
  "com.moreapps:pin": { widgetKey: "pin", component: WidgetPin },
  "com.moreapps:photo": { widgetKey: "photo", component: WidgetPhoto },
  "com.moreapps:html": { widgetKey: "richText", component: WidgetRichText },
  "com.moreapps:location": { widgetKey: "location", component: WidgetLocation },
  "com.moreapps.plugin:smiley": { widgetKey: "smiley", component: WidgetSmiley },
  "com.moreapps.plugins:price": { widgetKey: "price", component: WidgetPrice },
  "com.dirkjanhoek:price": { widgetKey: "price", component: WidgetPrice },
  "com.moreapps.plugins:drawing": { widgetKey: "drawing", component: WidgetDrawing },
  "com.moreapps.plugins:file": { widgetKey: "file", component: WidgetFile },
  "com.moreapps.plugins:help": { widgetKey: "help", component: WidgetHelp },
  "com.moreapps.plugins:rating": { widgetKey: "rating", component: WidgetRating },
  "com.moreapps.plugins:stopwatch": { widgetKey: "stopwatch", component: WidgetStopwatch },
  "com.moreapps.plugins:timecalculation": { widgetKey: "timeDifference", component: WidgetTimeDifference },
  "com.moreapps.plugins:iban": { widgetKey: "iban", component: WidgetIBAN },
  "com.moreapps.plugins:zipcode": { widgetKey: "postcode", component: WidgetPostcode },
  "com.moreapps.plugins:calculation": { widgetKey: "calculation", component: WidgetCalculation },
  "com.moreapps.plugins:catalogue": { widgetKey: "catalogue", component: WidgetCatalogue },
  "com.moreapps.plugins:video": { widgetKey: "video", component: WidgetVideo },
  "com.dirkjanhoek:currentlocation": { widgetKey: "currentLocation", component: WidgetCurrentLocation },
  "nl.gildesoftware:readonlytext": { widgetKey: "readOnlyText", component: WidgetReadOnlyText },
  "nl.stijlaart.mats:borderlessheader": { widgetKey: "borderlessHeader", component: WidgetBorderlessHeader },
  "nl.robin:paragraph": { widgetKey: "paragraph", component: WidgetParagraph },
  "com.landien:weeknumber": { widgetKey: "weekNumber", component: WidgetWeekNumber },
  "com.landien:weeknumberc": { widgetKey: "weekNumber", component: WidgetWeekNumber },
  "com.landien:weeknumberfordedicatedcustomers": { widgetKey: "weekNumber", component: WidgetWeekNumber },
  "com.moreapps.plugins:bolsiusgpssearch": { widgetKey: "search", component: WidgetSearch },
  "com.samsonit.instructionhtml:instructionhtml": {
    widgetKey: "instructionRichText",
    component: WidgetInstructionRichText,
  },
  "clearsolutions:decimal": { widgetKey: "decimal", component: WidgetDecimal },
};

export const widgetResultByDataName = (
  fields: FormField<any>[],
  formData: SubmissionFormData,
  submissionId: string,
  entryId?: string,
  parentId?: string,
): SubmissionFormData =>
  formData
    ? fields.reduce((acc, item) => {
        const id = getCalculatedFieldId(item.uid, submissionId, entryId, parentId);
        const widgetResult = formData[id];
        if (widgetResult && !widgetResult.meta.hidden) {
          acc[item.properties.data_name] = widgetResult;
        }
        return acc;
      }, {} as SubmissionFormData)
    : {};

export const setFieldErrors = (fieldErrors: FieldError[], setError: UseFormSetError<any>, t: TFunction): void => {
  fieldErrors.forEach((constraintViolation) => {
    const path = last(constraintViolation.path.split("."))!;
    setError(path, {
      type: "custom",
      message: t(constraintViolation.code),
    });
  });
};

export const getDefaultMeta = (
  field: FormField<any>,
  fieldId: string,
  submission?: Submission,
  parentId?: string | undefined,
  entryId?: string | undefined,
): Omit<WidgetResultMeta, "widget" | "hidden" | "evaluatedRules" | "order"> => ({
  formFieldId: field.uid,
  submissionId: submission?.id,
  parentId,
  fieldId,
  entryId,
  dataName: field.properties.data_name,
  compressed: false,
});

export const getCalculatedFieldId = (
  fieldUid: string,
  submissionId: string,
  entryId?: string,
  parentId?: string,
): string => {
  const id = validate(submissionId) ? submissionId : legacySubmissionId(submissionId);
  return uuidv5(parentId ? `${fieldUid}-${parentId}-${entryId}` : fieldUid, id);
};

export const highestOrder = (entries?: SubformEntry<any>[]): number =>
  Math.max(...(entries?.filter((e) => !e.deleted).map((el) => el.meta?.order ?? 0) ?? [0]), 0);

export const getInitialValue = <T extends WidgetProperties>(
  fieldId: string,
  formField: FormField<T>,
  submission?: Submission,
  rememberedField?: RememberedFieldDocument,
  entryId?: string,
  parentId?: string,
): WidgetResult<unknown> => {
  const defaultValue = getDefaultValue(fieldId, formField, submission, entryId, parentId);
  return rememberedField ? { ...defaultValue, rawValue: rememberedField.data } : defaultValue;
};

export const getDefaultValue = <T extends WidgetProperties>(
  fieldId: string,
  formField: FormField<T>,
  submission?: Submission,
  entryId?: string,
  parentId?: string,
): any => {
  const widgetId = removeWidgetVersionNumber(formField.widget);
  const widgetComponent = WidgetComponents[widgetId]?.component;

  // All widgets that can have data have a `defaultValue` function
  if (widgetComponent && "defaultValue" in widgetComponent) {
    const defaultValue = (): any => {
      const meta = getDefaultMeta(formField, fieldId, submission, parentId, entryId);
      return widgetComponent.defaultValue(formField, meta);
    };
    return defaultValue();
  }
  return undefined;
};

/**
 *  Calculates ALL entries with a validation error.
 *  So example: Subform A > Entry 1 > Subform B > Entry 1.1 > Number*
 *  If number is not filled in, this will return ['Entry 1', 'Entry 1.1']
 *  @return array with all entries with an error
 */
export const getEntriesWithError = (fields: Field[]): string[] =>
  fields
    .filter((field) => field.error && !field.hidden)
    .flatMap((field) => getParentEntries(fields, field.entryId, field.parentId));

export const findInvalidEntries = (entries: Entry[], errorEntries: string[]): string[] =>
  entries
    .filter((valueEntry) => errorEntries.find((errorEntry) => errorEntry === valueEntry.id))
    .map((invalidEntry) => invalidEntry.id);

export const getError = (field: Field, invalidEntries: string[]): ErrorOption | undefined => {
  if (field.hidden) {
    return undefined;
  }
  const subformErrors = findInvalidEntries(field.entries, invalidEntries);
  if (!isEmpty(subformErrors)) {
    return { type: "entries", message: JSON.stringify(subformErrors) };
  }
  if (field.error) {
    return { type: "validate", message: field.error };
  }
  return undefined;
};

export enum FieldStatus {
  Final = "FINAL",
  DefaultValue = "DEFAULT_VALUE",
  Draft = "DRAFT",
  ShouldInitialize = "SHOULD_INITIALIZE",
}

export const getFieldStatus = (submission?: Submission, field?: Field, readOnly?: boolean): FieldStatus => {
  const isFinalized = submission?.status === "final" || field?.status === "final" || readOnly;
  if (isFinalized) {
    return FieldStatus.Final;
  }
  if (field && !field?.updatedBy) {
    return FieldStatus.DefaultValue;
  }
  if (field) {
    return FieldStatus.Draft;
  }
  return FieldStatus.ShouldInitialize;
};

export const isSameValue = (rememberedField: RememberedField, field?: Field): boolean =>
  isEqual(rememberedField.data, field?.data);

export const updateHumanEdited = (fields: Field[], setHumanEdited?: (value: boolean) => void): void => {
  if (setHumanEdited && fields.some((x) => x.updatedBy || x.entries?.length > 0)) {
    setHumanEdited(true);
  }
};

/**
 * Some widgets have default values we can only set at the time that the submission is opened for the first time.
 * This includes the Date, Date-Time, Time and (Current) Location widget.
 *
 * @param field
 * @param formField
 * @returns result whether field should calculate a new default value
 */
export const shouldCalculateDefault = (formField: FormField<any>, field?: Field): any => {
  const hasDefault =
    formField.properties.now_as_default ||
    formField.properties.initial_current_location ||
    formField.widget === "currentLocation";
  return hasEmptyValue(field?.data) && hasDefault;
};

export const isFieldVisible = (field: ControlledField<WidgetResult<unknown>>): boolean | undefined =>
  field.controller && field.result?.meta && !field.result.meta.hidden;

export const compareByLocale = (a: string, b: string): number =>
  a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: "base",
  });
export const getFormVersionForField = (formVersions: AbstractForm[], formFieldId: string): AbstractForm | undefined =>
  formVersions.find((x) => x.fields.find((y) => y.uid === formFieldId));

export const getFieldFromFormVersions = (
  formVersions: AbstractForm[],
  formFieldId: string,
): FormField<any> | undefined =>
  formVersions.flatMap((formVersion) => formVersion.fields).find((x) => x.uid === formFieldId);

export const isErrorWidget = (field: ControlledField<WidgetResult<unknown>>): boolean =>
  field.result?.meta.widget === "missing" || field.result?.meta.widget === "incompatible";

export const isUsable = (form: Form): boolean => form.status === "ACTIVE" && !!form.publishedVersion.formVersion; // Remove trashed, hidden and unpublished forms

const getWidgetComponent = (formField: FormField<any>): Widget<any, any> => {
  const widgetId = removeWidgetVersionNumber(formField.widget);
  return WidgetComponents[widgetId]?.component || WidgetError;
};

export const getWidgetOnUploadCompleteByWidgetKey: (key: string) => ((value: any) => Promise<any>) | undefined = (
  key: string,
) => Object.values(WidgetComponents).find((entry) => entry.widgetKey === key)?.component?.onUploadComplete;

type WidgetValidationResult = (
  value: any,
  properties: any,
  t: TFunction<"translation", undefined>,
  meta: any,
  entries?: any,
) => string | undefined;

const getWidgetValidation = (formField: FormField<any>): WidgetValidationResult =>
  getWidgetComponent(formField)?.validate;

export const validateField = (
  formField: FormField<any>,
  hidden: boolean,
  data: any,
  entries: Entry[],
  t: TFunction,
): string | undefined => {
  if (hidden) {
    return undefined;
  }
  const validator = getWidgetValidation(formField);
  return validator ? validator(data, formField.properties, t, entries) : undefined;
};

export const getFieldMap = (
  submission: Submission,
  formFields: FormField<any>[],
  fields: Field[],
  fieldProperties: FieldProperties,
  entryId?: string,
  parentId?: string,
): Map<string, FormField<any>> => {
  let result = new Map<string, FormField<any>>();

  const fieldsMap: Map<string, Field> = new Map<string, Field>(fields.map((field) => [field.id, field]));
  formFields.forEach((formField) => {
    const fieldId = getCalculatedFieldId(formField.uid, submission.id, entryId, parentId);
    const nestedField = fieldsMap.get(fieldId);
    result.set(fieldId, formField);
    nestedField?.entries.forEach((entry) => {
      if (formField.widget === "com.moreapps:detail:1") {
        const nestedSubform = getSubformVersion(formField, fieldProperties);
        result = new Map([
          ...result,
          ...getFieldMap(submission, nestedSubform.fields, fields, fieldProperties, entry.id, fieldId),
        ]);
      } else if (formField.widget === "com.moreapps:pin:1") {
        const nestedPinforms = getPinFormVersions(formField, fieldProperties);
        const pinForm = nestedPinforms.find(({ uid }) => entry.meta.scope.target === uid);
        result = new Map([
          ...result,
          ...getFieldMap(submission, pinForm?.fields ?? [], fields, fieldProperties, entry.id, fieldId),
        ]);
      }
    });
  });

  return result;
};

export const getPinFormVersions = (
  formField: FormField<WidgetPinProperties>,
  fieldProperties: FieldProperties,
): SubForm[] =>
  (formField.properties.pins ?? [])
    ?.map((pin) => (pin.target_form_id ? fieldProperties[pin.target_form_id] : pin.form))
    .filter((item): item is SubForm => !!item);

export const getSubformVersion = (
  formField: FormField<WidgetSubformProperties>,
  fieldProperties: FieldProperties,
): SubForm =>
  formField.properties.target_form_id
    ? fieldProperties[formField.properties.target_form_id]
    : formField.properties.form;

/**
 * Detects empty cases like: undefined, null, [], "", {}
 * Skips falsy but valid form values like: false, 0 (zero)
 * @param value
 */
export const hasEmptyValue = (value: unknown): boolean =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);
