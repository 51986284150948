import { FC, FocusEvent, ReactElement, Ref, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { NumberFormatValues, NumericFormat, OnValueChange, SourceInfo } from "react-number-format";
import { isNil } from "lodash-es";
import { Label } from "../Label/Label";
import { Text } from "../Text/Text";
import { IconProps } from "../Icon/Icon";
import { Feedback } from "../Feedback/Feedback";
import { Button } from "../Button/Button";
import { delimiter, thousandSeparator } from "../../../utils/formatter";

export interface NumberInputProps {
  name: string;
  label: string;
  inputMode?: "numeric" | "decimal";
  inputRef?: Ref<any>;
  value?: number | string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: OnValueChange;
  onBlur?: (e: FocusEvent<any>) => void;
  onFocus?: (e: FocusEvent<any>) => void;
  onPlusMinus?: () => void;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  clamp?: boolean;
  maxPrecision?: number;
  showThousandSeparator?: boolean;
  leftIcon?: ReactElement<IconProps>;
  leftChild?: string;
  className?: string;
}

export const NumberInput: FC<NumberInputProps> = ({
  name,
  label,
  inputMode,
  inputRef,
  value,
  description,
  placeholder,
  required = false,
  errorMessage,
  disabled = false,
  onChange = (): void => {},
  onBlur = (): void => {},
  onFocus = (): void => {},
  onClear = (): void => {},
  onPlusMinus,
  showClearBtn = false,
  clearLabel,
  min,
  max,
  step = 1,
  clamp = false,
  maxPrecision,
  showThousandSeparator,
  leftIcon,
  leftChild,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);
  const hasIndicator = leftChild || leftIcon;

  const inputClasses = classNames(
    "block w-full resize-none border-0 p-3.5 text-sm text-gray-700 placeholder:text-gray-400",
    disabled
      ? "cursor-not-allowed border-gray-200 bg-gray-100"
      : "focus:border-blue-500 focus:bg-white focus:ring-0.5 focus:ring-blue-500 group-hover:ring-0.5 focus:group-hover:ring-blue-500",
    errorMessage && !disabled
      ? {
          "border-red-500 group-hover:ring-red-500": true,
          "bg-red-50": hasIndicator,
          "bg-red-100": !hasIndicator,
        }
      : "border-gray-200 group-hover:ring-gray-200",
  );

  const wrapperClasses = classNames(
    "flex w-full border-1.5 outline-none transition",
    hasIndicator ? "rounded-r" : "rounded",
    errorMessage && !disabled
      ? {
          "border-red-500 group-hover:ring-red-500": true,
          "bg-red-50": hasIndicator,
          "bg-red-100": !hasIndicator,
        }
      : "border-gray-200 group-hover:ring-gray-200",
  );

  const iconClasses = classNames(
    "-mr-0.5 flex w-12 shrink-0 items-center justify-center rounded-l border-1.5 border-gray-200 bg-gray-50 text-gray-400 transition",
    !disabled && "group-hover:ring-0.5 group-hover:ring-gray-200",
    focus && "ring-0.5 ring-gray-200",
    leftChild && {
      "font-semibold overflow-hidden": true,
      "text-xl": leftChild.length === 1,
      "text-lg tracking-wider": leftChild.length > 1,
    },
  );

  const handleBlur = (e: FocusEvent): void => {
    setFocus(false);
    onBlur(e);
  };
  const handleFocus = (e: FocusEvent): void => {
    setFocus(true);
    onFocus(e);
  };
  const isAllowed = ({ floatValue }: NumberFormatValues): boolean => {
    if (!floatValue) {
      return true;
    }

    // These checks will prevent users from typing more than the max or less than the min
    // Before, it would visually let you type bigger/smaller numbers while the effective value stayed capped
    if (max && floatValue > max) {
      onChange({ floatValue: max } as NumberFormatValues, {} as SourceInfo);
      return false;
    }

    if (min && floatValue < min) {
      onChange({ floatValue: min } as NumberFormatValues, {} as SourceInfo);
      return false;
    }

    return true;
  };

  return (
    <div className={classNames("relative", className)} {...props}>
      <Label
        label={label}
        required={required}
        showClearBtn={showClearBtn}
        onClear={onClear}
        clearLabel={clearLabel}
        htmlFor={name}
      />
      <div className="group flex">
        {hasIndicator && <div className={iconClasses}>{leftIcon ?? leftChild}</div>}
        <div className={wrapperClasses}>
          {!disabled && onPlusMinus && (
            <Button
              onClick={onPlusMinus}
              aria-label={t("SWITCH_PLUS_MINUS")}
              size="md"
              className="m-1 min-w-10"
              icon="PlusMinusIcon"
            />
          )}
          <NumericFormat
            id={name}
            name={name}
            className={inputClasses}
            type="text"
            decimalScale={maxPrecision}
            fixedDecimalScale={!isNil(maxPrecision)}
            allowedDecimalSeparators={[".", ","]}
            inputMode={inputMode}
            disabled={disabled}
            decimalSeparator={delimiter}
            thousandSeparator={showThousandSeparator ? thousandSeparator : undefined}
            min={min}
            max={max}
            step={step}
            valueIsNumericString
            value={value}
            placeholder={placeholder}
            onValueChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            isAllowed={clamp ? isAllowed : undefined}
            autoComplete="off"
            getInputRef={inputRef}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
          />
        </div>
      </div>
      {errorMessage && <Feedback status="error" message={errorMessage} />}
      {description && !errorMessage && (
        <Text className="mt-1" size="sm" color="medium">
          {description}
        </Text>
      )}
    </div>
  );
};
