import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useEffectOnce } from "../hooks/useEffectOnce";
import Page from "../components/Page";
import useLogin from "../hooks/useLogin";
import useAuth from "../hooks/useAuth";
import SignupPage from "./SignupPage";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";
import { TextButton } from "../storybook/components/TextButton/TextButton";
import { NON_DESCRIPTIVE_IMAGE } from "../utils/a11yUtil";

const StartPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useLogin();
  const [showSignup, setShowSignup] = useState(false);
  const { authorization } = useAuth();

  useEffectOnce(() => {
    if (authorization?.type === "oauth") {
      navigate("/folders");
    }
  });

  return (
    <Page className="justify-end p-4 pb-safe md:justify-center lg:h-screen">
      <div className="z-10 mx-auto flex max-w-sm flex-col items-center space-y-8 text-center md:max-w-lg">
        <img src="/assets/welcome-back.svg" alt={NON_DESCRIPTIVE_IMAGE} />
        <Title as="h2" size="3xl" children={t("START_SCREEN_WELCOME")} />
        <Text color="medium">{t("START_SCREEN_DESCRIPTION")}</Text>
        <div className="w-full space-y-2">
          <TextButton label={t("LOGIN")} variant="primary" block onClick={login} />
          <TextButton label={t("SIGNUP")} variant="transparentBrand" block onClick={() => setShowSignup(true)} />
        </div>
      </div>

      <SignupPage open={showSignup} onClose={() => setShowSignup(false)} />
    </Page>
  );
};

export default StartPage;
