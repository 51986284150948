import { FC, useCallback, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { RxCollection } from "rxdb";
import useToasts from "../hooks/useToasts";
import useAuth from "../hooks/useAuth";
import { Submission } from "../types/Submission";
import useRememberFields from "../hooks/useRememberFields";
import uuidv4 from "../utils/uuid";
import {
  buildNewSubmission,
  getDefaultValues,
  getInvalidFields,
  getFailedUploads,
  updateSubmissionMeta,
} from "../utils/submissionUtil";
import useFileHandler from "../hooks/useFileHandler";
import { Field } from "../types/Field";
import FormWithContext from "./FormWithContext";
import logger from "../utils/logger";
import useDerivedStateOnce from "../hooks/useDerivedStateOnce";
import FormContentWrapper from "./FormContentWrapper";
import { FormMethods, SubmissionFormData } from "./Form";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { FormVersion } from "../types/FormVersion";
import { RememberedFieldDocument, SubmissionDocument } from "../utils/databaseUtil";
import { Theme } from "../storybook/themes";
import SubmissionDeleteModal from "./SubmissionDeleteModal";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import { DropdownMenu } from "../storybook/components/DropdownMenu/DropdownMenu";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Modal } from "../storybook/components/Modal/Modal";
import { TextButton } from "../storybook/components/TextButton/TextButton";

interface SubmissionFormProps {
  formVersion: FormVersion;
  submission: SubmissionDocument;
  theme: Theme;
  fieldCollection: RxCollection<Field>;
  submissionCollection: RxCollection<Submission>;
  rememberedFields: RememberedFieldDocument[];
}
const SubmissionForm: FC<SubmissionFormProps> = ({
  fieldCollection,
  theme,
  submission,
  formVersion,
  submissionCollection,
  rememberedFields,
}) => {
  const { customerId, authorization } = useAuth();
  const { t } = useTranslation();
  const { removeLocalFiles } = useFileHandler();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast, hideToast } = useToasts();
  const { rememberFields } = useRememberFields();
  const device = useDeviceInfo();
  const { isOnline } = useOnlineStatus();
  const [submissionButtonPressed, setSubmissionButtonPressed] = useState<boolean>(false);

  const [isHumanEdited, setHumanEdited] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAbortModal, setShowAbortModal] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [syncFailed, setSyncFailed] = useState(false);

  const methods: UseFormReturn<SubmissionFormData, any> = useForm<SubmissionFormData>({
    defaultValues: async () => getDefaultValues(submission.id, fieldCollection),
    mode: "all",
    reValidateMode: "onChange",
  });

  const formRef = useRef<FormMethods>(null);

  const sentWithPendingUploads = submission.status === "draft" && submission.submittedAt;
  const isEditingDraft = submission.status === "draft" && !submission.submittedAt;

  const handleSubmit = useCallback(
    methods.handleSubmit(
      async () => {
        if (!submission || !fieldCollection || !isInitialized) {
          return;
        }

        try {
          // We should await any pending persist operations, because it's async we're not sure if it's settled here.
          await formRef.current?.onIdle();

          const invalidFields = await getInvalidFields(fieldCollection, submission.id);

          if (!isEmpty(invalidFields)) {
            formRef.current?.onInvalid(invalidFields);
            return;
          }

          const metaSubmission = await updateSubmissionMeta(submission, device);

          const failedUploads = await getFailedUploads(submission.id, fieldCollection);
          const submittedAt = new Date().toISOString();
          if (failedUploads.length > 0) {
            // Only set the submittedAt, set final after all uploads are successful
            await metaSubmission.incrementalPatch({ submittedAt });
          } else {
            // Finalize and cleanup
            await metaSubmission.incrementalPatch({ submittedAt, status: "final" });
            await removeLocalFiles(submission.id);
          }

          await onSave();
        } catch (e) {
          logger.error("Couldn't submit submission", e);
        }
      },
      async () => {
        if (!submission || !fieldCollection) {
          return;
        }
        // We should await any pending persist operations, because it's async we're not sure if it's settled here.
        await formRef.current?.onIdle();

        // Get ALL invalid fields and pass it on
        const invalidFields = await getInvalidFields(fieldCollection, submission.id);
        formRef.current?.onInvalid(invalidFields.filter((field) => !field.entryId));
      },
    ),
    [device, methods, removeLocalFiles],
  );

  const previouslyViewed = useDerivedStateOnce((i) => !!i.viewedAt, submission);
  useDerivedStateOnce((i) => {
    if (i.status === "draft") {
      i.incrementalPatch({ viewedAt: new Date().toISOString() }).catch((e) => {
        logger.error("Couldn't set viewedAt property", e);
      });
    }
  }, submission);
  const saveMode = formVersion?.settings.saveMode;

  const navigateAfterSend = async (): Promise<void> => {
    if (saveMode === "ALL") {
      // Save & New
      await openNew();
    } else {
      goBack();
    }
  };

  const openNew = async (): Promise<void> => {
    const newSubmissionId = uuidv4();
    const newSubmission = buildNewSubmission(
      newSubmissionId,
      customerId!,
      submission.formId,
      submission.formVersionId,
      submission.form,
    );
    await submissionCollection?.upsert(newSubmission);
    navigate(`/submissions/${newSubmissionId}`, { replace: true });
  };

  const deleteSubmission = async (showMessage = true): Promise<void> => {
    try {
      await submission.remove();
      await removeLocalFiles(submission.id);
      if (showMessage) {
        showToast({
          message: t("SUBMISSION_DELETED_TOAST_TITLE"),
          icon: "CheckIcon",
        });
      }
    } catch (e) {
      logger.warn("Could not delete submission", e);
    }
  };

  const checkForEmptySubmission = async (): Promise<boolean> => {
    const fields = await fieldCollection?.find().where("submissionId").eq(submission.id).exec();
    return fields?.every((field) => !field.updatedBy);
  };

  const returnAction = async (): Promise<void> => {
    goBack();
    const isEmptySubmission = await checkForEmptySubmission();
    if (isEmptySubmission && !submission.task && !previouslyViewed) {
      await deleteSubmission(false);
    }
  };

  const goBack = (): void => {
    location.key !== "default" ? navigate(-1) : navigate("/folders");
  };

  const header = (
    <div className="flex h-14 items-center justify-between">
      <IconAndTextButton
        icon="ChevronLeftIcon"
        variant="transparentWhite"
        size="md"
        onClick={returnAction}
        label={isHumanEdited && isEditingDraft ? t("SAVE_AND_CLOSE") : t("BACK")}
      />
      {isEditingDraft && isInitialized && (
        <DropdownMenu
          menuButton={() => (
            <IconAndTextButton
              iconAlign="right"
              icon="ChevronDownIcon"
              variant="transparentWhite"
              label={t("OPTIONS")}
              size="md"
              loading={submissionButtonPressed && methods.formState.isSubmitting}
              disabled={!submissionButtonPressed && methods.formState.isSubmitting}
            />
          )}
          items={[
            ...(saveMode !== "NO_SAVE"
              ? [
                  {
                    label: t("SEND_FORM"),
                    onClick: (): void => {
                      setSubmissionButtonPressed(true);
                      handleSubmit()
                        .catch((err) => {
                          throw new Error("Could not submit submission", { cause: err });
                        })
                        .finally(() => setSubmissionButtonPressed(false));
                    },
                  },
                  { label: t("SAVE_AND_CLOSE"), onClick: goBack },
                ]
              : []),
            {
              label: t("DELETE"),
              type: "destructive",
              onClick: () => setShowDeleteModal(true),
            },
          ]}
        />
      )}

      {sentWithPendingUploads && isInitialized && (
        <TextButton
          variant="transparentWhite"
          size="md"
          label={t("CANCEL_SENDING_BUTTON")}
          onClick={() => setShowAbortModal(true)}
        />
      )}
    </div>
  );

  const onSave = async (): Promise<void> => {
    rememberFields(submission?.id, submission?.formId, formVersion.fields);
    await navigateAfterSend();
    if (authorization.type === "oauth") {
      if (!isOnline) {
        showToast({
          message: t("OFFLINE_SUBMISSION_TOAST_TITLE"),
        });
        return;
      }
      showToast({
        message: t("SUBMISSION_SENT_TOAST_TITLE"),
        icon: "CheckIcon",
        button: {
          type: "text",
          label: t("SUBMISSION_SENT_TOAST_ACTION"),
          onClick: () => {
            hideToast();
          },
          navigateTo: "/sent",
        },
      });
    }
  };

  const deleteModal = (
    <SubmissionDeleteModal
      showModal={showDeleteModal}
      onConfirm={async () => {
        goBack();
        await deleteSubmission();
        setShowDeleteModal(false);
      }}
      onModalClose={async () => setShowDeleteModal(false)}
    />
  );

  const abortModal = (
    <Modal
      title={t("SUBMISSION_CANCEL_SENDING_MODAL_TITLE")}
      content={{ kind: "message", message: t("SUBMISSION_CANCEL_SENDING_MODAL_DESCRIPTION") }}
      open={showAbortModal}
      onClose={() => setShowAbortModal(false)}
      buttons={[
        { label: t("CANCEL"), onClick: () => setShowAbortModal(false) },
        {
          label: t("SUBMISSION_CANCEL_CONFIRM_BUTTON"),
          variant: "destructive",
          onClick: async (): Promise<void> => {
            try {
              await submission.incrementalPatch({ submittedAt: undefined, meta: undefined });
            } catch (e) {
              throw Error("Couldn't abort sending submission with pending file uploads", { cause: e });
            } finally {
              setShowAbortModal(false);
            }
          },
        },
      ]}
    />
  );

  return (
    <>
      {deleteModal}
      {abortModal}
      <FormProvider {...methods}>
        <FormContentWrapper
          submission={submission}
          theme={theme}
          header={header}
          onSubmit={saveMode !== "NO_SAVE" ? handleSubmit : undefined}
          isInitialized={isInitialized}
          syncFailed={syncFailed}
        >
          <FormWithContext
            formVersion={formVersion}
            fieldProperties={formVersion.fieldProperties}
            fieldsCollection={fieldCollection}
            submission={submission}
            rememberedFields={rememberedFields}
            setHumanEdited={setHumanEdited}
            isInitialized={isInitialized}
            setInitialized={setInitialized}
            ref={formRef}
            setSyncFailed={setSyncFailed}
            validationMode={isHumanEdited && previouslyViewed ? "trigger_immediately" : "default"}
            shouldSync
          />
        </FormContentWrapper>
      </FormProvider>
    </>
  );
};

export default SubmissionForm;
