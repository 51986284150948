import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInterval } from "usehooks-ts";
import { Duration } from "luxon";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { DurationResult, Widget } from "../../types/Widget";
import { durationToString } from "../../utils/stringUtil";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { Label } from "../../storybook/components/Label/Label";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";

export interface WidgetStopwatchProps {
  label_text: string;
}

const WidgetStopwatch: Widget<WidgetStopwatchProps, WidgetResult<DurationResult>> = (props) => {
  const { t } = useTranslation();
  const { field, helpers, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetStopwatch.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );
  const [elapsedTime, setElapsedTime] = useState<Duration>();
  const running = field.result?.rawValue?.start && !field.result?.rawValue?.end;

  useInterval(() => updateElapsedTime(), running ? 500 : null);

  const updateElapsedTime = (): void => {
    if (!field.result?.rawValue?.start) {
      return;
    }

    const startTimestamp = new Date(field.result.rawValue?.start).getTime();
    setElapsedTime(Duration.fromMillis(Date.now() - startTimestamp));
  };

  const start = async (): Promise<void> => {
    await helpers.persist({
      start: new Date().toISOString(),
    });
  };

  const stop = async (): Promise<void> => {
    if (!field.result?.rawValue?.start) {
      return;
    }
    const endDate = new Date();
    const startDate = new Date(field.result?.rawValue?.start);
    await helpers.persist({
      start: field.result.rawValue.start,
      end: endDate.toISOString(),
      duration: Duration.fromMillis(endDate.getTime() - startDate.getTime()).toISO() || undefined,
    });
  };

  const reset = async (): Promise<void> => {
    setElapsedTime(undefined);
    await helpers.persist(undefined);
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }

  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const timerClasses =
    "flex h-12 w-auto grow items-center justify-center rounded-lg bg-brand-100 font-semibold text-gray-700";

  return (
    <article
      aria-label={`${props.field.properties.label_text} - ${t("STOPWATCH_FIELD")}`}
      className="relative"
      ref={field.inputRef}
    >
      <Label
        htmlFor={field.props.name}
        label={props.field.properties.label_text}
        showClearBtn={!isDisabled && !!field.result?.rawValue?.duration}
        onClear={reset}
        clearLabel={t("CLEAR")}
      />
      <div className="flex gap-x-1">
        {field.result?.rawValue?.duration && (
          <time dateTime={field.result.rawValue.duration} aria-label={t("STOPWATCH_RESULT")} className={timerClasses}>
            {durationToString(Duration.fromISO(field.result.rawValue.duration), {
              forceHours: true,
              includeMillis: true,
            })}
          </time>
        )}
        {field.result?.rawValue?.start && !field.result.rawValue.end && (
          <div className="flex w-full items-center space-x-1.5">
            <div role="timer" aria-label={t("STOPWATCH_COUNTING")} className={timerClasses}>
              {elapsedTime ? durationToString(elapsedTime, { realTime: true, forceHours: true }) : "00:00:00"}
            </div>
            <IconButton aria-label={t("STOP_TIMER")} variant="primary" onClick={stop} icon="StopIcon" />
          </div>
        )}
        {!field.result?.rawValue && (
          <IconAndTextButton
            block
            variant="default"
            label={isDisabled ? t("NO_TIME_REGISTERED") : t("START")}
            icon="ClockIcon"
            onClick={start}
            disabled={isDisabled}
          />
        )}
      </div>
      {field.controller.formState.submitCount > 0 && field.props.errorMessage && (
        <Feedback status="error" message={field.props.errorMessage} />
      )}
    </article>
  );
};

WidgetStopwatch.defaultValue = (_field, defaultMeta): WidgetResult<DurationResult> => ({
  type: "duration",
  meta: {
    widget: "stopwatch",
    ...defaultMeta,
  },
});

WidgetStopwatch.validate = (val, properties, t): string | undefined => {
  if (val?.start && !val.end) {
    return t("VALIDATION_STOPWATCH_RUNNING");
  }
  return undefined;
};

export default WidgetStopwatch;
