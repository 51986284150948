import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useSentSubmission from "../hooks/useSentSubmission";
import useSubmissionCopy from "../hooks/useSubmissionCopy";
import OfflineContent from "../pages/errorpages/OfflineContent";
import useNavigateAsync from "../hooks/useNavigateAsync";
import StaticForm from "./StaticForm";
import { SubmissionDocument } from "../utils/databaseUtil";
import FormWithContext from "./FormWithContext";
import SubmissionNotFoundContent from "../pages/errorpages/SubmissionNotFoundContent";
import useOnlineStatus from "../hooks/useOnlineStatus";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { Drawer } from "../storybook/components/Drawer/Drawer";

interface SentSubmissionDrawerProps {
  id: string;
  onClose: () => void;
}

const SentSubmissionDrawer: FC<SentSubmissionDrawerProps> = ({ id, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { isLoading, isNotFound, submission, fields, defaultValues, formVersion } = useSentSubmission(id);
  const { copy } = useSubmissionCopy(submission, fields, formVersion?.formId);
  const { isOnline } = useOnlineStatus();
  const navigate = useNavigateAsync();

  const errorState = ((): JSX.Element | undefined => {
    if (!isOnline) {
      return <OfflineContent centered />;
    }

    if (isNotFound || !submission || !fields) {
      return <SubmissionNotFoundContent centered />;
    }

    return undefined;
  })();

  const copySubmission = async (): Promise<void> => {
    setLoading(true);
    try {
      const result = await copy();
      navigate(`/submissions/${result}`);
    } finally {
      setLoading(false);
    }
  };

  const formElements =
    formVersion && defaultValues && submission ? (
      <div className="flex flex-col gap-y-6 px-4 py-6">
        <StaticForm defaultValues={defaultValues}>
          <FormWithContext
            formVersion={formVersion}
            fieldProperties={formVersion.fieldProperties}
            submission={submission as SubmissionDocument}
            readOnly
          />
        </StaticForm>
      </div>
    ) : (
      <Spinner />
    );

  return (
    <Drawer
      header={{
        kind: "simple",
        title: t("SEARCH_SUBMISSIONS"),
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
      }}
      open
      onClose={onClose}
      footer={
        !isNotFound
          ? {
              kind: "default",
              primaryButton: {
                label: t("CREATE"),
                loading,
                onClick: copySubmission,
              },
            }
          : undefined
      }
      contentPadding={false}
    >
      {isLoading && <Spinner />}
      {!errorState && !isLoading ? formElements : errorState}
    </Drawer>
  );
};

export default SentSubmissionDrawer;
