import { FC, useCallback, useMemo, useRef, useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { t } from "i18next";
import { useMutation } from "@tanstack/react-query";
import { isEmpty } from "lodash-es";
import { useBeforeunload } from "react-beforeunload";
import { RxCollection } from "rxdb";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router";
import FormContentWrapper from "./FormContentWrapper";
import FormWithContext from "./FormWithContext";
import { FormMethods, SubmissionFormData } from "./Form";
import { getDefaultValues, getInvalidFields, updateSubmissionMeta } from "../utils/submissionUtil";
import { FormVersion } from "../types/FormVersion";
import { Field, fieldToRemote, RemoteField } from "../types/Field";
import { RemoteSubmission, submissionToRemote } from "../types/Submission";
import logger from "../utils/logger";
import useFileHandler from "../hooks/useFileHandler";
import useDeviceInfo from "../hooks/useDeviceInfo";
import { RememberedFieldDocument, SubmissionDocument } from "../utils/databaseUtil";
import { MoreAppError, useMoreAppClient } from "../context/MoreAppContext";
import { Theme } from "../storybook/themes";
import useToasts from "../hooks/useToasts";
import SubmissionDeleteModal from "./SubmissionDeleteModal";
import { IconAndTextButton } from "../storybook/components/IconAndTextButton/IconAndTextButton";
import branding from "../utils/brandingUtil";

interface DirectUrlFormProps {
  formVersion: FormVersion;
  submission: SubmissionDocument;
  theme: Theme;
  fieldCollection: RxCollection<Field>;
  rememberedFields: RememberedFieldDocument[];
  isLoggedIn: boolean;
  preview?: boolean;
}
const DirectUrlForm: FC<DirectUrlFormProps> = ({
  formVersion,
  submission,
  theme,
  fieldCollection,
  rememberedFields,
  isLoggedIn,
  preview,
}) => {
  const { removeLocalFiles } = useFileHandler();
  const device = useDeviceInfo();
  const saveMode = formVersion?.settings.saveMode;
  const client = useMoreAppClient();
  const [isInitialized, setInitialized] = useState(false);
  const { showToast } = useToasts();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useBeforeunload((event) => {
    if (submission.status !== "final") {
      event.preventDefault();
    }
  });

  const { mutateAsync: sendSubmission } = useMutation<
    any,
    MoreAppError,
    { submissionDto: RemoteSubmission & { fields: RemoteField[] } }
  >({
    mutationFn: async ({ submissionDto }) => client?.post(`/api/v1.0/client/submissions`, submissionDto),
  });

  const formRef = useRef<FormMethods>(null);
  const navigate = useNavigate();

  const methods: UseFormReturn<SubmissionFormData, any> = useForm<SubmissionFormData>({
    defaultValues: async () => getDefaultValues(submission.id, fieldCollection),
    mode: "all",
    reValidateMode: "onChange",
  });

  const handleSubmit = useCallback(
    methods.handleSubmit(
      async () => {
        if (!submission || !fieldCollection || !isInitialized) {
          return;
        }

        // We should await any pending persist operations, because it's async we're not sure if it's settled here.
        await formRef.current?.onIdle();
        const invalidFields = await getInvalidFields(fieldCollection, submission.id);
        if (!isEmpty(invalidFields)) {
          formRef.current?.onInvalid(invalidFields);
          return;
        }

        // Don't allow preview submissions to be sent
        if (preview) {
          showToast({ message: t("VALIDATION_SUCCEEDED_TOAST"), icon: "CheckIcon" });
          return;
        }

        try {
          // Update meta for final submission
          const fields = await fieldCollection.find().where("submissionId").eq(submission.id).exec();
          const metaSubmission = await updateSubmissionMeta(submission, device);
          // Try to send to API
          await sendSubmission({
            submissionDto: {
              fields: fields.map(fieldToRemote),
              ...submissionToRemote(metaSubmission),
              status: "final",
            },
          });

          // Finalize and cleanup
          await metaSubmission.incrementalPatch({ status: "final" });
          await removeLocalFiles(submission.id);
        } catch (e) {
          logger.error("Couldn't submit submission", e);
        }
      },
      async () => {
        if (!submission || !fieldCollection) {
          return;
        }
        // We should await any pending persist operations, because it's async we're not sure if it's settled here.
        await formRef.current?.onIdle();

        // Get ALL invalid fields and pass it on
        const invalidFields = await getInvalidFields(fieldCollection, submission.id);
        formRef.current?.onInvalid(invalidFields.filter((field) => !field.entryId));
      },
    ),
    [device, methods, removeLocalFiles, sendSubmission, fieldCollection, submission, preview],
  );

  const showExitButton = (): boolean => Capacitor.isNativePlatform() || isLoggedIn;

  const showLogoHeader = !isLoggedIn && !branding.isBranded();

  const header = useMemo(
    () => (
      <div className="grid h-14 grid-cols-3 items-center">
        {showExitButton() && (
          <IconAndTextButton
            icon="ChevronLeftIcon"
            variant="transparentWhite"
            size="md"
            onClick={() => setShowDeleteModal(true)}
            label={t("BACK")}
            className="justify-self-start"
          />
        )}
        {showLogoHeader && (
          <a className="col-start-2 justify-self-center" href="https://moreapp.com" rel="noreferrer" target="_blank">
            <img src="/assets/logo-inverted.png" alt={t("LOGO_ALT")} />
          </a>
        )}
      </div>
    ),
    [],
  );

  return (
    <>
      <SubmissionDeleteModal
        showModal={showDeleteModal}
        onConfirm={async () => {
          await submission.remove();
          await removeLocalFiles(submission.id);
          setShowDeleteModal(false);
          navigate("/");
        }}
        onModalClose={async () => setShowDeleteModal(false)}
      />
      <FormProvider {...methods}>
        <FormContentWrapper
          submission={submission}
          preview={preview}
          theme={theme}
          header={header}
          onSubmit={saveMode !== "NO_SAVE" ? handleSubmit : undefined}
          isInitialized={isInitialized}
        >
          <FormWithContext
            formVersion={formVersion}
            fieldProperties={formVersion.fieldProperties}
            fieldsCollection={fieldCollection}
            submission={submission}
            rememberedFields={rememberedFields}
            isInitialized={isInitialized}
            setInitialized={setInitialized}
            ref={formRef}
          />
        </FormContentWrapper>
      </FormProvider>
    </>
  );
};
export default DirectUrlForm;
