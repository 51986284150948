import { forwardRef, useContext } from "react";
import Form, { FormMethods, FormProps } from "./Form";
import { FocusProvider } from "../context/FocusContext";
import FormState from "./FormState";
import Compose from "./Compose";
import { CustomerResourceProvider } from "../context/CustomerResourceContext";
import { ContainerContext } from "./FormContentWrapper";

const FormWithContext = forwardRef<FormMethods, FormProps>(({ ...props }, ref) => {
  const containerRef = useContext(ContainerContext);

  return (
    <Compose components={[FocusProvider, CustomerResourceProvider]}>
      <FormState
        submission={props.submission}
        formVersion={props.formVersion}
        fieldProperties={props.fieldProperties}
        fieldsCollection={props.fieldsCollection}
        readOnly={!!props.readOnly}
        initialized={props.isInitialized}
        setHumanEdited={props.setHumanEdited}
      />
      <Form {...props} setInitialized={props.setInitialized} containerRef={containerRef ?? undefined} ref={ref} />
    </Compose>
  );
});
FormWithContext.displayName = "FormWithContext";

export default FormWithContext;
