import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WidgetResult } from "../../../types/Field";
import { FileResult } from "../../../types/Widget";
import { ControlledField } from "../../../hooks/useWidget";
import { Feedback } from "../../../storybook/components/Feedback/Feedback";

const FileFeedback: FC<{ field: ControlledField<WidgetResult<FileResult>> }> = ({ field }) => {
  const { t } = useTranslation();

  if (field.result?.meta.uploadStatus === "failed") {
    return <Feedback className="mt-2" message={t("VALIDATION_UPLOAD_FAILED")} status="error" />;
  }

  if (field.result?.meta.uploadStatus === "uploading" || !field.props.errorMessage) {
    return null;
  }

  return <Feedback className="mt-2" status="error" message={field.props.errorMessage} />;
};

export default FileFeedback;
