import { ChangeEvent, RefObject, useRef, useState } from "react";
import uuidv4 from "../utils/uuid";
import { FileResult } from "../types/Widget";
import { Submission } from "../types/Submission";
import { WidgetResult } from "../types/Field";
import useFileHandler from "./useFileHandler";
import { ControlledField, PersistWithUploadStatusFn } from "./useWidget";
import { getExtensionBlob, isExtensionAllowedMimeType } from "../utils/fileUtil";

type UseFileWidgetResult = {
  inputFile: RefObject<HTMLInputElement>;
  percentageUploaded: number;
  isAttemptingOrUploaded?: boolean;
  showCompletedMessage: boolean;
  uploadFileOnChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  cancelUpload: () => Promise<void>;
  retryUpload: () => Promise<void>;
  removeFile: () => Promise<void>;
};

const useFileWidget = (
  helpers: { persistWithUploadStatus: PersistWithUploadStatusFn<FileResult> },
  field: ControlledField<WidgetResult<FileResult>>,
  submission?: Submission,
  allowedMimeTypeArray?: string[],
): UseFileWidgetResult => {
  const { storeAndUploadFile } = useFileHandler();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const uploadFileOnChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.currentTarget.files || !event.currentTarget.files[0]) {
      return;
    }
    await uploadFile(event.currentTarget.files[0]);
  };

  const uploadFile = async (file: File): Promise<void> => {
    const id = uuidv4();
    const extension = getExtensionBlob(file);
    const initialValue: FileResult = { id, name: file.name, extension };

    setPercentageUploaded(0);

    if (allowedMimeTypeArray && !isExtensionAllowedMimeType(extension ?? "", allowedMimeTypeArray)) {
      await helpers.persistWithUploadStatus(initialValue, "invalid_extension", { shouldWait: false });
      return;
    }

    await helpers.persistWithUploadStatus(initialValue, "uploading", { shouldWait: false });

    const result = await storeAndUploadFile(
      URL.createObjectURL(file),
      submission!.id,
      id,
      ({ loaded, total = 1 }) => {
        setPercentageUploaded((loaded / total) * 100);
      },
      abortController.signal,
      file.name,
    );

    if (abortController.signal.aborted) {
      await removeFile();
      setAbortController(new AbortController());
      return;
    }

    await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus);

    if (result.uploadStatus === "uploaded") {
      setShowCompletedMessage(true);
      setTimeout(() => setShowCompletedMessage(false), 4000);
    }
  };

  const removeFile = async (): Promise<void> => {
    if (inputFile.current) {
      inputFile.current.value = "";
      await helpers.persistWithUploadStatus();
    }
  };

  const cancelUpload = async (): Promise<void> => {
    abortController.abort();
    await removeFile();
  };

  const retryUpload = async (): Promise<void> => {
    const files = inputFile.current?.files;
    if (files && files[0]) {
      await uploadFile(files[0]);
    }
  };

  const isAttemptingOrUploaded = field.result?.meta.uploadStatus && field.result?.meta.uploadStatus !== "aborted";

  return {
    inputFile,
    percentageUploaded,
    isAttemptingOrUploaded,
    showCompletedMessage,
    uploadFileOnChange,
    cancelUpload,
    retryUpload,
    removeFile,
  };
};
export default useFileWidget;
