import { AxiosInstance, AxiosResponse } from "axios";
import { RxCollection } from "rxdb";
import { isEmpty } from "lodash-es";
import logger from "./logger";
import { DataSource, DataSourceEntry, DataSourceMeta, DataSourceNotFoundError } from "../types/Datasource";
import { readDir, readFileAsText, rmDir, stat, writeFile, writeFileBlob } from "./fileSystemUtil";
import { IMAGE_OPT_URL } from "../constants";
import { Currency } from "../types/Currency";
import { formatCurrency } from "./formatter";
import { toIsoCurrency } from "./currencyUtil";

const NOT_FOUND = 404;
const OK = 200;
const DATASOURCES_PATH = "datasources";

const getDatasourceVersion = async (client: AxiosInstance, customerId: number, id: string): Promise<any> => {
  const { status: versionStatus, data: version } = await client.get(
    `/api/v1.0/client/customers/${customerId}/datasources/${id}/version`,
  );

  if (versionStatus === NOT_FOUND) {
    throw new DataSourceNotFoundError(`Couldn't find datasource ${id} for customer ${customerId}`);
  }
  return version;
};

const getDatasource = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
  version: string,
): Promise<AxiosResponse<DataSourceEntry[]>> =>
  client.get(`/api/v1.0/customers/${customerId}/datasources/${id}/entries/version/${version}`);

const getDatasourceBlob = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
  version: string,
): Promise<AxiosResponse<any, any>> =>
  client.get(`/api/v1.0/customers/${customerId}/datasources/${id}/entries/version/${version}`, {
    responseType: "blob",
  });

export const fetchDataSource = async (
  username: string,
  customerId: number,
  id: string,
  client: AxiosInstance,
  datasourceCollection?: RxCollection<DataSourceMeta>,
): Promise<DataSource> => {
  const version = await getVersion(client, customerId, id, datasourceCollection);

  const localEntries = await fetchFromFilesystem(version, id, customerId, username);
  if (localEntries) {
    return { id, entries: deduplicateEntries(localEntries), fallback: false };
  }

  const { status, data: entries } = await getDatasource(client, customerId, id, version);

  if (status === NOT_FOUND) {
    throw new DataSourceNotFoundError(`Couldn't retrieve datasource version ${version}`);
  }

  if (status !== OK) {
    const fallbackEntries = await fetchLatestFromFilesystem(id, customerId, username);
    if (fallbackEntries) {
      return { id, entries: deduplicateEntries(fallbackEntries), fallback: true };
    }
    throw Error(`Could not fetch datasource, statusCode: ${status}`);
  }

  return { id, entries, fallback: false };
};

export const prefetchDatasource = async (
  username: string,
  customerId: number,
  id: string,
  client: AxiosInstance,
  collection: RxCollection<DataSourceMeta>,
): Promise<void> => {
  const version = await getDatasourceVersion(client, customerId, id);

  try {
    await stat({ path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}` });
  } catch (e) {
    const { status, data } = await getDatasourceBlob(client, customerId, id, version);

    if (status === OK) {
      await removeLocalDatasource(id, username, customerId);
      await collection.upsert({ customerId, id, version, updatedAt: new Date().toISOString() });
      await writeFileBlob({
        path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}`,
        blob: data,
      });
      URL.revokeObjectURL(data);
    }
  }
};

export const onDatasourceSuccess = async (
  username: string,
  customerId: number,
  data: DataSource,
  id: string,
  datasourceCollection: RxCollection<DataSourceMeta>,
): Promise<void> => {
  if (isEmpty(data.entries)) {
    return;
  }
  const { version } = data.entries[0];
  await stat({ path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}` }).catch(async () => {
    await removeLocalDatasource(id, username, customerId);
    await datasourceCollection.upsert({
      customerId,
      id,
      version,
      updatedAt: new Date().toISOString(),
    });
    return writeFile({
      path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${data.entries[0].version}`,
      data: JSON.stringify(data.entries),
    });
  });
};

export const onDatasourceError = async (username: string, customerId: number, e: Error, id: string): Promise<void> => {
  if (e instanceof DataSourceNotFoundError) {
    await removeLocalDatasource(id, username, customerId);
  } else {
    logger.warn("Something went wrong while fetching the datasource");
  }
};

export const fetchFromFilesystem = async (
  version: string,
  id: string,
  customerId: number,
  username: string,
): Promise<DataSourceEntry[] | undefined> => {
  try {
    const data = await readFileAsText({
      path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}/${version}`,
    });
    return (await JSON.parse(data)) as DataSourceEntry[];
  } catch (e) {
    return undefined;
  }
};

export const fetchLatestFromFilesystem = async (
  id: string,
  customerId: number,
  username: string,
): Promise<DataSourceEntry[] | undefined> => {
  try {
    const { files } = await readDir({
      path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}`,
    });

    if (files.length === 0) {
      return undefined;
    }

    return await fetchFromFilesystem(files[0].name, id, customerId, username);
  } catch (e) {
    return undefined;
  }
};

const getVersion = async (
  client: AxiosInstance,
  customerId: number,
  id: string,
  datasourceCollection?: RxCollection<DataSourceMeta>,
): Promise<any> =>
  getDatasourceVersion(client, customerId, id).catch(async () => {
    const datasource = await datasourceCollection?.findOne(id).exec();
    if (!datasource) {
      throw new DataSourceNotFoundError();
    }
    return datasource?.version;
  });

const removeLocalDatasource = async (id: string, username: string, customerId: number): Promise<void> => {
  try {
    await rmDir({ path: `${username}/${customerId}/${DATASOURCES_PATH}/${id}` });
  } catch (e) {
    logger.debug("Could not delete old datasources", e);
  }
};

export const getEnabledFields = (mapping?: Record<string, boolean>): string[] =>
  Object.entries(mapping ?? {})
    .filter(([, value]) => value)
    .map(([key]) => key);

export const getDatasourceImg = (url?: string): string | undefined =>
  url ? `${IMAGE_OPT_URL}/?url=${encodeURIComponent(url)}` : undefined;

export const getCatalogueItemTitle = (data: Record<string, any>, entryFields: string[]): any => {
  if (data.name) {
    return data.name;
  }
  return Object.entries(data)
    .filter(([key]) => entryFields.some((x) => x === key))
    .map(([, value]) => value)
    .filter((value) => !isEmpty(value))
    .join(", ");
};

export const asCurrency = (price: number, currency?: Currency, precision?: number): string | undefined => {
  if (Number.isNaN(price)) {
    return undefined;
  }
  return formatCurrency(price, "en-GB", {
    currency: toIsoCurrency(currency) ?? "EUR",
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: precision,
  });
};

export const deduplicateEntries = (entries: DataSourceEntry[]): DataSourceEntry[] => {
  const seenIds = new Set<string>();

  return entries.reduce((deduplicatedEntries: DataSourceEntry[], entry: DataSourceEntry) => {
    const dataId = entry.data.id;
    if (!seenIds.has(dataId)) {
      seenIds.add(dataId);
      deduplicatedEntries.push(entry);
    }
    return deduplicatedEntries;
  }, []);
};
