import { FC, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useRxCollection } from "rxdb-hooks";
import { first } from "lodash-es";
import { t } from "i18next";
import useFormVersion from "../hooks/useFormVersion";
import { Submission, SubmissionSummary } from "../types/Submission";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import OfflinePage from "./errorpages/OfflinePage";
import useOnlineStatus from "../hooks/useOnlineStatus";
import LoadingPage from "./LoadingPage";
import { Field } from "../types/Field";
import useTheme from "../hooks/useTheme";
import SubmissionForm from "../components/SubmissionForm";
import { useMoreAppClient } from "../context/MoreAppContext";
import useAuth from "../hooks/useAuth";
import useStatusBar from "../hooks/useStatusBar";
import logger from "../utils/logger";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import useToasts from "../hooks/useToasts";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import useLocalRememberedFields from "../hooks/useLocalRememberedFields";

const SubmissionPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isOnline } = useOnlineStatus();
  const client = useMoreAppClient();
  const { customerId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToasts();

  const submissionCollection = useRxCollection<Submission>("submissions");
  const fieldCollection = useRxCollection<Field>("fields");
  const { result, isFetching } = useLocalSubmissions((collection) => collection?.find().where("id").equals(id));

  const submission = useMemo(() => first(result), [result]);

  const {
    data: formVersion,
    isLoading: isLoadingFormVersion,
    error: formVersionError,
  } = useFormVersion(submission?.formVersionId, submission?.customerId, submission?.formId);

  const { rememberedFields, isFetchingRememberedFields } = useLocalRememberedFields(submission?.formId);

  useEffect(() => {
    if (formVersionError) {
      showToast({
        message: formVersionError.status === 403 ? t("TASKS_NO_ACCESS") : t("TASKS_FAILED_TO_LOAD"),
      });
      location.key !== "default" ? navigate(-1) : navigate("/folders", { replace: true });
    }
  }, [formVersionError, showToast, navigate, location]);

  useEffect(() => {
    // Try navigation to remote submission, when available
    if (submission == null && !isFetching) {
      client!
        .get<SubmissionSummary>(`/api/v1.0/client/customers/${customerId}/submissions/${id}`)
        .then((data) => navigate(`/sent/${data.data.id}`, { replace: true }))
        .catch(() => {
          logger.log(`Couldn't get completed submission ${id}, probably deleted or unprocessed`);
          location.key !== "default" ? navigate(-1) : navigate("/folders", { replace: true });
        });
    }
  }, [submission, isFetching, client, customerId, id, navigate, location]);

  useSubmissionPageTitle(submission);
  const theme = useTheme(formVersion);
  useStatusBar(theme.color);
  const isLoading = isFetching || isFetchingRememberedFields || isLoadingFormVersion;

  if (isLoading) {
    return <LoadingPage conditions={[{ labelKey: "LOADING_ERROR_DIRECT_URL_TOKEN", value: isLoading }]} />;
  }

  // Wait until default values are loaded in
  if (!fieldCollection || !submissionCollection) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_SUBMISSION_DATABASE_FIELD", value: !fieldCollection },
          { labelKey: "LOADING_SUBMISSION_DATABASE_SUBMISSION", value: !submissionCollection },
        ]}
      />
    );
  }

  if (submission == null || !formVersion) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  return (
    <SubmissionForm
      submission={submission}
      fieldCollection={fieldCollection}
      formVersion={formVersion}
      theme={theme}
      submissionCollection={submissionCollection}
      rememberedFields={rememberedFields}
    />
  );
};

export default SubmissionPage;
