import { FC } from "react";
import classNames from "classnames";

const WidgetInvisible: FC<{ withHeight: boolean }> = ({ withHeight }) => {
  // A minimum of 2px height is needed for an element to be accepted by react-viewport-list, otherwise it completely breaks. The negative margin is the gap plus the height of this element.
  const classes = classNames("-mt-7 border-t-4 border-transparent", withHeight && "box-content h-1");
  return <div className={classes} />;
};

export default WidgetInvisible;
