import { FC, MutableRefObject, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";
import { useWatch } from "react-hook-form";
import {
  getCalculatedFieldId,
  highestOrder,
  isErrorWidget,
  isFieldVisible,
  widgetResultByDataName,
} from "../../utils/formUtil";
import Form, { FormMethods, SubmissionFormData } from "../Form";
import { ActiveEntry, DeleteIntent, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import uuidv4 from "../../utils/uuid";
import logger from "../../utils/logger";
import useWidget, { ControlledField } from "../../hooks/useWidget";
import useRememberFields from "../../hooks/useRememberFields";
import TemplateContent from "../TemplateContent";
import WidgetHidden from "./WidgetHidden";
import { FormField, SubForm } from "../../types/FormVersion";
import WidgetError from "./WidgetError";
import useDrawer from "../../hooks/useDrawer";
import { getInvalidFields } from "../../utils/submissionUtil";
import { Modal } from "../../storybook/components/Modal/Modal";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { Label } from "../../storybook/components/Label/Label";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { MenuItem } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { getPlaceholderNames } from "../../utils/stringUtil";
import { sortEntries } from "../../utils/entryUtil";

export interface WidgetSubformProperties {
  label_text: string;
  form: SubForm;
  target_form_id?: string;
  add_button_text?: string;
  itemHtml?: string;
  min_items?: number;
  max_items?: number;
}

export type SubformEntry<M> = {
  id: string;
  submissionId: string;
  meta: Meta & M;
  deleted: boolean;
};

type Meta = {
  createdOn: string;
  order: number;
};

const hasEntryError = (field: ControlledField<WidgetResult<void>>, entryId: string): boolean => {
  const { error } = field.controller.fieldState;
  return error?.type === "entries" && error.message?.indexOf(entryId) !== -1;
};

const WidgetSubform: Widget<WidgetSubformProperties, WidgetResult<void>> = (props) => {
  const { t } = useTranslation();
  const [activeEntry, setActiveEntry] = useState<ActiveEntry | undefined>(undefined);
  const [open, setOpen, isOnTop] = useDrawer(props.field.uid);
  const [pendingRemovalEntry, setPendingRemovalEntry] = useState<DeleteIntent | undefined>();
  const initialFocus: MutableRefObject<any> = useRef(null);
  const formRef = useRef<FormMethods>(null);
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetSubform.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );
  const { rememberFields } = useRememberFields();

  const setDrawerOpen = useCallback((activityEntry: ActiveEntry) => {
    setActiveEntry(activityEntry);
    setOpen(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setDrawerClose = (): void => {
    setOpen(false);
    setActiveEntry(undefined);
  };

  const entries = useMemo(
    () => field.result?.entries?.filter((x) => !x.deleted).sort(sortEntries) ?? [],
    [field.result?.entries],
  );

  const formVersion = props.field.properties.target_form_id
    ? props.context.fieldProperties[props.field.properties.target_form_id] // linked subform
    : props.field.properties.form; // inline subform

  const remove = (entryId: string): void => {
    const entry = entries?.find((e) => e.id === entryId);
    if (entry) {
      helpers.removeEntry(entry).catch((e) => logger.error("Can't remove subform entry", e));
    } else {
      logger.warn(`Tried removing entry ${entryId} which wasn't available`);
    }
  };

  const deleteModal = useMemo(() => {
    const isBack = pendingRemovalEntry?.type === "BACK_BUTTON";
    return (
      <Modal
        title={isBack ? t("DISCARD_MODAL_TITLE") : t("SUBFORM_DELETE_MODAL_TITLE")}
        content={{
          kind: "message",
          message: isBack ? t("DISCARD_MODAL_DESCRIPTION") : t("SUBFORM_DELETE_MODAL_DESCRIPTION"),
        }}
        open={!!pendingRemovalEntry}
        onClose={() => {
          setPendingRemovalEntry(undefined);
        }}
        buttons={[
          {
            label: t("CANCEL"),
            onClick: (): void => {
              setPendingRemovalEntry(undefined);
            },
          },
          {
            label: t("DELETE"),
            variant: "destructive",
            onClick: (): void => {
              pendingRemovalEntry && remove(pendingRemovalEntry.id);
              setPendingRemovalEntry(undefined);
              setDrawerClose();
            },
          },
        ]}
      />
    );
  }, [pendingRemovalEntry]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasMaxItems = props.field.properties.max_items && entries.length >= props.field.properties.max_items;
  const hideAddButton = hasMaxItems || (isDisabled && entries.length > 0);

  const saveEntry = async (entryId: string, enforceValidation?: boolean): Promise<void> => {
    await formRef.current?.onIdle();
    if (props.context.submission) {
      rememberFields(props.context.submission.id, props.context.submission.formId, formVersion.fields, entryId);
    }
    // Trigger validation for widget (including underlying widgets)
    await helpers.trigger(field.result?.meta.fieldId);

    if (!hasEntryError(field, entryId) || !enforceValidation) {
      setDrawerClose();
      return;
    }

    if (enforceValidation && props.context.fieldsCollection) {
      const invalidFields = await getInvalidFields(
        props.context.fieldsCollection,
        props.context.submission.id,
        entryId,
      );

      if (!isEmpty(invalidFields)) {
        formRef.current?.onInvalid(invalidFields);
      } else {
        setDrawerClose();
      }
    }
  };

  const entryComponents = useMemo(
    () =>
      entries.map((entry, index) => {
        const fieldError = field.controller.fieldState.error;
        const entryHasError = fieldError?.type === "entries" && fieldError.message?.indexOf(entry.id) !== -1;
        const parentId = field.result?.meta.fieldId;
        const dataNamesInItemHtml = getPlaceholderNames(props.field.properties.itemHtml);
        const entryFieldIds = formVersion.fields
          .filter((formField) => dataNamesInItemHtml.includes(formField.properties.data_name))
          .map((formField) => getCalculatedFieldId(formField.uid, props.context.submission.id, entry.id, parentId));
        return (
          <SubformEntryComponent
            key={entry.id}
            entry={entry}
            submissionId={props.context.submission.id}
            onDelete={() => setPendingRemovalEntry({ type: "DELETE_BUTTON", id: entry.id })}
            onOpen={() => setDrawerOpen({ id: entry.id, isHumanEdited: true })}
            readOnly={props.context.readOnly}
            itemHtml={props.field.properties.itemHtml}
            fieldId={field.result?.meta.fieldId}
            entryFieldIds={entryFieldIds}
            fields={formVersion.fields}
            hasError={entryHasError}
            order={entry.meta.order ?? index + 1}
          />
        );
      }),
    [
      entries,
      field.controller.fieldState.error,
      field.result?.meta.fieldId,
      formVersion.fields,
      props.context.readOnly,
      props.context.submission.id,
      props.field.properties.itemHtml,
      setDrawerOpen,
    ],
  );

  const onClose = (): void => {
    if (props.context.readOnly) {
      setDrawerClose();
      return;
    }
    if (open && activeEntry && !activeEntry.isHumanEdited) {
      remove(activeEntry.id);
      setDrawerClose();
      return;
    }

    open && activeEntry && saveEntry(activeEntry.id);
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("SUBFORM_FIELD")}`}>
      <Label label={props.field.properties.label_text} required={(props.field.properties.min_items || 0) > 0} />
      {entryComponents}
      {!hideAddButton && (
        <IconAndTextButton
          label={isDisabled ? t("NO_SUBFORM_ENTRIES") : props.field.properties.add_button_text || t("ADD")}
          icon="PlusIcon"
          block
          disabled={isDisabled}
          onClick={() => {
            const entryId = uuidv4();
            const entry: SubformEntry<Meta> = {
              id: entryId,
              submissionId: props.context.submission.id,
              deleted: false,
              meta: {
                order: highestOrder(entries) + 1,
                createdOn: new Date().toISOString(),
              },
            };
            helpers
              .addEntry(entry)
              .then(() => setDrawerOpen({ id: entryId }))
              .catch((e) => logger.error("Can't add subform entry", e));
          }}
        />
      )}
      {field.props.errorMessage && (
        <Feedback
          status="error"
          message={
            field.controller.fieldState.error?.type === "entries"
              ? t("VALIDATION_SUBFORM_ENTRY_INVALID")
              : field.props.errorMessage
          }
        />
      )}
      <Drawer
        open={open}
        scrollLock={!isOnTop}
        header={{
          kind: "extended",
          title: props.field.properties.label_text,
          leftButton: {
            kind: "textAndIcon",
            label: activeEntry?.isHumanEdited ? t("SAVE_AND_CLOSE") : t("BACK"),
            icon: "ChevronLeftIcon",
            iconAlign: "left",
            onClick: onClose,
          },
          rightButton: !props.context.readOnly
            ? {
                kind: "textAndIcon",
                label: t("OPTIONS"),
                icon: "ChevronDownIcon",
                dropDownMenu: {
                  items: [
                    {
                      label: t("SAVE"),
                      onClick: () => open && activeEntry && saveEntry(activeEntry.id, true),
                    },
                    {
                      label: t("DELETE"),
                      type: "destructive",
                      onClick: () =>
                        open && activeEntry && setPendingRemovalEntry({ type: "DELETE_BUTTON", id: activeEntry.id }),
                    },
                  ],
                },
              }
            : undefined,
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: props.context.readOnly ? t("CLOSE") : t("SAVE"),
            onClick: () => open && activeEntry && saveEntry(activeEntry.id, true),
          },
        }}
        initialFocus={initialFocus}
        onClose={onClose}
        className="flex flex-col gap-y-6 py-6"
      >
        {open && activeEntry && (
          <>
            <Form
              ref={formRef}
              formVersion={formVersion}
              fieldProperties={props.context.fieldProperties}
              submission={props.context.submission}
              rememberedFields={props.context.rememberedFields}
              entryId={activeEntry.id}
              parentId={field.result?.meta.fieldId}
              readOnly={props.context.readOnly}
              fieldsCollection={props.context.fieldsCollection}
              setHumanEdited={(isHumanEdited) => {
                setActiveEntry({ ...activeEntry, isHumanEdited });
              }}
              validationMode={activeEntry.isHumanEdited ? "trigger_immediately" : "default"}
            />
            {pendingRemovalEntry && deleteModal}
          </>
        )}
      </Drawer>
      {!open && deleteModal}
    </article>
  );
};

WidgetSubform.defaultValue = (_field, defaultMeta): WidgetResult<void> => ({
  type: "array",
  meta: {
    widget: "subform",
    ...defaultMeta,
  },
  entries: [],
});

WidgetSubform.validate = (_val, properties, t, _meta, entries): string | undefined => {
  const minItems = properties.min_items;
  const maxItems = properties.max_items;

  const subformEntries = entries?.filter((entry) => !entry.deleted);

  if (subformEntries && minItems && minItems > subformEntries.length) {
    return t("VALIDATION_SUBFORM_MIN", { min: minItems });
  }
  if (subformEntries && maxItems && maxItems < subformEntries.length) {
    return t("VALIDATION_SUBFORM_MAX", { max: maxItems });
  }

  return undefined;
};

export default WidgetSubform;

type SubformEntryProps = {
  entry: SubformEntry<any>;
  submissionId: string;
  onOpen: () => void;
  onDelete: () => void;
  readOnly?: boolean;
  itemHtml?: string;
  fieldId?: string;
  fields: FormField<any>[];
  hasError: boolean;
  entryFieldIds: string[];
  order: number;
};

const SubformEntryComponent: FC<SubformEntryProps> = ({
  entry,
  submissionId,
  onOpen,
  onDelete,
  readOnly,
  itemHtml = "",
  fieldId,
  fields,
  hasError,
  entryFieldIds,
  order,
}) => {
  const { t } = useTranslation();
  const openButton: MenuItem = { label: t("OPEN"), onClick: onOpen };
  const deleteButton: MenuItem = { label: t("DELETE"), onClick: onDelete, type: "destructive" };
  const watchedFields = useWatch<SubmissionFormData>({ name: entryFieldIds });

  const formData = watchedFields.reduce(
    (acc, curr) => ({ ...acc, [curr?.meta?.fieldId]: curr }),
    {} as SubmissionFormData,
  );
  const data = widgetResultByDataName(fields, formData, submissionId, entry.id, fieldId);

  return (
    <WidgetContentButton
      appearance={hasError ? "danger" : "default"}
      className="my-1"
      items={!readOnly ? [openButton, deleteButton] : [openButton]}
    >
      <span className="flex" data-testid="entry-description">
        {`${order}.`}&nbsp;
        <TemplateContent template={itemHtml} inputData={data} submissionId={submissionId} allowNewLines />
      </span>
    </WidgetContentButton>
  );
};
