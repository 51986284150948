import { useMemo } from "react";
import { useRxData } from "rxdb-hooks";
import useAuth from "./useAuth";
import { Field } from "../types/Field";
import { getTermData, getTermFields } from "../utils/termUtil";

const useSearchTerms = (submissionId: string, filterFields?: string[], entryId?: string): any[] | undefined => {
  const { authorization } = useAuth();
  const { result: fields, isFetching } = useRxData<Field>("fields", (fieldsCollection) =>
    fieldsCollection.find().where("submissionId").eq(submissionId),
  );

  return useMemo(() => {
    if (isFetching) {
      return undefined;
    }
    if (!filterFields) {
      return [];
    }
    const termFields = getTermFields(fields, filterFields, entryId).filter((f) => f.field.data);

    const terms = termFields
      .flatMap((f) => getTermData(f))
      .filter((x) => !!x)
      .map((termData) => (typeof termData === "number" ? termData.toString() : termData));
    if (filterFields.includes("username") && authorization.username && authorization.type === "oauth") {
      terms.push(authorization.username);
    }
    return terms;
  }, [filterFields, fields, entryId, authorization?.username, authorization?.type, isFetching]);
};

export default useSearchTerms;
