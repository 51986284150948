import { FC } from "react";
import { RxCollection } from "rxdb";
import { AbstractForm, FieldProperties } from "../types/FormVersion";
import { SubmissionDocument } from "../utils/databaseUtil";
import useSync from "../hooks/useSync";
import { Field } from "../types/Field";
import useFormLogic from "../hooks/useFormLogic";

type FormStateProps = {
  submission: SubmissionDocument;
  formVersion: AbstractForm;
  fieldProperties: FieldProperties;
  readOnly: boolean;
  initialized?: boolean;
  fieldsCollection?: RxCollection<Field>;
  setHumanEdited?: (value: boolean) => void;
};

const FormState: FC<FormStateProps> = ({
  submission,
  formVersion,
  fieldProperties,
  readOnly,
  fieldsCollection,
  initialized,
  setHumanEdited,
}) => {
  useSync(submission, readOnly, setHumanEdited);
  useFormLogic(formVersion, fieldProperties, submission, readOnly, fieldsCollection, initialized);
  // Isolate to avoid re-rendering entire Form component for external effects
  return null;
};

export default FormState;
