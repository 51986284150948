import { useContext } from "react";
import { DrawerContext } from "../context/DrawerContext";

const useDrawer = (id: string): [boolean, (state: boolean) => void, boolean] => {
  const { open, close, isOpen, isOnTop } = useContext(DrawerContext);

  return [isOpen(id), (state: boolean): void => (state ? open(id) : close(id)), isOnTop(id)];
};

export default useDrawer;
