import { isNil, round } from "lodash-es";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { CalculationOperator, CurrencyResult, Widget } from "../../types/Widget";
import { Currency } from "../../types/Currency";
import useWidget from "../../hooks/useWidget";
import { FieldId, WidgetResult } from "../../types/Field";
import { formatCurrency } from "../../utils/formatter";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { toIsoCurrency } from "../../utils/currencyUtil";

export type WidgetCalculationProperties = {
  label_text: string;
  initial_value?: number;
  no_value_on_missing_term: boolean;
  operator: CalculationOperator;
  precision?: number;
  terms?: FieldId[];
  skip_hidden_terms?: boolean;
} & ({ isPrice: true; currency: Currency } | { isPrice: false });

const WidgetCalculation: Widget<WidgetCalculationProperties, WidgetResult<CurrencyResult>> = (props) => {
  const { t } = useTranslation();
  const precision = !isNil(props.field.properties.precision) ? props.field.properties.precision : 2;
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetCalculation.validate,
    {
      onChange: "none",
      onBlur: "none",
      valueFormat: (value) => {
        if (value?.rawValue?.value === undefined || Number.isNaN(value.rawValue.value)) {
          return undefined;
        }
        return props.field.properties.isPrice
          ? formatCurrency(value?.rawValue.value, navigator.language, {
              currency: toIsoCurrency(props.field.properties.currency),
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: precision,
            })
          : round(value.rawValue?.value, precision).toString();
      },
    },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("CALCULATION_FIELD")}`}>
      <TextInput
        type="text"
        externalInput
        disabled
        name={props.field.uid}
        value={field.props.value ?? ""}
        label={props.field.properties.label_text}
        placeholder={t("CALCULATE_MISSING_VALUES")}
      />
    </article>
  );
};

WidgetCalculation.defaultValue = (_field, defaultMeta: any): WidgetResult<CurrencyResult> => ({
  type: "currency",
  meta: {
    widget: "calculation",
    ...defaultMeta,
  },
});

WidgetCalculation.validate = (): undefined => undefined;

export default WidgetCalculation;
