import { AxiosInstance } from "axios";
import { RxCollection, RxDatabase, RxDocument } from "rxdb";
import { JSONPath } from "jsonpath-plus";
import pTimeout from "p-timeout";
import { isNil } from "lodash-es";
import { Submission, SubmissionForm } from "../types/Submission";
import uuidv4 from "./uuid";
import {
  getCalculatedFieldId,
  getDefaultValue,
  getEntriesWithError,
  getError,
  getFormVersionForField,
  getInitialValue,
} from "./formUtil";
import { Field, rxToForm, WidgetResult } from "../types/Field";
import { FileResult } from "../types/Widget";
import { Form } from "../types/Folder";
import { calculateActions } from "./ruleEvaluationUtil";
import { Authorization } from "../hooks/useAuth";
import { AbstractForm, FormField, FormVersion } from "../types/FormVersion";
import { DeviceInfo, getConnection, getCurrentAppInfo } from "./deviceUtil";
import { getLocation } from "./locationUtil";
import logger from "./logger";
import { FormFieldErrors } from "../components/Form";
import { INITIAL_DATE } from "../hooks/useWidget";
import { DBCollections } from "./databaseUtil";
import { FieldResult } from "../hooks/useFormRules";

const EXCLUDED_WIDGETS = ["signature"];

const LOCATION_RETRIEVAL_TIMEOUT = 15_000;

export const createNewSubmission = async (
  form: Form,
  customerId: number,
  submissions: RxCollection<Submission>,
): Promise<RxDocument<Submission, {}>> => {
  const id = uuidv4();
  const submission = buildNewSubmission(id, customerId, form.id, form.publishedVersion.formVersion, { ...form.meta });
  return submissions?.upsert(submission);
};

export const buildNewSubmission = (
  id: string,
  customerId: number,
  formId: string,
  formVersionId: string,
  form: SubmissionForm,
): Submission => ({
  id,
  customerId,
  formId,
  formVersionId,
  status: "draft",
  form: {
    name: form.name,
    description: form.description,
    icon: form.icon,
    iconColor: form.iconColor,
  },
  meta: {},
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  sendType: "remote_trigger",
  type: "draft",
});

export const extractAbstractForms = (formVersion: FormVersion): AbstractForm[] =>
  (
    JSONPath({
      path: "$..fields^",
      json: formVersion,
    }) as AbstractForm[]
  )
    // The JSONPath above also selects the `settings.searchSettings` object since it also contains a `fields` property,
    // this is not what we want, so we filter out everything that doesn't have the main properties of an abstract form.
    // In the future it might be a good idea to refactor the initial selection with JSONPath completely.
    .filter((x) => x.fields && x.rules && x.settings);

export const copySubmission = async (
  submission: Submission,
  fields: Field[],
  formVersion: FormVersion,
  client: AxiosInstance,
  authorization: Authorization,
): Promise<{ submission: Submission; fields: Field[] }> => {
  const newSubmission: Submission = {
    id: uuidv4(),
    customerId: submission.customerId,
    formId: submission.formId,
    formVersionId: formVersion.id,
    description: submission.description,
    status: "draft",
    form: submission.form,
    meta: {},
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    sendType: "remote_trigger",
    type: "draft",
  };
  const abstractForms = extractAbstractForms(formVersion);

  // Calculate new Entry IDs, which have to be unique across the database
  const newEntryIds = fields.reduce(
    (acc, field) => {
      field.entries.forEach((entry) => (acc[entry.id] = uuidv4())); // eslint-disable-line no-return-assign
      return acc;
    },
    {} as Record<string, string>,
  );

  const copiedFileFields = await Promise.all(
    fields
      .filter((field) => !EXCLUDED_WIDGETS.includes(field.widget)) // Filter sensitive fields
      .filter((field) => field.type === "file")
      .map(async (field): Promise<Field> => {
        let fileData: FileResult | undefined;
        if (field.type === "file" && (field.data as FileResult)?.remoteId) {
          const file = field.data as FileResult;
          const { data: remoteId } = await client.post<string>(
            `/api/v1.0/customers/${submission.customerId}/registrationFile/${file.remoteId}/copy`,
            null,
            { params: { hasuraSubmissionUuid: newSubmission.id } },
          );
          fileData = { ...file, id: uuidv4(), remoteId };
        }
        return {
          ...field,
          uploadStatus: "uploaded",
          data: fileData ?? field.data,
        };
      }),
  );

  const copiedFields = fields
    .filter((field) => !EXCLUDED_WIDGETS.includes(field.widget)) // Filter sensitive fields
    .map((field) =>
      cloneField(field, fields, newEntryIds, newSubmission, copiedFileFields, abstractForms, authorization),
    );
  const copiedFieldsWithRules = getSortedFields(
    fieldsWithRules(copiedFields, newSubmission, formVersion, abstractForms, authorization),
  ); // Ensure Set Value rules aren't triggered on insert
  return { submission: newSubmission, fields: copiedFieldsWithRules };
};

/**
 * Submission API doesn't know/care about the values of hidden fields.
 * This function adds the default value into the Field when it was hidden, because we need to use it for the copied submission.
 *
 */
const getFieldDataWithHiddenRules = (
  field: Field,
  formVersions: AbstractForm[],
  submission: Submission,
  ruleAction?: FieldResult,
): unknown =>
  ruleAction?.hidden && isNil(field.data)
    ? getDefaultValueForField(field, formVersions, submission).rawValue
    : field.data;

const fieldsWithRules = (
  fields: Field[],
  submission: Submission,
  formVersion: FormVersion,
  formVersions: AbstractForm[],
  authorization: Authorization,
): Field[] => {
  const ruleActions = calculateActions(submission, fields, formVersion, formVersion.fieldProperties, authorization);
  return fields.map((field) => {
    const ruleAction = ruleActions.find((x) => x.id === field.id);
    const data = getFieldDataWithHiddenRules(field, formVersions, submission, ruleAction);
    return !ruleAction
      ? field
      : { ...field, data, hidden: !!ruleAction.hidden, evaluatedRules: ruleAction.ruleResults };
  });
};

const getParentId = (
  field: Field,
  submission: Submission,
  newEntryIds: Record<string, string>,
  fields: Field[],
): string | undefined => {
  const parent = fields.find((parentField) => field.parentId === parentField.id);
  if (!parent) {
    return undefined;
  }

  const entryId = parent.entryId ? newEntryIds[parent.entryId] : undefined;
  return parent
    ? getCalculatedFieldId(
        parent.formFieldId,
        submission.id,
        entryId,
        getParentId(parent, submission, newEntryIds, fields),
      )
    : undefined;
};

const cloneField = (
  field: Field,
  fields: Field[],
  newEntryIds: Record<string, string>,
  newSubmission: Submission,
  copiedFileFields: Field[],
  formVersions: AbstractForm[],
  authorization: Authorization,
): Field => {
  const entryId = field.entryId ? newEntryIds[field.entryId] : undefined;
  const parentId = getParentId(field, newSubmission, newEntryIds, fields);
  const fieldId = getCalculatedFieldId(field.formFieldId, newSubmission.id, entryId, parentId);
  const copiedData = copiedFileFields.find((copiedField) => copiedField.id === field.id);

  return {
    ...field,
    id: fieldId,
    data: copiedData?.data ?? field.data,
    submissionId: newSubmission.id,
    parentId,
    entryId,
    entries: field.entries.map((entry) => ({
      ...entry,
      id: newEntryIds[entry.id],
      submissionId: newSubmission.id,
    })),
    order: getOrderInFormVersion(formVersions, field.formFieldId),
    updatedBy: authorization.userId,
    evaluatedRules: [], // This will be calculated again later, excluded fields might have an effect on the rules
    status: "draft",
    _deleted: false,
  };
};

export const getNestedEntries = (fields: Field[], entryId: string, result: string[] = []): string[] => {
  const entryIds = fields
    .filter((field) => field.entryId === entryId)
    .flatMap((field) => field.entries.map((entry) => entry.id));

  if (entryIds.length === 0) {
    return [...result, entryId];
  }

  const nestedEntries = entryIds.flatMap((nestedEntryId) => getNestedEntries(fields, nestedEntryId));
  return [...result, entryId, ...nestedEntries];
};

export const getParentEntries = (
  fields: Field[],
  entryId?: string,
  parentId?: string,
  result: string[] = [],
): string[] => {
  if (!entryId || !parentId) {
    return result;
  }
  const parent = fields.find((parentField) => parentId === parentField.id && !parentField.hidden);
  if (!parent) {
    return result; // No parent, orphaned?
  }
  const parentEntry = parent?.entries.find((x) => x.id === entryId);
  if (!parentEntry || parentEntry.deleted) {
    return result; // Entry is linked to this parent, but no longer available.
  }
  return getParentEntries(fields, parent.entryId, parent.parentId, [...result, entryId]);
};

export const getSortedFields = (fields: Field[]): Field[] => {
  const sortedSubforms = fields
    .filter((field) => field.entries?.length > 0)
    .map((field) => ({ field, depth: getSubformDepth(0, field, fields) }))
    .sort((a, b) => a.depth - b.depth)
    .map((pair) => pair.field);
  const otherFields = fields.filter((f) => f.entries?.length === 0);
  return sortedSubforms.concat(otherFields);
};

export const fieldsToSubmissionFormData = (fields: Field[]): Record<string, WidgetResult<unknown>> =>
  fields.reduce((acc, item) => ({ ...acc, [item.id]: rxToForm(item) }), {} as Record<string, WidgetResult<unknown>>);

export const updateSubmissionMeta = async (
  submission: RxDocument<Submission>,
  device: DeviceInfo,
): Promise<RxDocument<Submission, {}, unknown>> => {
  let location;
  try {
    location = await pTimeout(getLocation(), { milliseconds: LOCATION_RETRIEVAL_TIMEOUT });
  } catch (e) {
    logger.log("Couldn't retrieve location", e);
  }
  return submission.incrementalPatch({
    meta: { device, app: await getCurrentAppInfo(), connection: await getConnection(), location },
  });
};

export const getFieldsForEntry = (
  fields: RxDocument<Field, {}>[],
  entryFields: RxDocument<Field, {}>[],
  result: RxDocument<Field, {}>[] = entryFields.filter((field) =>
    fields.map((entryField) => entryField.id).includes(field.id),
  ),
): RxDocument<Field, {}>[] => {
  const entryFieldIds = entryFields.map((field) => field.id);
  const childFields = fields.filter((field) => field.parentId && entryFieldIds.includes(field.parentId));
  // field.entries.length > 0, is chosen to check if a field contains nested fields
  if (childFields.filter((field) => field.entries.length > 0).length > 0) {
    // Recursively return nested fields if there are any
    return getFieldsForEntry(fields, childFields, [...result, ...childFields]);
  }
  return [...result, ...childFields];
};

export const getInvalidFields = async (
  fieldCollection: RxCollection<Field>,
  submissionId: string,
  entryId?: string,
): Promise<FormFieldErrors[]> => {
  const allFields = await fieldCollection.find().where("submissionId").eq(submissionId).exec();
  const fields = getFieldsForEntry(
    allFields,
    allFields.filter((field) => field.entryId === entryId),
  );
  const invalidEntries = getEntriesWithError(fields);
  return fields
    .filter((field) => field.entryId === entryId)
    .map((field) => ({
      id: field.id,
      formFieldId: field.formFieldId,
      entryId: field.entryId,
      error: getError(field, invalidEntries),
    }))
    .filter((field) => field.error) as FormFieldErrors[];
};

export const getFailedUploads = async (
  submissionId: string,
  fieldCollection: RxCollection<Field>,
): Promise<RxDocument<Field, {}>[]> =>
  fieldCollection.find().where("submissionId").eq(submissionId).where("uploadStatus").eq("failed").exec();

const getOrderInFormVersion = (formVersions: AbstractForm[], formFieldId: string): number =>
  getFormVersionForField(formVersions, formFieldId)?.fields?.findIndex((x) => x.uid === formFieldId) ?? -1;

const getSubformDepth = (depth: number, field: Field, fields: Field[]): number => {
  if (!field.parentId) {
    return depth;
  }
  const parent = fields.find((f) => f.id === field.parentId);
  return parent ? getSubformDepth(depth + 1, parent, fields) : depth;
};

export const getDefaultValues = async (
  submissionId: string,
  fieldCollection: RxCollection<Field>,
): Promise<Record<string, WidgetResult<unknown>>> => {
  // Load in fields from RxDB
  const existingFields = await fieldCollection?.find().where("submissionId").eq(submissionId).exec();
  return existingFields ? fieldsToSubmissionFormData(existingFields) : {};
};

export const getStaticDefaultValues = (
  formVersion: FormVersion,
  submission: Submission,
): Record<string, WidgetResult<unknown>> => {
  const deviceId = submission.meta.device?.id ?? uuidv4();
  const fields: Field[] = formVersion.fields.map((field, index) =>
    getStaticDefaultValue(field, index, submission, deviceId),
  );
  return fieldsToSubmissionFormData(fields);
};

export const getDefaultValueForField = (field: Field, formVersions: AbstractForm[], submission: Submission): any => {
  const formVersion = getFormVersionForField(formVersions, field.formFieldId);
  const formField = formVersion?.fields.find((x) => x.uid === field.formFieldId);
  if (!formField) {
    return undefined;
  }
  return getDefaultValue(field.id, formField, submission, field.entryId, field.parentId);
};

/**
 * Get default value to use in a static form context. I.e. templates or search form.
 * This doesn't start with validation result.
 */
const getStaticDefaultValue = (
  formField: FormField<any>,
  index: number,
  submission: Submission,
  deviceId: string,
  entryId?: string,
  parentId?: string,
): Field => {
  const fieldId = getCalculatedFieldId(formField.uid, submission.id, entryId, parentId);
  const initialValue = getInitialValue(fieldId, formField, submission, undefined, entryId, parentId);
  return {
    id: fieldId,
    submissionId: submission.id,
    updatedAt: INITIAL_DATE,
    formFieldId: initialValue.meta.formFieldId,
    dataName: initialValue.meta.dataName,
    widget: initialValue.meta.widget,
    type: initialValue.type,
    data: initialValue.rawValue,
    deviceId,
    hidden: false,
    compressed: false,
    entryId: initialValue.meta.entryId,
    parentId: initialValue.meta.parentId,
    entries: [],
    status: "draft",
    evaluatedRules: [],
    order: index,
    _deleted: false,
  };
};

export const getActiveSubmissionIds = async (
  submissionIds: string[],
  db: RxDatabase<DBCollections>,
): Promise<string[]> => {
  const result = await db.submissions.find().where("id").in(submissionIds).exec();
  return result.filter((submission) => !submission.deleted).map((submission) => submission.id);
};
