import { FC, useEffect, useState } from "react";
import DOMPurify from "isomorphic-dompurify";
import replaceAsync from "string-replace-async";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useFileHandler from "../hooks/useFileHandler";
import { templateRegex } from "../utils/interpolateUtil";
import { WidgetResult } from "../types/Field";
import { getTemplatedContentWithFiles } from "../utils/templateUtil";

interface TemplateContentProps {
  template: string;
  inputData: Record<string, WidgetResult<unknown>>;
  submissionId: string;
  className?: string;
  allowNewLines?: boolean;
}

const TemplateContent: FC<TemplateContentProps> = ({
  template,
  inputData,
  submissionId,
  className,
  allowNewLines = false,
}) => {
  const { t } = useTranslation();
  const { getFileUrl } = useFileHandler();
  const [templateContent, setTemplateContent] = useState("");

  useEffect(() => {
    replaceAsync(template, templateRegex, async (_match: string, path: string) => {
      const widgetResult = inputData[path.split(".")[0]];
      return getTemplatedContentWithFiles(path, widgetResult, submissionId, t, getFileUrl, {
        humanReadable: true,
      });
    }).then((result) => {
      // NOTE: Extremely important to sanitize template before rendering
      // https://pragmaticwebsecurity.com/articles/spasecurity/react-xss-part2.html
      setTemplateContent(
        DOMPurify.sanitize(result.trim(), {
          // Allow HTTP(S)/Capacitor protocol in sanitized html
          ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|capacitor):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
        }),
      );
    });
  }, [template, inputData]); // eslint-disable-line react-hooks/exhaustive-deps

  // We mitigated the danger by sanitizing it first
  return (
    <span
      className={classNames("line-clamp-4 break-words", className, { "whitespace-pre-wrap": allowNewLines })}
      dangerouslySetInnerHTML={{ __html: templateContent }} // eslint-disable-line react/no-danger
    />
  );
};

export default TemplateContent;
