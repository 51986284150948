import { RxCollection } from "rxdb";
import { UseFormClearErrors, useFormContext, UseFormSetError, UseFormSetValue } from "react-hook-form";
import { deepEqual } from "fast-equals";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import useAuth from "./useAuth";
import { AbstractForm, FieldProperties, FormField, FormRule } from "../types/FormVersion";
import { Field, rxToForm } from "../types/Field";
import { calculateActions } from "../utils/ruleEvaluationUtil";
import { FieldDocument } from "../utils/databaseUtil";
import { SubmissionFormData } from "../components/Form";
import { useAsyncEffect } from "./useAsyncEffect";
import { Submission } from "../types/Submission";
import { getFieldMap, validateField } from "../utils/formUtil";
import { getValueForType } from "../utils/ruleUtil";

export interface RuleResult {
  type: "SET_VISIBILITY" | "SET_VALUE";
  visible?: boolean;
  value?: string | boolean;
}

export interface FieldRuleResult {
  id: string;
  results: RuleResult[];
}

export type FormRuleContext = {
  field: Field;
  rules: FormRule[];
  scopedFields: FieldMap;
};

export type EntryFieldMap = Map<string, FieldMap>;
export type FieldMap = Map<string, Field>;

export interface FieldResult {
  id: string;
  hidden?: boolean;
  value?: unknown;
  ruleResults: RuleResult[];
}

const useFormRules = (
  enabled: boolean,
  formVersion: AbstractForm,
  fieldProperties: FieldProperties,
  fields: Field[],
  deviceId: string,
  submission: Submission,
  readOnly: boolean = false,
  fieldsCollection?: RxCollection<Field>,
): void => {
  const { t } = useTranslation();
  const { authorization } = useAuth();
  const { setValue, setError, clearErrors } = useFormContext<SubmissionFormData>();

  useAsyncEffect(
    async () => {
      if (!enabled || !authorization || !fieldsCollection) {
        return;
      }
      const calculatedActions = calculateActions(submission, fields, formVersion, fieldProperties, authorization);
      if (calculatedActions.length === 0) {
        return;
      }
      const formFieldMap: Map<string, FormField<any>> = getFieldMap(
        submission,
        formVersion.fields,
        fields,
        fieldProperties,
      );
      await executeActions(
        calculatedActions,
        fieldsCollection,
        formFieldMap,
        readOnly,
        deviceId,
        setValue,
        setError,
        clearErrors,
        t,
      );
    },
    async () => undefined,
    [fields, formVersion, enabled, authorization, fieldsCollection, readOnly, setValue],
  );
};

const executeActions = async (
  calculatedActions: FieldResult[],
  fieldsCollection: RxCollection<Field>,
  formFieldMap: Map<string, FormField<any>>,
  readOnly: boolean,
  deviceId: string,
  setValue: UseFormSetValue<SubmissionFormData>,
  setError: UseFormSetError<SubmissionFormData>,
  clearErrors: UseFormClearErrors<SubmissionFormData>,
  t: TFunction,
): Promise<void> => {
  const fields = await fieldsCollection.findByIds(calculatedActions.map((x) => x.id)).exec();
  await Promise.all(
    calculatedActions
      .filter((fieldResult) => fieldResult?.id)
      .filter((fieldResult) => fields.has(fieldResult.id))
      .map((fieldResult) =>
        updateField(
          readOnly,
          fieldResult,
          fields.get(fieldResult.id)!,
          formFieldMap.get(fieldResult.id)!,
          deviceId,
          setValue,
          setError,
          clearErrors,
          t,
        ),
      ),
  );
};

const updateField = async (
  readOnly: boolean,
  fieldResult: FieldResult,
  fieldRx: FieldDocument,
  formField: FormField<any>,
  deviceId: string,
  setValue: UseFormSetValue<SubmissionFormData>,
  setError: UseFormSetError<SubmissionFormData>,
  clearErrors: UseFormClearErrors<SubmissionFormData>,
  t: TFunction,
): Promise<void> => {
  const { value, hidden, ruleResults } = fieldResult;
  // Get the correct value for the widget's type. We should check if we can refactor this later higher up
  const valueForType = getValueForType(fieldRx.type, formField, value as Object);
  const fieldUpdate: Field = {
    ...fieldRx.toMutableJSON(),
    ...(!readOnly && value !== undefined ? { data: valueForType } : {}),
    hidden: hidden ?? false,
    evaluatedRules: ruleResults,
  };
  if (!deepEqual(fieldRx, fieldUpdate)) {
    const error = getFieldError(formField, !!hidden, fieldUpdate, fieldRx, t);
    if (!isNil(fieldRx.updatedBy)) {
      // Don't visualize directly after setting rule if it hasn't been touched by users yet.
      // The field will either become invalid on submit/save or when touching it.
      setFormError(setError, clearErrors, fieldRx, error, hidden);
    }
    // Still set the error to prevent submitting with lacking rule actions
    setValue(fieldRx.id, rxToForm({ ...fieldUpdate, error }));
    await fieldRx.incrementalPatch({
      ...fieldUpdate,
      error,
      updatedAt: new Date().toISOString(),
      deviceId,
    });
  }
};

const setFormError = (
  setError: UseFormSetError<SubmissionFormData>,
  clearErrors: UseFormClearErrors<SubmissionFormData>,
  fieldRx: FieldDocument,
  error?: string,
  hidden?: boolean,
): void => {
  if (error && !hidden) {
    setError(fieldRx.id, { message: error });
  } else {
    clearErrors(fieldRx.id);
  }
};

const getFieldError = (
  formField: FormField<any>,
  hidden: boolean,
  fieldUpdate: Field,
  fieldRx: FieldDocument,
  t: TFunction,
): string | undefined => validateField(formField, hidden, fieldUpdate.data, fieldRx.entries, t);

export default useFormRules;
