import { useRxCollection } from "rxdb-hooks";
import { RxDocument } from "rxdb";
import { Field, RememberedField } from "../types/Field";
import { FormField } from "../types/FormVersion";
import logger from "../utils/logger";
import { getRememberedFieldId } from "../utils/stringUtil";

type UseRememberFieldsResult = {
  rememberFields: (submissionId: string, formId: string, formFields: FormField<any>[], entryId?: string) => void;
  clearAllRememberedFields: () => Promise<RxDocument<RememberedField, {}>[]> | undefined;
};

const useRememberFields = (): UseRememberFieldsResult => {
  const collection = useRxCollection<RememberedField>("remembered-fields");
  const fields = useRxCollection<Field>("fields");

  const saveRememberedFields = async (
    submissionId: string,
    formId: string,
    formFields: FormField<any>[],
    entryId?: string,
  ): Promise<void> => {
    const filledFields = await fields
      ?.find()
      .where("submissionId")
      .eq(submissionId)
      .where("entryId")
      .eq(entryId)
      .exec();

    if (!filledFields) {
      return;
    }
    await Promise.all(
      filledFields
        .filter((field) => formFields.find((formField) => formField.uid === field.formFieldId))
        .map((rememberedField) =>
          collection?.incrementalUpsert({
            id: getRememberedFieldId(rememberedField.formFieldId, formId),
            formId,
            data: rememberedField.data,
            updatedAt: rememberedField.updatedAt,
            dataName: rememberedField.dataName,
            widget: rememberedField.widget,
            type: rememberedField.type,
          }),
        ),
    );
  };

  const rememberFields = (
    submissionId: string,
    formId: string,
    formFields: FormField<any>[],
    entryId?: string,
  ): void => {
    const rememberInputFormFields = formFields.filter((formField) => formField.properties.remember_input);
    saveRememberedFields(submissionId, formId, rememberInputFormFields, entryId).catch((e) =>
      logger.error("Could not save remembered fields", e),
    );
  };

  const clearAllRememberedFields = (): Promise<RxDocument<RememberedField, {}>[]> | undefined =>
    collection?.find().remove();

  return {
    rememberFields,
    clearAllRememberedFields,
  };
};

export default useRememberFields;
