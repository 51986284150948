import { parseInt } from "lodash-es";
import { CurrencyResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetPrice, { WidgetPriceProperties } from "./WidgetPrice";
import { validateCurrency } from "../../utils/validationUtil";

export interface WidgetDecimalProperties {
  decimal_places: string;
}

const WidgetDecimal: Widget<WidgetPriceProperties & WidgetDecimalProperties, WidgetResult<CurrencyResult>> = ({
  ...props
}) => {
  const { currency, decimal_places: decimalPlaces } = props.field.properties;
  const priceField = {
    uid: props.field.uid,
    widget: props.field.widget,
    properties: {
      ...props.field.properties,
      currency: currency !== "empty" ? currency : undefined,
      precision: parseInt(decimalPlaces),
    },
  };
  return <WidgetPrice {...props} field={priceField} />;
};

WidgetDecimal.defaultValue = (_field, defaultMeta: any): WidgetResult<CurrencyResult> => ({
  type: "currency",
  meta: {
    widget: "decimal",
    ...defaultMeta,
  },
});

WidgetDecimal.validate = (val, properties, t): string | undefined => validateCurrency(properties, val, t);

export default WidgetDecimal;
