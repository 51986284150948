import { FocusEvent } from "react";
import { isFinite, isNil } from "lodash-es";
import { NumberFormatValues } from "react-number-format";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { CurrencyResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import { toFormattedCurrency } from "../../utils/stringUtil";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import { validateCurrency } from "../../utils/validationUtil";
import { getFormValue } from "../../utils/numberUtil";
import { isTouchCapable } from "../../utils/deviceUtil";
import { toIsoCurrency, toIsoCurrencySymbol } from "../../utils/currencyUtil";

export interface WidgetPriceProperties {
  required: boolean;
  currency?: string;
  label_text: string;
  min?: number;
  max?: number;
  precision?: number;
  decimal_mark: "comma" | "period";
}

const STEP_SIZE = 0.01;
const PLACEHOLDER = 0.0;

const WidgetPrice: Widget<WidgetPriceProperties, WidgetResult<CurrencyResult>> = (props) => {
  const { t } = useTranslation();
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetPrice.validate,
    {
      onChange: "none",
      onBlur: "persist-on-unmount",
      valueFormat: (value) => (!isNil(value?.rawValue?.value) ? value?.rawValue?.value : undefined),
    },
    props.fieldRx,
    props.entry,
  );

  const { precision, currency: unMappedCurrency, decimal_mark: decimalFormat } = props.field.properties;
  const currency = toIsoCurrency(unMappedCurrency);

  const onChange = (values: NumberFormatValues): void => {
    const rawValue = !isNil(values.floatValue)
      ? {
          currency,
          value: values.floatValue,
          decimalFormat,
          precision,
        }
      : undefined;
    helpers.setValue(rawValue);
  };

  const onBlur = async (e: FocusEvent<any, any>): Promise<void> => {
    const value = getFormValue(e);
    const rawValue = !isNil(value)
      ? {
          currency,
          value,
          decimalFormat,
          precision,
        }
      : undefined;
    await helpers.persist(rawValue);
    field.props.onBlur(e);
    field.controller.field.onBlur();
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }

  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("PRICE_FIELD")}`}>
      <NumberInput
        {...field.props}
        leftChild={toIsoCurrencySymbol(currency)}
        label={props.field.properties.label_text}
        inputMode="decimal"
        disabled={isDisabled}
        required={props.field.properties.required}
        step={STEP_SIZE}
        onChange={onChange}
        onBlur={onBlur}
        onPlusMinus={
          isTouchCapable()
            ? async (): Promise<void> => {
                // Only switch real numbers and skip 0 because -0 is a quirky number
                if (isFinite(field.props.value) && field.props.value !== 0) {
                  const rawValue = { currency, value: field.props.value * -1, decimalFormat, precision };
                  helpers.setValue(rawValue);
                  await helpers.persist(rawValue);
                }
              }
            : undefined
        }
        placeholder={toFormattedCurrency(PLACEHOLDER)}
        min={props.field.properties.min}
        max={props.field.properties.max}
        maxPrecision={props.field.properties.precision ?? 2}
        showThousandSeparator
      />
    </article>
  );
};

WidgetPrice.defaultValue = (_field, defaultMeta): WidgetResult<CurrencyResult> => ({
  type: "currency",
  meta: {
    widget: "price",
    ...defaultMeta,
  },
});

WidgetPrice.validate = (val, properties, t): string | undefined => validateCurrency(properties, val, t);

export default WidgetPrice;
