import { useCallback, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LicensePlate from "license-plate";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import DrawerListItem from "../DrawerListItem";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { WidgetResult } from "../../types/Field";
import { LICENSE_PLATE_BASE_URL } from "../../constants";
import { toHumanReadableDate } from "../../utils/dateUtil";
import useDrawer from "../../hooks/useDrawer";
import WidgetError from "./WidgetError";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { noopAsync } from "../../utils/noop";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";

export interface WidgetRDWProperties {
  required: boolean;
  label_text: string;
}

export interface RDWResult {
  licensePlate: string;
  brand?: string;
  type?: string;
  expirationDate?: string;
  firstInternationalAdmission?: string;
  euroClassification?: string;
  verifiedAt?: string;
}

const WidgetRDW: Widget<WidgetRDWProperties, WidgetResult<RDWResult>> = (props) => {
  const { t } = useTranslation();
  const [warning, setWarning] = useState({ enabled: false, title: "", message: "" });
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetRDW.validate,
    {
      onChange: "none",
      onBlur: "persist-on-unmount",
      valueFormat: (value) => value?.rawValue?.licensePlate?.toUpperCase(),
    },
    props.fieldRx,
    props.entry,
  );
  const [drawerOpen, setDrawerOpen] = useDrawer(props.field.uid);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getIconState = (): "loading" | "warning" | "default" => {
    if (isLoading) {
      return "loading";
    }
    if (warning.enabled) {
      return "warning";
    }
    return "default";
  };

  const verifyRDW = useCallback(
    async (license: LicensePlate) => {
      setIsLoading(true);
      try {
        const { data: licenseData } = await axios.get<RDWResult>(
          `${LICENSE_PLATE_BASE_URL}/NL/${license.pretty().replaceAll("-", "")}`,
        );

        if (!licenseData) {
          setWarning({
            enabled: true,
            title: t("ERROR"),
            message: t("VALIDATION_RDW_NOT_FOUND"),
          });
          setIsLoading(false);
          setShowSuccess(false);
          return;
        }

        setWarning({ ...warning, enabled: false });
        await helpers.persist({
          ...licenseData,
          licensePlate: license.pretty(),
          verifiedAt: new Date().toISOString(),
        });
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 2000);
        setIsLoading(false);
      } catch (e) {
        const notFound = axios.isAxiosError(e) && e.response?.data.code === "not_found";
        const message = notFound ? t("VALIDATION_RDW_NOT_FOUND") : t("VERIFICATION_RDW_UNAVAILABLE");

        setWarning({ enabled: true, title: t("ERROR"), message });
        setShowSuccess(false);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [helpers.persist],
  );

  useAsyncEffect(
    async () => {
      setWarning({ ...warning, enabled: false });
      const licensePlate = new LicensePlate(field.result?.rawValue?.licensePlate ?? "");
      if (licensePlate.valid() && !field.result?.rawValue?.verifiedAt && !isDisabled) {
        await verifyRDW(licensePlate);
      }
    },
    noopAsync,
    [field.result?.rawValue?.licensePlate],
  );

  const reset = async (): Promise<void> => {
    await helpers.persist(undefined);
    setWarning({ ...warning, enabled: false });
    setIsLoading(false);
  };

  const showInfoBtn =
    !warning.enabled && !isLoading && new LicensePlate(field.result?.rawValue?.licensePlate ?? "").valid();

  const descriptionLine =
    field.result?.rawValue?.brand &&
    field.result.rawValue.type &&
    t("RDW_BRAND_AND_MODEL", {
      brand: field.result.rawValue.brand,
      model: field.result.rawValue.type,
    });

  const drawer = field.result?.rawValue && (
    <Drawer
      open={drawerOpen}
      header={{
        kind: "simple",
        title: t("DATA"),
        button: {
          kind: "icon",
          icon: "XIcon",
          onClick: () => setDrawerOpen(false),
        },
      }}
      onClose={() => setDrawerOpen(false)}
    >
      <DrawerListItem
        key="licensePlate"
        label={t("LICENSE_PLATE")}
        value={field.result?.rawValue.licensePlate ?? t("UNKNOWN")}
      />
      <DrawerListItem key="brand" label={t("BRAND")} value={field.result?.rawValue.brand ?? t("UNKNOWN")} />
      <DrawerListItem key="type" label={t("VEHICLE_TYPE")} value={field.result?.rawValue.type ?? t("UNKNOWN")} />
      <DrawerListItem
        key="expirationDate"
        label={t("APK_EXPIRATION_DATE")}
        value={
          field.result?.rawValue.expirationDate
            ? toHumanReadableDate(field.result?.rawValue.expirationDate)
            : t("UNKNOWN")
        }
      />
      <DrawerListItem
        key="firstInternationalAdmission"
        label={t("FIRST_INTERNATIONAL_ADMISSION")}
        value={
          field.result?.rawValue.firstInternationalAdmission
            ? toHumanReadableDate(field.result?.rawValue.firstInternationalAdmission)
            : t("UNKNOWN")
        }
      />
      <DrawerListItem
        key="euroClassification"
        label={t("EURO_CLASSIFICATION")}
        value={field.result?.rawValue.euroClassification ?? t("UNKNOWN")}
      />
    </Drawer>
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("LICENSE_PLATE_FIELD")}`}>
      <TextInput
        {...field.props}
        inputRef={field.inputRef}
        disabled={isDisabled}
        type="text"
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        placeholder={t("RDW_PLACEHOLDER_TEXT")}
        showClearBtn={!!field.result?.rawValue?.licensePlate && !isDisabled}
        clearLabel={t("CLEAR")}
        onClear={reset}
        description={descriptionLine}
        onChange={(e) => helpers.setValue({ licensePlate: e.target.value })}
        leftIcon={{
          name: showSuccess ? "CheckIcon" : "CarIcon",
          className: `${showSuccess && "text-green-500"} transition`,
        }}
        iconState={getIconState()}
        warningTitle={warning.enabled && !field.props.errorMessage ? warning.title : ""}
        warningMessage={warning.enabled && !field.props.errorMessage ? warning.message : ""}
        rightBtn={
          showInfoBtn
            ? {
                "aria-label": t("RDW_VIEW_INFO"),
                icon: "InformationCircleIcon",
                size: "md",
                onClick: () => setDrawerOpen(true),
              }
            : undefined
        }
      />
      {drawer}
    </article>
  );
};

WidgetRDW.defaultValue = (_field, defaultMeta): WidgetResult<RDWResult> => ({
  type: "object",
  meta: {
    widget: "rdw",
    ...defaultMeta,
  },
});

WidgetRDW.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  if (val && !new LicensePlate(val.licensePlate).valid()) {
    return t("VALIDATION_RDW_INVALID");
  }

  return undefined;
};

export default WidgetRDW;
