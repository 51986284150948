import { isRxDocument, RxCollection, RxDocument } from "rxdb";
import { isEqual } from "lodash-es";
import { useEffect } from "react";
import { AbstractForm, FormField } from "../types/FormVersion";
import { Field } from "../types/Field";
import { getFieldFromFormVersions } from "../utils/formUtil";
import { WidgetCalculationProperties } from "../components/widgets/WidgetCalculation";
import { getCalculationResult } from "../utils/calculationUtil";
import { CurrencyResult } from "../types/Widget";
import logger from "../utils/logger";

export type FormCalculationContext = {
  field: Field;
  formField: FormField<WidgetCalculationProperties>;
};

const useFormCalculation = (
  enabled: boolean,
  formVersions: AbstractForm[],
  fields: Field[],
  deviceId: string,
  readOnly: boolean = false,
  fieldsCollection?: RxCollection<Field>,
): void => {
  useEffect(() => {
    if (!enabled || !formVersions || !fieldsCollection || !fields || fields.length === 0) {
      return;
    }

    const calculationFields = fields?.filter((x) => x.widget === "calculation");
    const updatedCalculationFields = calculationFields
      .map(
        (calculation): FormCalculationContext => ({
          field: calculation,
          formField: getFieldFromFormVersions(
            formVersions,
            calculation.formFieldId,
          ) as FormField<WidgetCalculationProperties>,
        }),
      )
      .map(({ field, formField }) => getCalculationResult(formField, field, fields));

    Promise.allSettled(
      updatedCalculationFields.map(async (updatedField) => {
        const data = updatedField.field?.data as CurrencyResult;
        if (isEqual(data?.value, updatedField.data?.value)) {
          return;
        }
        if (isRxDocument(updatedField.field)) {
          const fieldRx = updatedField.field as RxDocument<Field>;
          await fieldRx?.incrementalPatch({
            data: updatedField.data,
            updatedAt: new Date().toISOString(),
            deviceId,
          });
        }
      }),
    ).catch((e) => logger.error("Could not resolve updated calculations", e));
  }, [fields, formVersions, enabled, fieldsCollection, readOnly, deviceId]);
};

export default useFormCalculation;
