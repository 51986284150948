import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Submission } from "../types/Submission";
import { toHumanReadableDate } from "../utils/dateUtil";
import { Text } from "../storybook/components/Text/Text";
import { Title } from "../storybook/components/Title/Title";
import { Icon } from "../storybook/components/Icon/Icon";

interface SubmissionContentHeaderProps {
  submission: Submission;
  isInitialized: boolean;
  syncFailed?: boolean;
}

const SubmissionContentHeader: FC<SubmissionContentHeaderProps> = ({
  submission: { form, task },
  isInitialized = true,
  syncFailed = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full" key="form-header">
      {isInitialized && (
        <>
          <Title as="h1" size="3xl">
            {form.name}
          </Title>
          {form.description && !syncFailed && (
            <Text className="mt-2" color="medium">
              {form.description}
            </Text>
          )}
          {task?.message && !syncFailed && (
            <div className="mt-4 flex flex-col gap-2 rounded-lg bg-gray-100 p-4">
              <Title as="h3" size="lg" className="line-clamp-2">
                {t("TASKS_MESSAGE")}
              </Title>
              <Text>{task.message}</Text>
              {task.dueDate && (
                <div className="flex items-center gap-x-2 text-gray-700">
                  <Icon name="CalendarIcon" type="outline" className="size-5" />
                  <Text size="sm">{toHumanReadableDate(task.dueDate)}</Text>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SubmissionContentHeader;
