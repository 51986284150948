import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useRxCollection } from "rxdb-hooks";
import useAuth from "./useAuth";
import { useMoreAppClient } from "../context/MoreAppContext";
import { DataSource, DataSourceMeta } from "../types/Datasource";
import { fetchDataSource, onDatasourceError, onDatasourceSuccess } from "../utils/datasourceUtil";
import { hours, minutes } from "../utils/timeUtil";

const useDatasource = (id: string, disableRetry = false): UseQueryResult<DataSource, Error> => {
  const { customerId, username } = useAuth();
  const client = useMoreAppClient();
  const datasourceCollection = useRxCollection<DataSourceMeta>("datasources");

  return useQuery<DataSource, Error>({
    queryKey: ["datasources", customerId, id],
    queryFn: () => fetchDataSource(username!, customerId!, id, client!, datasourceCollection!),
    enabled: !!customerId && !!id && !!datasourceCollection && !!username,
    onSuccess: (data) => onDatasourceSuccess(username!, customerId!, data, id, datasourceCollection!),
    onError: (err) => onDatasourceError(username!, customerId!, err, id),
    cacheTime: minutes(15),
    staleTime: hours(1),
    ...(disableRetry && { retry: false }),
    networkMode: "always",
  });
};

export default useDatasource;
