import { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { isNumber } from "../../utils/numberUtil";
import useLocalValue from "../../hooks/useLocalValue";
import { RangeInput } from "../../storybook/components/RangeInput/RangeInput";

export interface WidgetSliderProperties {
  required: boolean;
  label_text: string;
  min?: number;
  max?: number;
  step?: number;
  default_value?: number;
}

const WidgetSlider: Widget<WidgetSliderProperties, WidgetResult<number>> = (props) => {
  const { field, helpers, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetSlider.validate,
    { onChange: "set", onBlur: "none", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );
  const [value, setValue] = useLocalValue<number>(field.props.value, helpers, field, {
    debounce: 700,
  });
  const showClearButton = useMemo(() => {
    if (isDisabled) {
      return false;
    }
    return !!(value || value === 0);
  }, [isDisabled, value]);

  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const result = Number(e.target?.value);
    if (Number.isNaN(result)) {
      return;
    }

    setValue(result);
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("SLIDER_FIELD")}`}>
      <RangeInput
        {...field.props}
        value={isNumber(value) ? `${value}` : ""}
        inputRef={field.inputRef}
        showClearBtn={showClearButton}
        onClear={() => setValue(undefined)}
        clearLabel={t("CLEAR")}
        min={props.field.properties.min}
        max={props.field.properties.max}
        step={props.field.properties.step}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        disabled={isDisabled}
        onChange={handleOnChange}
      />
    </article>
  );
};

WidgetSlider.defaultValue = (field, defaultMeta): WidgetResult<number> => ({
  type: "number",
  rawValue: field.properties.default_value,
  meta: {
    widget: "slider",
    ...defaultMeta,
  },
});

WidgetSlider.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const min = properties.min ?? -Infinity;
  const max = properties.max ?? Infinity;
  if (required && !(val || val === 0)) {
    return t("VALIDATION_REQUIRED");
  }

  if (val || val === 0) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(val)) {
      return t("VALIDATION_NUMBER_NAN");
    }

    if (val < min) {
      return t("VALIDATION_NUMBER_MIN", { min });
    }
    if (val > max) {
      return t("VALIDATION_NUMBER_MAX", { max });
    }
  }

  return undefined;
};

export default WidgetSlider;
