import { useRxData } from "rxdb-hooks";
import { useMemo } from "react";
import { JSONPath } from "jsonpath-plus";
import { Field } from "../types/Field";
import { DataSourceEntry } from "../types/Datasource";

const useDatasourceImages = (submissionId: string): any => {
  const { result: fields } = useRxData<Field>(
    "fields",
    (fieldsCollection) =>
      fieldsCollection.find().where("submissionId").equals(submissionId).where("widget").equals("search"),
    { json: true },
  );

  return useMemo(() => {
    const fieldData = fields.map((f) => f.data) as DataSourceEntry[];
    return JSONPath({ path: "$..value^", json: fieldData })
      .filter((x: Record<string, unknown>) => x.type === "images")
      .flatMap((x: Record<string, unknown>) => x.value);
  }, [fields]);
};

export default useDatasourceImages;
