import { isFinite, isNil } from "lodash-es";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import { getFormValue } from "../../utils/numberUtil";
import { isTouchCapable } from "../../utils/deviceUtil";

export interface WidgetNumberProperties {
  required: boolean;
  label_text: string;
  numeric_min?: number;
  numeric_max?: number;
  text_default_value?: number;
  text_placeholder?: string;
}

// limits of nine trillion are near the exact limit for numbers remaining numbers in MongoDB numbers above nine trillion become sting
const HARD_MIN_LIMIT = -9000000000000000000n; // n means BigInt
const HARD_MAX_LIMIT = 9000000000000000000n;

const WidgetNumber: Widget<WidgetNumberProperties, WidgetResult<number>> = (props) => {
  const { t } = useTranslation();
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetNumber.validate,
    { onChange: "none", onBlur: "persist-on-unmount", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }

  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("NUMBER_FIELD")}`}>
      <NumberInput
        {...field.props}
        onChange={(values) => {
          field.controller.field.onChange({ ...field.controller.field.value, rawValue: values.floatValue });
        }}
        onBlur={async (e) => {
          await helpers.persist(getFormValue(e));
          field.props.onBlur(e);
          field.controller.field.onBlur();
        }}
        onPlusMinus={
          isTouchCapable()
            ? async (): Promise<void> => {
                // Only switch real numbers and skip 0 because -0 is a quirky number
                if (isFinite(field.props.value) && field.props.value !== 0) {
                  const rawValue = field.props.value * -1;
                  field.controller.field.onChange({ ...field.controller.field.value, rawValue });
                  await helpers.persist(rawValue);
                }
              }
            : undefined
        }
        inputMode="decimal"
        min={props.field.properties.numeric_min}
        max={props.field.properties.numeric_max}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        disabled={isDisabled}
        placeholder={props.field.properties.text_placeholder}
        showThousandSeparator
      />
    </article>
  );
};

WidgetNumber.defaultValue = (field, defaultMeta: any): WidgetResult<number> => ({
  type: "number",
  rawValue: field.properties.text_default_value,
  meta: {
    widget: "number",
    ...defaultMeta,
  },
});

WidgetNumber.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const min = properties.numeric_min ?? HARD_MIN_LIMIT;
  const max = properties.numeric_max ?? HARD_MAX_LIMIT;

  if (required && isNil(val)) {
    return t("VALIDATION_REQUIRED");
  }

  if (!isNil(val)) {
    //  also check val against hard limits in case min and/or max from platform succeeds hard limits
    if (val < min || val < HARD_MIN_LIMIT) {
      return t("VALIDATION_NUMBER_MIN", { min: min < HARD_MIN_LIMIT ? HARD_MIN_LIMIT : min });
    }
    if (val > max || val > HARD_MAX_LIMIT) {
      return t("VALIDATION_NUMBER_MAX", { max: max > HARD_MAX_LIMIT ? HARD_MAX_LIMIT : max });
    }
  }

  return undefined;
};

export default WidgetNumber;
