import stringLength from "string-length";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { TextInput } from "../../storybook/components/TextInput/TextInput";

export interface WidgetTextareaProperties {
  required: boolean;
  label_text: string;
  max_length?: number;
  text_placeholder?: string;
  text_default_value?: string;
}

const WidgetTextarea: Widget<WidgetTextareaProperties, WidgetResult<string>> = (props) => {
  const { t } = useTranslation();
  const { field, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetTextarea.validate,
    { onChange: "set", onBlur: "persist", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("TEXT_AREA_FIELD")}`}>
      <TextInput
        {...field.props}
        inputRef={field.inputRef}
        disabled={isDisabled}
        type="textarea"
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        placeholder={props.field.properties.text_placeholder}
      />
    </article>
  );
};

WidgetTextarea.defaultValue = (field, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: field.properties.text_default_value,
  meta: {
    widget: "textarea",
    ...defaultMeta,
  },
});

WidgetTextarea.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  const max = properties.max_length;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  if (val && max && stringLength(val) > max) {
    return t("VALIDATION_TEXT_MAX", { max });
  }

  return undefined;
};

export default WidgetTextarea;
