/* eslint-disable consistent-return */
import { Capacitor } from "@capacitor/core";
import { AxiosProgressEvent } from "axios";
import { Directory } from "@capacitor/filesystem";
import mime from "mime";
import logger from "../utils/logger";
import { API_URL } from "../constants";
import { useMoreAppClient } from "../context/MoreAppContext";
import { FileResult } from "../types/Widget";
import useAuth from "./useAuth";
import { deleteFile, readFile, rmDir, writeFileBlob } from "../utils/fileSystemUtil";
import { getFilePath, filePathToUri, getExtensionBlob } from "../utils/fileUtil";
import { UploadStatus } from "../types/Field";
import { seconds } from "../utils/timeUtil";

export type UploadResult = {
  fileResult: FileResult;
  uploadStatus: UploadStatus;
};

const LEGACY_GRIDFS_PREFIX = "gridfs://registrationFiles/";

const MAX_FILE_SIZE = 50 * 1024 * 1024;

export type UseFileHandlerResult = {
  storeFile: (blob: Blob, fileName: string, submissionId: string, extension?: string) => Promise<string>;
  uploadFile: (
    blob: Blob,
    id: string,
    submissionId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
    extension?: string,
  ) => Promise<UploadResult>;
  storeAndUploadFile: (
    blobUri: string,
    submissionId: string,
    id: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
  ) => Promise<UploadResult>;
  retryUploadLocalFile: (
    fileId: string,
    submissionId: string,
    name?: string,
    extension?: string,
  ) => Promise<UploadResult>;
  getFileUrl: (file: FileResult, submissionId: string, remotePath?: boolean) => Promise<string | undefined>;
  removeLocalFiles: (submissionId: string) => Promise<void>;
};

const useFileHandler = (): UseFileHandlerResult => {
  const client = useMoreAppClient();
  const { customerId, username } = useAuth();

  const storeFile = async (blob: Blob, fileName: string, submissionId: string, extension?: string): Promise<string> =>
    writeFileBlob({
      path: getFilePath(username!, customerId!, submissionId, fileName, extension),
      blob,
    });

  const uploadFile = async (
    blob: Blob,
    id: string,
    submissionId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
    extension?: string,
  ): Promise<UploadResult> => {
    try {
      if (blob.size > MAX_FILE_SIZE) {
        return { fileResult: { id, name: fileName }, uploadStatus: "size_exceeded" };
      }
      const remoteId = await postBlob(blob, submissionId, onUploadProgress, abortSignal, fileName);
      return { fileResult: { id, remoteId, name: fileName, extension }, uploadStatus: "uploaded" };
    } catch (e) {
      if (abortSignal?.aborted) {
        return { fileResult: { id, name: fileName, extension }, uploadStatus: "aborted" };
      }

      logger.warn("Could not upload file", id);
      return { fileResult: { id, name: fileName, extension }, uploadStatus: "failed" }; // that's ok, we'll try again later
    }
  };

  const storeAndUploadFile = async (
    blobUri: string,
    submissionId: string,
    id: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
  ): Promise<UploadResult> => {
    const blob = await fetch(blobUri).then((r) => r.blob());
    const extension = getExtensionBlob(blob);

    try {
      await storeFile(blob, id, submissionId, extension);
    } catch (e) {
      logger.error("Could not store file", e);
    }
    return uploadFile(blob, id, submissionId, onUploadProgress, abortSignal, fileName, extension);
  };

  const getBlob = async (path: string, fileName?: string): Promise<Blob> => {
    const uri = await filePathToUri(path, getWebFile, fileName);
    return fetch(uri).then((r) => r.blob());
  };

  const retryUploadLocalFile = async (
    fileId: string,
    submissionId: string,
    name?: string,
    extension?: string,
  ): Promise<UploadResult> => {
    let blob: Blob;
    try {
      blob = await getBlob(getFilePath(username!, customerId!, submissionId, fileId, extension), name);
    } catch (e) {
      return { fileResult: { id: fileId, name, extension }, uploadStatus: "error" };
    }

    const result = await uploadFile(blob, fileId, submissionId, undefined, undefined, name, extension);
    if (!Capacitor.isNativePlatform() && result.uploadStatus === "uploaded") {
      try {
        await removeLocalFile(fileId, submissionId);
      } catch (e) {
        // Probably already deleted
      }
    }
    return result;
  };

  const getFileUrl = async (
    file: FileResult,
    submissionId: string,
    remotePath = false,
  ): Promise<string | undefined> => {
    // always use remote path for web or when the remote path is requested
    if ((!Capacitor.isNativePlatform() || remotePath) && file.remoteId) {
      return `${API_URL}/client/registrations/files/${file.remoteId}`;
    }

    try {
      const path = getFilePath(username!, customerId!, submissionId, file.id, file.extension);
      return await filePathToUri(path, getWebFile, file.name);
    } catch (e) {
      if (file.remoteId) {
        return `${API_URL}/client/registrations/files/${file.remoteId}`;
      }
    }
  };

  const getWebFile = async (path: string, fileName?: string): Promise<string> => {
    const mimeType = fileName && mime.getType(fileName);
    const webFile = await readFile({ path, directory: Directory.Data });
    return `data:${mimeType || "image/jpeg"};base64,${webFile.data}`;
  };

  const removeLocalFile = async (fileName: string, submissionId: string, extension?: string): Promise<void> =>
    deleteFile({
      path: getFilePath(username!, customerId!, submissionId, fileName, extension),
    });

  const removeLocalFiles = async (submissionId: string): Promise<void> =>
    rmDir({ path: `${username}/${customerId}/submissionFiles/${submissionId}` }).catch((e) => {
      logger.warn("Tried to delete local files", e); // That's ok, there are probably no files to delete
    });

  const postBlob = async (
    blob: Blob,
    submissionId: string,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortSignal?: AbortSignal,
    fileName?: string,
  ): Promise<any> => {
    const data = new FormData();
    data.append("file", blob, fileName ?? ""); // Use empty string instead of undefined to prevent file from showing up as 'blob.ext' in fileDetails
    data.append("customerNumber", `${customerId}`);
    data.append("hasuraSubmissionUuid", submissionId);
    const response = await client!.post("/api/v1.0/client/registrations/files", data, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
      signal: abortSignal,
      timeout: seconds(90), // Overriding the default. File uploads can take longer on unstable connections
    });
    return response.data.replace(LEGACY_GRIDFS_PREFIX, "");
  };

  return {
    storeFile,
    uploadFile,
    storeAndUploadFile,
    retryUploadLocalFile,
    getFileUrl,
    removeLocalFiles,
  };
};

export default useFileHandler;
