import { ComponentProps, createElement, FC, ReactSVGElement } from "react";
import * as solid from "@heroicons/react/solid";
import * as outline from "@heroicons/react/outline";
import classNames from "classnames";
import { PaperAirplaneIcon } from "@heroicons/react/outline";
import { PaperAirplaneIcon as SolidPaperAirplaneIcon } from "@heroicons/react/solid";
import { containsSizeOverride } from "../../utils/classHelper";

export type IconName =
  | keyof typeof solid
  | keyof typeof outline
  | "CarIcon"
  | "CurrentLocationIcon"
  | "CalendarTimeIcon"
  | "SendIcon"
  | "CircleIcon"
  | "PlusMinusIcon";

export interface IconProps {
  name: IconName;
  type?: "solid" | "outline";
  className?: string;
}

export const Icon: FC<IconProps> = ({ name, type = "solid", className }) => {
  const sizeOverride = containsSizeOverride(className);
  const classes = classNames({ "size-6": !sizeOverride }, className);

  if (name === "CarIcon") {
    return CarIcon({ className: classes });
  }
  if (name === "CurrentLocationIcon") {
    return CurrentLocationIcon({ className: classes });
  }
  if (name === "CalendarTimeIcon") {
    return CalendarTimeIcon({ className: classes });
  }
  if (name === "SendIcon") {
    return type === "solid" ? (
      <SolidPaperAirplaneIcon className={classNames("rotate-90", classes)} />
    ) : (
      <PaperAirplaneIcon className={classNames("rotate-90", classes)} />
    );
  }
  if (name === "CircleIcon") {
    return CircleIcon({ className: classes });
  }
  if (name === "PlusMinusIcon") {
    return PlusMinusIcon({ className: classes });
  }
  const HeroIcon = type === "solid" ? solid[name] : outline[name];
  return <HeroIcon className={classes} />;
};

const CustomIcon = (props: ComponentProps<"svg">, elements: ReactSVGElement[]): ReactSVGElement =>
  createElement(
    "svg",
    {
      "aria-hidden": "true",
      xmlns: "http://www.w3.org/2000/svg",
      fill: "currentColor",
      viewBox: "0 0 24 24",
      stroke: "currentColor",
      ...props,
    },
    elements,
  );

const CarIcon = (props: ComponentProps<"svg">): ReactSVGElement => {
  const d =
    "M20.985 10.3491C20.9743 10.2995 20.9586 10.2511 20.9381 10.2047L19.1672 6.07219C18.7589 5.11734 17.7656 4.5 16.6355 4.5H7.36453C6.23531 4.5 5.24109 5.11734 4.83328 6.07219L3.06047 10.2047C3.02018 10.2979 2.99959 10.3984 3 10.5V18.75C3 18.9489 3.07902 19.1397 3.21967 19.2803C3.36032 19.421 3.55109 19.5 3.75 19.5H5.25C5.44891 19.5 5.63968 19.421 5.78033 19.2803C5.92098 19.1397 6 18.9489 6 18.75V18H18V18.75C18 18.9489 18.079 19.1397 18.2197 19.2803C18.3603 19.421 18.5511 19.5 18.75 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V10.5C21.0001 10.4493 20.995 10.3987 20.985 10.3491V10.3491ZM6.75 15C6.45333 15 6.16332 14.912 5.91664 14.7472C5.66997 14.5824 5.47771 14.3481 5.36418 14.074C5.25065 13.7999 5.22094 13.4983 5.27882 13.2074C5.3367 12.9164 5.47956 12.6491 5.68934 12.4393C5.89912 12.2296 6.16639 12.0867 6.45736 12.0288C6.74833 11.9709 7.04993 12.0006 7.32402 12.1142C7.59811 12.2277 7.83238 12.42 7.9972 12.6666C8.16202 12.9133 8.25 13.2033 8.25 13.5C8.25 13.8978 8.09196 14.2794 7.81066 14.5607C7.52935 14.842 7.14782 15 6.75 15ZM17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7506 13.7999 15.7209 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3107 14.5607C18.0294 14.842 17.6478 15 17.25 15ZM4.88719 9.75L6.21047 6.66328C6.38015 6.26672 6.84375 6 7.36453 6H16.6355C17.1558 6 17.6198 6.26672 17.7895 6.66328L19.1128 9.75H4.88719Z";
  return CustomIcon(props, [createElement("path", { strokeWidth: 0.1, d })]);
};

const CurrentLocationIcon = (props: ComponentProps<"svg">): ReactSVGElement => {
  const d =
    "M11.9999 2C13.1044 2 13.9999 2.89544 13.9999 4.00003V4.76969C16.5344 5.46921 18.5308 7.46563 19.2303 10.0001H20C21.1046 10.0001 22 10.8956 22 12.0002C22 13.1047 21.1046 14.0002 20 14.0002H19.2304C18.5308 16.5347 16.5344 18.5312 13.9999 19.2307V20.0003C13.9999 21.1049 13.1044 22.0003 11.9999 22.0003C10.8953 22.0003 9.99982 21.1049 9.99982 20.0003V19.2307C7.4653 18.5312 5.46886 16.5347 4.76934 14.0002H3.99973C2.89514 14.0002 1.99969 13.1047 1.99969 12.0002C1.99969 10.8956 2.89514 10.0001 3.99973 10.0001H4.76936C5.46889 7.46563 7.46532 5.4692 9.99982 4.76968V4.00003C9.99982 2.89544 10.8953 2 11.9999 2ZM11.9999 16.8001C14.6508 16.8001 16.7999 14.6511 16.7999 12.0001C16.7999 9.34915 14.6508 7.20012 11.9999 7.20012C9.34891 7.20012 7.19988 9.34915 7.19988 12.0001C7.19988 14.6511 9.34891 16.8001 11.9999 16.8001Z";
  return CustomIcon(props, [
    createElement("path", { strokeWidth: 0.1, d }),
    createElement("circle", { r: 3, cx: 12, cy: 12 }),
  ]);
};

const CalendarTimeIcon = (props: ComponentProps<"svg">): ReactSVGElement => {
  const d1 =
    "M3.4 1.8C3.4 1.35817 3.75817 1 4.2 1C4.64183 1 5 1.35817 5 1.8V2.6H9.8V1.8C9.8 1.35817 10.1582 1 10.6 1C11.0418 1 11.4 1.35817 11.4 1.8V2.6H12.2C13.0837 2.6 13.8 3.31634 13.8 4.2V6.85695C13.0973 6.38109 12.267 6.07931 11.3712 6.01357C11.39 5.94558 11.4 5.87396 11.4 5.8C11.4 5.35817 11.0418 5 10.6 5H4.2C3.75817 5 3.4 5.35817 3.4 5.8C3.4 6.24183 3.75817 6.6 4.2 6.6H8.62308C7.06095 7.44565 5.99997 9.09892 5.99997 11C5.99997 12.0375 6.31594 13.0011 6.8569 13.8H2.6C1.71634 13.8 1 13.0837 1 12.2V4.2C1 3.31634 1.71634 2.6 2.6 2.6H3.4V1.8Z";
  const d2 =
    "M11 15C13.2091 15 15 13.2091 15 11C15 8.79086 13.2091 7 11 7C8.79083 7 6.99997 8.79086 6.99997 11C6.99997 13.2091 8.79083 15 11 15ZM11.5 9C11.5 8.72386 11.2761 8.5 11 8.5C10.7238 8.5 10.5 8.72386 10.5 9V11C10.5 11.1326 10.5526 11.2598 10.6464 11.3536L12.0606 12.7678C12.2559 12.963 12.5725 12.963 12.7677 12.7678C12.963 12.5725 12.963 12.2559 12.7677 12.0607L11.5 10.7929V9Z";

  return CustomIcon({ ...props, viewBox: "0 0 16 16", stroke: "auto" }, [
    createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: d1,
      fill: "currentColor",
    }),
    createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: d2,
      fill: "currentColor",
    }),
  ]);
};

const PlusMinusIcon = (props: ComponentProps<"svg">): ReactSVGElement =>
  CustomIcon({ ...props, viewBox: "0 0 24 24", fill: "none", stroke: "none" }, [
    createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M14 16.006a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z",
      fill: "currentColor",
    }),
    createElement(
      "mask",
      {
        id: "plus-mask",
        fill: "#fff",
      },
      [
        createElement("path", {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M5 12.004a1 1 0 1 0 2 0v-2.002h2a1 1 0 0 0 0-2H7V6.001a1 1 0 1 0-2 0v2.001H3a1 1 0 1 0 0 2h2v2.002Z",
        }),
      ],
    ),
    createElement("path", {
      d: "M7 10.002v-2H5v2h2Zm0-2H5v2h2v-2Zm-2 0v2h2v-2H5Zm0 2h2v-2H5v2Zm1 1.002a1 1 0 0 1 1 1H3a3 3 0 0 0 3 3v-4Zm-1 1a1 1 0 0 1 1-1v4a3 3 0 0 0 3-3H5Zm0-2.002v2.002h4v-2.002H5Zm4-2H7v4h2v-4Zm-1 1a1 1 0 0 1 1-1v4a3 3 0 0 0 3-3H8Zm1 1a1 1 0 0 1-1-1h4a3 3 0 0 0-3-3v4Zm-2 0h2v-4H7v4ZM5 6.001v2.001h4V6.001H5Zm1 1a1 1 0 0 1-1-1h4a3 3 0 0 0-3-3v4Zm1-1a1 1 0 0 1-1 1V3a3 3 0 0 0-3 3h4Zm0 2.001V6.001H3v2.001h4Zm-4 2h2v-4H3v4Zm1-1a1 1 0 0 1-1 1v-4a3 3 0 0 0-3 3h4Zm-1-1a1 1 0 0 1 1 1H0a3 3 0 0 0 3 3v-4Zm2 0H3v4h2v-4Zm2 4.002v-2.002H3v2.002h4Z",
      fill: "currentColor",
      mask: "url(#plus-mask)",
    }),
    createElement("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.354 5.1a1 1 0 0 1 .532 1.311L10.42 19.34a1 1 0 1 1-1.842-.78l5.465-12.928a1 1 0 0 1 1.31-.532Z",
      fill: "currentColor",
    }),
  ]);

const CircleIcon = (props: ComponentProps<"svg">): ReactSVGElement =>
  CustomIcon(props, [createElement("circle", { cx: 12, cy: 12, r: 9 })]);
