import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isNil } from "lodash-es";
import useMigration from "../hooks/useMigration";
import { UploadManagerProvider } from "../context/UploadManagerContext";
import Compose from "../components/Compose";
import CorruptDatabaseModal from "../components/CorruptDatabaseModal";
import useDatabase from "../hooks/useDatabase";
import useSubmissionFinalizer from "../hooks/useSubmissionFinalizer";

const PrivateRoute: FC = () => {
  const { shouldMigrate, isLoggedIn } = useMigration();

  if (isLoggedIn && shouldMigrate) {
    return <Navigate to="/migrate" />;
  }

  return isLoggedIn ? (
    <Compose components={[UploadManagerProvider]}>
      <LoggedInRoute />
    </Compose>
  ) : (
    <Navigate to="/login" />
  );
};

const LoggedInRoute: FC = () => {
  const { isCorrupt } = useDatabase();
  useSubmissionFinalizer();

  return (
    <>
      {!isNil(isCorrupt) && <CorruptDatabaseModal state={isCorrupt} />}
      <Outlet />
    </>
  );
};

export default PrivateRoute;
