import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Text } from "../storybook/components/Text/Text";
import { Button } from "../storybook/components/Button/Button";

const SyncOfflineContent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mx-12 flex flex-col items-center space-y-2 text-center">
      <img className="mx-auto mb-7 size-20" src="assets/errorpages/not-exist.svg" alt={t("NOT_SYNCED_ALT")} />
      <Text size="lg" weight="semibold">
        {t("SYNC_OFFLINE_SCREEN_TITLE")}
      </Text>
      <Text color="medium">{t("SYNC_OFFLINE_SCREEN_MESSAGE")}</Text>
      <Button onClick={() => navigate("/tasks")}>{t("SYNC_OFFLINE_SCREEN_CTA")}</Button>
    </div>
  );
};

export default SyncOfflineContent;
