import { useEffect } from "react";
import { isRxDocument } from "rxdb";
import { useFormContext, UseFormGetValues } from "react-hook-form";
import { debounce } from "lodash-es";
import { widgetResultByDataName } from "../utils/formUtil";
import { AbstractForm } from "../types/FormVersion";
import { templateRegex } from "../utils/interpolateUtil";
import { SubmissionDocument } from "../utils/databaseUtil";
import { SubmissionFormData } from "../components/Form";
import { getTemplatedContent } from "../utils/templateUtil";
import { Field } from "../types/Field";

const updateFormDescription = (
  getValues: UseFormGetValues<SubmissionFormData>,
  submission: SubmissionDocument,
  formVersion: AbstractForm,
): void => {
  if (!formVersion.settings?.itemHtml) {
    return;
  }
  const inputData = widgetResultByDataName(formVersion.fields, getValues(), submission.id);
  const description = formVersion.settings.itemHtml
    .replace(templateRegex, (_match, path) => {
      const widgetResult = inputData[path.split(".")[0]];
      return getTemplatedContent(path, widgetResult, { humanReadable: true });
    })
    .trim();
  if (isRxDocument(submission) && submission?.description !== description) {
    submission?.incrementalPatch({ description });
  }
};

const updateFormDescriptionDebounced = debounce(updateFormDescription, 100);

const useFormDescription = (submission: SubmissionDocument, formVersion: AbstractForm, fields: Field[]): void => {
  const { getValues } = useFormContext<SubmissionFormData>();

  useEffect(() => {
    updateFormDescriptionDebounced(getValues, submission, formVersion);

    return (): void => {
      updateFormDescription(getValues, submission, formVersion);
    };
    // fields dependency is needed for triggering the updates in certain cases
  }, [getValues, submission, formVersion, fields]);
};

export default useFormDescription;
