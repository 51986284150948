import { FC, useMemo } from "react";
import uuidv4 from "../../utils/uuid";
import SearchSubmissionList, { SearchSubmission } from "./SearchSubmissionList";

type RecentSubmissionsProps = {
  customerId: number;
  formVersionId: string;
  formId: string;
  onClick: (value: string) => void;
};

const RecentSubmissions: FC<RecentSubmissionsProps> = ({ formId, formVersionId, customerId, onClick }) => {
  const recentQuery: SearchSubmission = useMemo(
    () => ({
      id: uuidv4(),
      customerId,
      formId,
      formVersionId,
      meta: {},
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      fields: [],
    }),
    [customerId, formId, formVersionId],
  );
  return <SearchSubmissionList searchSubmission={recentQuery} onClick={onClick} onlyUser />;
};

export default RecentSubmissions;
