import type { UseQueryResult } from "@tanstack/react-query";
import useForm from "./useForm";
import useFormVersion from "./useFormVersion";
import type { FormVersion } from "../types/FormVersion";
import type { MoreAppError } from "../context/MoreAppContext";

const usePublishedFormVersion = (customerId?: number, id?: string): UseQueryResult<FormVersion, MoreAppError> => {
  const { data: form } = useForm(id);
  return useFormVersion(form?.publishedVersion.formVersion, customerId, form?.id);
};

export default usePublishedFormVersion;
