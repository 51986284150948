import { WithDeleted } from "rxdb";
import { SubformEntry } from "../components/widgets/WidgetSubform";
import { RuleResult } from "../hooks/useFormRules";
import { decompress } from "../utils/compressUtil";

export type FieldId = string;
export type UploadStatus =
  | "uploading"
  | "failed"
  | "uploaded"
  | "aborted"
  | "error"
  | "size_exceeded"
  | "invalid_extension";
export const CLEARABLE_UPLOAD_STATUSES: UploadStatus[] = ["uploaded", "size_exceeded", "error", "invalid_extension"];

export type RemoteField = {
  id: string;
  submissionId: string;
  meta: {
    type: WidgetDataTypes;
    formFieldId: string;
    widget: string;
    dataName: string;
    deviceId?: string;
    error?: string;
    hidden?: boolean;
    compressed: boolean;
    evaluatedRules: RuleResult[];
    order: number;
    updatedBy?: string;
    uploadStatus?: UploadStatus;
  };
  data?: unknown;
  entry?: {
    id?: string;
    fieldId?: string;
  };
  entryId?: string;
  entries: Entry[];
  updatedAt: string;
  _deleted: boolean;
  status: "draft" | "final";
};

export type Field = {
  id: string;
  submissionId: string;
  data?: unknown;
  deviceId?: string;
  error?: string;
  updatedAt: string;
  formFieldId: string;
  dataName?: string;
  widget: string;
  type: WidgetDataTypes;
  status: "draft" | "final";
  entryId?: string;
  parentId?: string;
  entries: Entry[];
  hidden: boolean;
  compressed: boolean;
  evaluatedRules: RuleResult[];
  order: number;
  updatedBy?: string;
  uploadStatus?: UploadStatus;
  _deleted: boolean;
};

export type HasuraField = {
  id: string;
  submissionId: string;
  status: string;
  data: unknown;
  meta: {
    type: WidgetDataTypes;
    formFieldId: string;
    widget: string;
    dataName?: string;
    deviceId?: string;
    error?: string;
    hidden?: boolean;
    compressed: boolean;
    evaluatedRules?: RuleResult[];
    order: number;
    updatedBy?: string;
    uploadStatus?: UploadStatus;
  };
  entries?: {
    data: unknown[];
    on_conflict: {
      update_columns: string[];
      constraint: string;
    };
  };
  entryId?: string;
  deleted: boolean;
};

export type FieldMeta = {
  id: string;
  submissionId: string;
  remote: boolean;
};

export type RememberedField = {
  id: string;
  formId: string;
  widget: string;
  type: WidgetDataTypes;
  data?: unknown;
  updatedAt?: string;
  dataName?: string;
};

export type RememberedSearchQuery = {
  id: string;
  formId: string;
  query?: string;
};

export type WidgetDataTypes =
  | "string"
  | "number"
  | "boolean"
  | "object"
  | "array"
  | "file"
  | "date"
  | "datetime"
  | "time"
  | "none"
  | "location"
  | "duration"
  | "currency";

export type Entry = {
  id: string;
  submissionId: string;
  meta: Record<string, any>;
  deleted: boolean;
};

export type WidgetResult<R> = {
  type: WidgetDataTypes;
  rawValue?: R;
  entries?: SubformEntry<any>[];
  formattedValue?: string;
  updatedAt?: string;
  meta: WidgetResultMeta;
};
export type WidgetResultMeta = {
  widget: string;
  submissionId?: string; // The id of the submission this is linked to
  formFieldId: string; // The id of the form field
  fieldId: string; // The id of this particular instance
  entryId?: string; // The id when in a subform
  parentId?: string; // The id of the parent when in a subform
  dataName?: string; // Customer facing name for this configured field
  evaluatedRules: RuleResult[];
  hidden: boolean;
  compressed: boolean;
  order: number;
  uploadStatus?: UploadStatus;
};

export const rxToForm = (field: Field): WidgetResult<unknown> => ({
  rawValue: field.compressed ? decompress(field.data as string) : field.data,
  entries: field.entries,
  meta: {
    widget: field.widget,
    submissionId: field.submissionId,
    formFieldId: field.formFieldId,
    fieldId: field.id,
    entryId: field.entryId,
    parentId: field.parentId,
    dataName: field.dataName,
    hidden: field.hidden,
    compressed: field.compressed,
    evaluatedRules: field.evaluatedRules,
    order: field.order,
    uploadStatus: field.uploadStatus,
  },
  updatedAt: field.updatedAt,
  type: field.type,
});

export const searchFormToRemote = (field: WidgetResult<unknown>): RemoteField => ({
  id: field.meta.fieldId,
  submissionId: field.meta.submissionId ?? "",
  meta: {
    type: field.type,
    formFieldId: field.meta.formFieldId,
    widget: field.meta.widget,
    dataName: field.meta.dataName || "",
    hidden: false,
    compressed: field.meta.compressed,
    evaluatedRules: [],
    order: field.meta.order,
  },
  data: field.rawValue,
  entries: field.entries ?? [],
  status: "draft",
  updatedAt: field.updatedAt ?? new Date().toISOString(),
  _deleted: false,
});

export const remoteFieldToLocal = (doc: RemoteField, submissionId?: string): WithDeleted<Field> => ({
  id: doc.id,
  submissionId: submissionId ?? doc.submissionId,
  data: doc.data,
  error: doc.meta.error,
  updatedAt: doc.updatedAt,
  formFieldId: doc.meta.formFieldId,
  widget: doc.meta.widget,
  dataName: doc.meta.dataName,
  type: doc.meta.type,
  entryId: doc.entry?.id,
  parentId: doc.entry?.fieldId,
  entries: doc.entries,
  status: doc.status,
  hidden: doc.meta.hidden ?? false,
  compressed: doc.meta.compressed ?? false,
  deviceId: doc.meta.deviceId,
  evaluatedRules: doc.meta.evaluatedRules,
  order: doc.meta.order,
  updatedBy: doc.meta.updatedBy,
  uploadStatus: doc.meta.uploadStatus,
  _deleted: doc._deleted, // eslint-disable-line no-underscore-dangle
});

export const fieldToRemoteHasura = (doc: WithDeleted<Field>, status?: string): HasuraField => ({
  id: doc.id,
  submissionId: doc.submissionId,
  status: status || doc.status,
  meta: {
    type: doc.type,
    formFieldId: doc.formFieldId,
    widget: doc.widget,
    dataName: doc.dataName,
    error: doc.error,
    hidden: doc.hidden,
    compressed: doc.compressed,
    deviceId: doc.deviceId,
    evaluatedRules: doc.evaluatedRules,
    order: doc.order,
    updatedBy: doc.updatedBy,
    uploadStatus: doc.uploadStatus,
  },
  data: doc.data,
  entryId: doc.entryId,
  entries: doc.entries
    ? {
        data: doc.entries.map((entry) => ({ ...entry, fieldId: undefined })),
        on_conflict: {
          constraint: "submission_field_entries_pkey",
          update_columns: ["deleted", "meta"],
        },
      }
    : undefined,
  deleted: doc._deleted, // eslint-disable-line no-underscore-dangle
});

export const fieldToRemote = (doc: Field): RemoteField => ({
  id: doc.id,
  submissionId: doc.submissionId,
  meta: {
    type: doc.type,
    formFieldId: doc.formFieldId,
    widget: doc.widget,
    dataName: doc.dataName || "",
    hidden: doc.hidden,
    compressed: doc.compressed,
    evaluatedRules: doc.evaluatedRules,
    order: doc.order,
    updatedBy: doc.updatedBy,
    uploadStatus: doc.uploadStatus,
  },
  data: doc.data,
  entries: doc.entries ?? [],
  entryId: doc.entryId,
  status: doc.status,
  updatedAt: doc.updatedAt,
  _deleted: false,
});

export const submissionFormDataToFields = (formData: Record<string, WidgetResult<unknown>>): Field[] =>
  Object.values(formData).map((widgetResult) => {
    const field: Field = {
      id: widgetResult.meta.fieldId,
      submissionId: widgetResult.meta.submissionId ?? "",
      data: widgetResult.rawValue,
      updatedAt: widgetResult.updatedAt ?? "",
      formFieldId: widgetResult.meta.formFieldId,
      dataName: widgetResult.meta.dataName,
      widget: widgetResult.meta.widget,
      type: widgetResult.type,
      status: "final",
      entryId: widgetResult.meta.entryId,
      parentId: widgetResult.meta.parentId,
      entries: widgetResult.entries ?? [],
      hidden: widgetResult.meta.hidden,
      compressed: widgetResult.meta.compressed ?? false,
      evaluatedRules: widgetResult.meta.evaluatedRules,
      order: widgetResult.meta.order,
      _deleted: false,
    };
    return field;
  });
