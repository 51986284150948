import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRxCollection } from "rxdb-hooks";
import { useSearchParams } from "react-router-dom";
import { first, isNil } from "lodash-es";
import useAuth from "../hooks/useAuth";
import uuidv4 from "../utils/uuid";
import { Submission } from "../types/Submission";
import useFormTokenDetails from "../hooks/useFormTokenDetails";
import DirectUrlExpiredPage from "./errorpages/DirectUrlExpiredPage";
import useOnlineStatus from "../hooks/useOnlineStatus";
import OfflinePage from "./errorpages/OfflinePage";
import { Form } from "../types/Folder";
import LoadingPage from "./LoadingPage";
import useDirectForm from "../hooks/useDirectForm";
import useFormVersion from "../hooks/useFormVersion";
import SubmissionNotFoundPage from "./errorpages/SubmissionNotFoundPage";
import SentLandingPage from "./SentLandingPage";
import logger from "../utils/logger";
import DirectUrlForm from "../components/DirectUrlForm";
import useTheme from "../hooks/useTheme";
import { Field } from "../types/Field";
import useStatusBar from "../hooks/useStatusBar";
import useLocalSubmissions from "../hooks/useLocalSubmissions";
import { useSubmissionPageTitle } from "../hooks/useSubmissionPageTitle";
import useLocalRememberedFields from "../hooks/useLocalRememberedFields";
import useRememberFields from "../hooks/useRememberFields";

const DirectUrlPage: FC<{ preview?: boolean }> = ({ preview }) => {
  const { token, formVersionId } = useParams<{ token: string; formVersionId: string }>();
  const submissions = useRxCollection<Submission>("submissions");
  const { data: formTokenDetails, isLoading } = useFormTokenDetails(token);
  const { data: form, isLoading: isFormLoading } = useDirectForm(
    formTokenDetails?.formId,
    formTokenDetails?.customerId,
  );
  const { authorizeDirectUrl, deauthorizeDirectUrl, authorization } = useAuth();
  const { isOnline } = useOnlineStatus();
  const [searchParams, setSearchParams] = useSearchParams();
  const [routeId, setRouteId] = useState<string | null>(searchParams.get("id"));
  const [initState, setInitState] = useState<"pending" | "initializing" | "initialized">("pending");

  const { result } = useLocalSubmissions((collection) => {
    const submissionId = routeId ?? ""; // Query param can't be empty
    return collection?.find().where("id").equals(submissionId);
  });

  const submission = first(result);
  const { data: formVersion, isLoading: isLoadingFormVersion } = useFormVersion(
    submission?.formVersionId,
    submission?.customerId,
    submission?.formId,
  );
  const fieldCollection = useRxCollection<Field>("fields");
  useSubmissionPageTitle(submission);

  const { clearAllRememberedFields } = useRememberFields();
  const { rememberedFields } = useLocalRememberedFields(submission?.formId);

  const theme = useTheme(formVersion);
  useStatusBar(theme.color);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(authorization.type === "oauth");
      authorizeDirectUrl(token).catch((e) => logger.error("Couldn't authorize direct url", e));
    }
    return deauthorizeDirectUrl;
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const createNewSubmission = useCallback(
    async (customerId: number, newForm: Form) => {
      if (initState !== "pending") {
        return;
      }
      const id = uuidv4();
      await submissions?.upsert({
        id,
        customerId,
        formId: newForm.id,
        formVersionId: formVersionId ?? newForm.publishedVersion.formVersion,
        status: "draft",
        form: {
          name: newForm.meta.name,
          description: newForm.meta.description,
          icon: newForm.meta.icon,
          iconColor: newForm.meta.iconColor,
        },
        meta: {},
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        sendType: "manual",
      });
      setSearchParams((prev) => {
        const updatedSearchParams = new Map(prev);
        updatedSearchParams.set("id", id); // Replace instead of adding extra search param
        setRouteId(id);
        return [...updatedSearchParams.entries()];
      });
    },
    [formVersionId, initState, setSearchParams, submissions],
  );

  useEffect(() => {
    // Make sure proper database is loaded, could be switching auth
    const expectedDatabaseName = `moreapp-anonymous-rx15`;
    const isDatabaseInitialized = submissions?.database.name === expectedDatabaseName && formTokenDetails && form;
    if (!isDatabaseInitialized) {
      return;
    }
    if (initState !== "pending") {
      return;
    }

    setInitState("initializing");
    clearAllRememberedFields()?.catch((e) => logger.error("Couldn't clear remembered fields", e));

    if (!routeId) {
      createNewSubmission(formTokenDetails.customerId, form)
        .catch((e) => logger.error("Couldn't create new submission", e))
        .finally(() => setInitState("initialized"));
    } else {
      submissions
        .findOne(routeId)
        .exec()
        .then((value) => {
          if (isNil(value)) {
            createNewSubmission(formTokenDetails.customerId, form).catch((e) =>
              logger.error("Couldn't create new submission", e),
            );
          }
        })
        .finally(() => setInitState("initialized"));
    }
  }, [
    authorization?.username,
    formTokenDetails,
    form,
    submissions,
    routeId,
    createNewSubmission,
    initState,
    clearAllRememberedFields,
  ]);

  if (submission?.status === "final") {
    return <SentLandingPage theme={theme} />;
  }

  if (!isOnline) {
    return <OfflinePage />;
  }

  if ((!isLoading && !formTokenDetails) || (!isFormLoading && !form)) {
    return <DirectUrlExpiredPage />;
  }

  if (isLoading || isLoadingFormVersion) {
    return (
      <LoadingPage
        conditions={[
          { labelKey: "LOADING_ERROR_DIRECT_URL_TOKEN", value: isLoading },
          { labelKey: "LOADING_ERROR_DIRECT_URL_FORM_VERSION", value: isLoadingFormVersion },
        ]}
      />
    );
  }

  if (!fieldCollection) {
    return <LoadingPage conditions={[{ labelKey: "LOADING_ERROR_DIRECT_URL_DATABASE", value: !fieldCollection }]} />;
  }

  if (!isLoadingFormVersion && !formVersion) {
    return isOnline ? <DirectUrlExpiredPage /> : <OfflinePage />;
  }

  if (submission == null || !formVersion) {
    return isOnline ? <SubmissionNotFoundPage /> : <OfflinePage />;
  }

  return (
    <DirectUrlForm
      preview={preview}
      formVersion={formVersion}
      theme={theme}
      submission={submission}
      fieldCollection={fieldCollection}
      isLoggedIn={isLoggedIn}
      rememberedFields={rememberedFields}
    />
  );
};
export default DirectUrlPage;
