import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import useWidget from "../../hooks/useWidget";
import { WidgetResult } from "../../types/Field";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { Title } from "../../storybook/components/Title/Title";

type Size = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface WidgetHeaderProperties {
  label_text: string;
  size: Size;
}

const headingSizeMapping: Record<Size, string> = {
  h1: "3xl",
  h2: "2xl",
  h3: "xl",
  h4: "lg",
  h5: "sm",
  h6: "xs",
};

const WidgetHeader: Widget<WidgetHeaderProperties, WidgetResult<void>> = ({
  fieldRx,
  field: formField,
  entry,
  context,
}) => {
  const { field } = useWidget(
    context,
    formField,
    WidgetHeader.validate,
    { onChange: "none", onBlur: "none" },
    fieldRx,
    entry,
  );
  const size = headingSizeMapping[formField.properties.size];
  const tag = formField.properties.size === "h1" ? "h2" : formField.properties.size;

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={formField} widgetResult={field.result} />;
  }
  return (
    <div>
      <Title as={tag} size={size as any}>
        {formField.properties.label_text}
      </Title>
    </div>
  );
};
WidgetHeader.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "header",
    ...defaultMeta,
  },
});

WidgetHeader.validate = (): undefined => undefined;

export default WidgetHeader;
