import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Content from "../Content";
import Form, { SubmissionFormData } from "../Form";
import { searchFormToRemote } from "../../types/Field";
import { submissionToRemote } from "../../types/Submission";
import { SubmissionDocument } from "../../utils/databaseUtil";
import { FormVersion } from "../../types/FormVersion";
import { hasEmptyValue } from "../../utils/formUtil";
import { Spinner } from "../../storybook/components/Spinner/Spinner";

type SearchSubmissionFieldsProps = {
  formVersion: FormVersion;
  defaultValues: SubmissionFormData;
  submission: SubmissionDocument;
  setResult: (value: any) => void;
};

export type SearchSubmissionFieldsMethods = {
  onSubmit: () => void;
};

const SearchSubmissionFields = forwardRef<SearchSubmissionFieldsMethods, SearchSubmissionFieldsProps>(
  ({ formVersion, defaultValues, setResult, submission }, ref) => {
    const form = useForm({ defaultValues, mode: "onSubmit", reValidateMode: "onSubmit" });

    const onSubmit = form.handleSubmit(async (values) => {
      if (!submission) {
        return;
      }
      setResult({
        fields: Object.values(values)
          .filter((v) => v.meta)
          .filter((v) => !hasEmptyValue(v.rawValue))
          .map(searchFormToRemote),
        ...submissionToRemote(submission),
      });
    });

    useImperativeHandle(ref, () => ({ onSubmit }));

    if (!submission) {
      return <Spinner />;
    }

    return (
      <FormProvider {...form}>
        <Content padding={false} asRows>
          <Form
            submission={submission}
            formVersion={formVersion}
            fieldProperties={formVersion.fieldProperties}
            validationMode="disabled"
          />
        </Content>
      </FormProvider>
    );
  },
);
SearchSubmissionFields.displayName = "SearchSubmissionFields";

export default SearchSubmissionFields;
