import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { first, isEmpty, startCase } from "lodash-es";
import { useRxCollection, useRxData } from "rxdb-hooks";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { Widget } from "../../types/Widget";
import { RememberedSearchQuery, WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { DataSourceEntry } from "../../types/Datasource";
import { getEnabledFields } from "../../utils/datasourceUtil";
import { getSearchEntryHumanText } from "../../utils/stringUtil";
import SearchValue from "./search/SearchValue";
import WidgetError from "./WidgetError";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import SearchDrawer from "./search/SearchDrawer";
import useDrawer from "../../hooks/useDrawer";
import { Label } from "../../storybook/components/Label/Label";
import { Text } from "../../storybook/components/Text/Text";
import { WidgetContentButton } from "../../storybook/components/WidgetContentButton/WidgetContentButton";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { DefinitionList } from "../../storybook/components/DefinitionList/DefinitionList";

export type DatasourceColor = { color: string; query: string };

export interface WidgetSearchProperties {
  required: boolean;
  label_text: string;
  default_value?: string;
  remember_search?: string;
  allow_barcode?: boolean;
  data_source_configuration: {
    id: string;
    mapping: Record<string, boolean>;
  };
  filter_fields?: string[];
  colors?: DatasourceColor[];
}

const WidgetSearch: Widget<WidgetSearchProperties, WidgetResult<DataSourceEntry>> = ({ ...props }) => {
  const { t } = useTranslation();
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetSearch.validate,
    { onChange: "persist", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  const mapping = props.field.properties?.data_source_configuration?.mapping;
  const { isFetching, result: rememberedQuery } = useRxData<RememberedSearchQuery>(
    "remembered-search-query",
    (collection) =>
      collection.find().where("id").eq(props.field.uid).where("formId").eq(props.context.submission.formId),
  );
  const queryCollection = useRxCollection<RememberedSearchQuery>("remembered-search-query");

  const [query, setQuery] = useState("");
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { startScan, isScanSupported, isScannerInstalling } = useBarcodeScanner();
  const [scanResult, setScanResult] = useState<string | undefined>();
  const enabledEntryFields = useMemo(() => getEnabledFields(mapping), [mapping]);
  const [isSearchOpen, setSearchOpen] = useDrawer(`search-${props.field.uid}`);
  const [activeOpen, setActiveOpen] = useDrawer(`active-${props.field.uid}`);
  const [activeItem, setActiveItem] = useState<DataSourceEntry | undefined>();
  const hasDatasource = props.field.properties?.data_source_configuration?.id;

  const handleChange = async (newVal: DataSourceEntry): Promise<void> => {
    if (props.field.properties.remember_search && queryCollection) {
      await queryCollection.upsert({ id: props.field.uid, formId: props.context.submission.formId, query });
    }
    await helpers.persist(newVal);
    setSearchOpen(false);
  };

  const closeActiveDrawer = (): void => {
    setActiveItem(undefined);
    setActiveOpen(false);
  };

  const startScanning = async (): Promise<void> => {
    try {
      const result = await startScan();
      setScanResult(result);
      setQuery(""); // Don't use old/default query when using barcode
      setSearchOpen(true);
    } catch (e) {
      if (e instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      throw e;
    }
  };

  const openSearch = (): void => {
    let initialQuery = props.field.properties.default_value ?? "";
    if (props.field.properties.remember_search && !isEmpty(rememberedQuery)) {
      initialQuery = first(rememberedQuery)?.query ?? initialQuery;
    }
    setQuery(initialQuery);
    setSearchOpen(true);
  };

  const buttonLabel = !isDisabled ? t("SEARCH_SELECT_ITEM") : t("NO_ITEM_SELECTED");

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("SEARCH_FIELD")}`}>
      <Label
        htmlFor={field.props.name}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        showClearBtn={!isDisabled && !!field.result?.rawValue}
        onClear={() => helpers.persist()}
        clearLabel={t("CLEAR")}
      />
      {field.result?.rawValue ? (
        <>
          <WidgetContentButton
            singleLine
            items={[
              {
                label: t("VIEW"),
                onClick: (): void => {
                  setActiveItem(field.result?.rawValue);
                  setActiveOpen(true);
                },
              },
              ...(!isDisabled && hasDatasource ? [{ label: t("EDIT"), onClick: () => setSearchOpen(true) }] : []),
            ]}
          >
            {getSearchEntryHumanText(field.result?.rawValue.data, enabledEntryFields)}
          </WidgetContentButton>
          <DefinitionList
            className="mt-1"
            cutOffLength={4}
            definitions={enabledEntryFields
              .filter((key) => field.result?.rawValue?.data[key])
              .map((key) => ({
                term: startCase(key),
                description: <SearchValue value={field.result?.rawValue?.data[key]} />,
              }))}
            showMoreLabel={t("SHOW_MORE_BUTTON")}
            showLessLabel={t("SHOW_LESS_BUTTON")}
          />
        </>
      ) : (
        <div className="flex space-x-2">
          <IconAndTextButton
            disabled={isDisabled || !hasDatasource || isFetching}
            block
            icon="SearchIcon"
            label={buttonLabel}
            onClick={() => openSearch()}
          />
          {props.field.properties.allow_barcode && (
            <IconButton
              aria-label={t("OPEN_BARCODE_SCANNER")}
              icon="QrcodeIcon"
              onClick={startScanning}
              disabled={!isScanSupported || !hasDatasource}
              data-testid="barcode-button"
              loading={isScannerInstalling}
            />
          )}
        </div>
      )}
      {field.props.errorMessage && <Feedback status="error" message={field.props.errorMessage} />}
      {/* Data Source List */}
      {isSearchOpen && (
        <SearchDrawer
          isOpen={isSearchOpen}
          setOpen={setSearchOpen}
          submissionId={props.context.submission.id}
          query={query}
          setQuery={setQuery}
          props={props.field}
          enabledEntryFields={enabledEntryFields}
          entryId={props.entry?.id}
          onChange={handleChange}
          scanResult={scanResult}
          setScanResult={setScanResult}
        />
      )}
      {/* Data Source Detail */}
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getSearchEntryHumanText(activeItem ? activeItem.data : {}, enabledEntryFields),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => closeActiveDrawer(),
          },
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("CLOSE"),
            onClick: () => closeActiveDrawer(),
          },
        }}
        onClose={() => closeActiveDrawer()}
      >
        {activeItem &&
          activeItem.data &&
          Object.entries(activeItem.data)
            .filter(([key]) => enabledEntryFields.includes(key))
            .map(([key, value]) => (
              <div key={key} className="flex items-center justify-between space-x-2 border-b border-gray-200 py-3">
                <Text color="medium">{startCase(key)}</Text>
                <Text>
                  <SearchValue value={value} />
                </Text>
              </div>
            ))}
      </Drawer>
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </article>
  );
};

WidgetSearch.defaultValue = (_field, defaultMeta): WidgetResult<DataSourceEntry> => ({
  type: "object",
  meta: {
    widget: "search",
    ...defaultMeta,
  },
});

WidgetSearch.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return undefined;
};

export default WidgetSearch;
