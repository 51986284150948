import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Img from "../../Img";
import Pin from "./Pin";
import { getLocation } from "../../../utils/pinUtil";
import { ControlledField } from "../../../hooks/useWidget";
import { WidgetResult } from "../../../types/Field";
import { FileResult } from "../../../types/Widget";
import { IconButton } from "../../../storybook/components/IconButton/IconButton";
import { Spinner } from "../../../storybook/components/Spinner/Spinner";
import { colors } from "../../../storybook/colors";

interface PinPreviewImageProps {
  imgUrl?: string;
  field: ControlledField<WidgetResult<FileResult>>;
  isDisabled: boolean;
  windowSize: { width: number; height: number };
  onClick: () => void;
  isUploadingPhoto: boolean;
  percentageUploaded: number;
  showCompletedMessage: boolean;
  onUploadRetry: () => void;
}

const fallbackImageClassName = "fallback-image";

const PinPreviewImage: FC<PinPreviewImageProps> = ({
  imgUrl,
  field,
  isDisabled,
  windowSize,
  onClick,
  isUploadingPhoto,
  percentageUploaded,
  showCompletedMessage,
  onUploadRetry,
}) => {
  const { t } = useTranslation();
  const [img, setImg] = useState<HTMLImageElement>();
  const imgRef = useCallback((ref: HTMLImageElement) => ref && setImg(ref), []);
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });

  const uploadStatus = field.result?.meta?.uploadStatus;

  const isFallback = !img || img.classList.contains(fallbackImageClassName);

  return (
    <div className="relative">
      <button
        className="relative z-10 block w-full rounded-lg border-2 bg-gray-50 outline-none hover:bg-gray-100 focus-visible:ring disabled:cursor-not-allowed"
        onClick={() => onClick()}
        disabled={isDisabled}
      >
        {!isUploadingPhoto ? (
          <Img
            className="z-10 size-full max-h-72 object-contain"
            src={imgUrl}
            ref={imgRef}
            fallbackClassName={fallbackImageClassName}
            onLoad={() =>
              setOriginalDimensions({
                width: img?.naturalWidth ?? 0,
                height: img?.naturalHeight ?? 0,
              })
            }
            alt={t("PIN_IMAGE_PREVIEW")}
          />
        ) : (
          <div className="relative flex h-40 items-center justify-center">
            <Spinner className="size-8" />
          </div>
        )}
        {field.result?.entries &&
          windowSize &&
          !isFallback &&
          field.result?.entries
            .filter((entry) => !entry.deleted)
            .map((pin, index) => (
              <Pin
                key={index}
                location={getLocation(img, pin.meta.location, originalDimensions, 1, true)}
                number={pin.meta.order}
                icon={pin.meta.icon}
              />
            ))}
      </button>

      <div className="absolute right-4 top-4 z-10 flex space-x-5">
        {uploadStatus === "uploaded" && !isDisabled && (
          <IconButton aria-label={t("OPEN_PIN_PLACEMENT_DRAWER")} icon="PlusIcon" onClick={() => onClick()} />
        )}

        {uploadStatus !== "uploaded" && !isUploadingPhoto && (
          <IconButton
            aria-label={t("UPLOAD_FAILED")}
            icon="ExclamationCircleIcon"
            iconColor="destructive"
            onClick={(e) => {
              e.stopPropagation();
              onUploadRetry();
            }}
          />
        )}
      </div>

      {(showCompletedMessage || isUploadingPhoto) && (
        <div
          data-testid="upload-bar"
          className="absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg"
          style={{
            background: `linear-gradient(to right, ${colors.green["700"]} ${percentageUploaded}%, ${colors.gray["200"]} ${percentageUploaded}% 100%)`,
          }}
        />
      )}

      {uploadStatus === "failed" && <div className="absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg bg-red-700" />}
    </div>
  );
};

export default PinPreviewImage;
