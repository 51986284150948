import React, { FC, MutableRefObject, PropsWithChildren } from "react";
import classNames from "classnames";
import { AnimatePresence, cubicBezier, domAnimation, LazyMotion, m } from "framer-motion";
import { Dialog } from "@headlessui/react";
import DrawerHeader, { DrawerHeaderProps } from "./DrawerHeader";
import DrawerFooter, { DrawerFooterProps } from "./DrawerFooter";

export interface DrawerProps {
  className?: string;
  header: DrawerHeaderProps;
  footer?: DrawerFooterProps;
  open: boolean;
  initialFocus?: MutableRefObject<any>;
  onClose: () => void;
  autoHeight?: boolean;
  contentPadding?: boolean;
  scrollLock?: boolean;
}

const transition = {
  type: "tween",
  ease: cubicBezier(0.42, 0, 0.58, 1),
  duration: 0.3,
};

export const Drawer: FC<PropsWithChildren<DrawerProps>> = ({
  children,
  className,
  header,
  footer,
  open,
  initialFocus,
  onClose,
  autoHeight,
  contentPadding = true,
  scrollLock = false,
}) => (
  <LazyMotion features={domAnimation}>
    <AnimatePresence>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          initialFocus={initialFocus}
          aria-label={header.kind !== "freestyle" ? header.title : ""}
        >
          <m.div
            className="fixed inset-0 z-50 bg-gray-500/75"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={transition}
            onClick={() => onClose()}
          />
          <m.div
            className={classNames(
              "pointer-events-none fixed z-50 pt-12",
              { "inset-x-0 bottom-0 max-h-screen": autoHeight },
              { "inset-0": !autoHeight },
            )}
            animate={{ translateY: "0%" }}
            transition={transition}
            initial={{ translateY: "100%" }}
            exit={{ translateY: "100%" }}
          >
            <div
              className={classNames(
                "pointer-events-auto mx-auto flex w-full max-w-lg flex-col divide-y divide-gray-200 rounded-t-2xl bg-white",
                { "h-[calc(100vh-48px)] max-h-full": !autoHeight },
                { "max-h-[calc(100vh-48px)]": autoHeight },
              )}
            >
              <DrawerHeader {...header} />
              <div
                className={classNames(
                  "size-full max-h-screen max-w-lg",
                  {
                    "px-6": contentPadding,
                    "overflow-hidden": scrollLock, // Prevent scrolling/moving when this is a parent drawer, so it will NEVER get removed from the DOM by react-viewport-list.
                    "overflow-y-auto": !scrollLock,
                  },
                  className,
                )}
              >
                {children}
              </div>
              {footer && <DrawerFooter className="border border-brand-500" {...footer} />}
            </div>
          </m.div>
        </Dialog>
      )}
    </AnimatePresence>
  </LazyMotion>
);
