import { ChangeEvent, CSSProperties, FC, FocusEvent, PointerEvent, Ref } from "react";
import classNames from "classnames";
import { Label } from "../Label/Label";
import { Text } from "../Text/Text";
import { Feedback } from "../Feedback/Feedback";

const withinThreshold = (valueToValidate: number, threshold: number): boolean => Math.abs(valueToValidate) <= threshold;

export interface RangeInputProps {
  name: string;
  label: string;
  inputMode?: "numeric" | "decimal";
  inputRef?: Ref<any>;
  value?: number | string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  onFocus?: (e: FocusEvent<any>) => void;
  onPointerUp?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  showClearBtn?: boolean;
  clearLabel?: string;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
}

export const RangeInput: FC<RangeInputProps> = ({
  name,
  label,
  inputMode,
  inputRef,
  value,
  description,
  placeholder,
  required = false,
  errorMessage,
  disabled = false,
  onChange = (): void => {},
  onBlur = (): void => {},
  onFocus = (): void => {},
  onPointerUp = (): void => {},
  onClear = (): void => {},
  showClearBtn = false,
  clearLabel,
  min = 0,
  max = 100,
  step = 1,
  className,
  ...props
}) => {
  const inputClasses = classNames(
    "block w-full rounded outline-none transition",
    disabled && "cursor-not-allowed",

    {
      "range-input mt-7 h-0.5 appearance-none border-2 p-0 before:absolute before:mt-1 before:text-gray-400 before:content-[attr(min)] after:absolute after:right-0 after:mt-1 after:text-gray-400 after:content-[attr(max)]":
        true,
      "cursor-pointer focus-visible:ring active:text-brand-500": !disabled,
      "border-red-200 text-red-400": errorMessage && !disabled,
      "text-gray-400": !errorMessage && !value && !disabled,
      "text-brand-400": !errorMessage && value && !disabled,
      "border-gray-200 text-gray-500": disabled && value,
      "border-gray-200 text-gray-300": disabled && !value,
    },
  );

  const measureWidth = (elementName: string): number => {
    const hiddenTextContainer = document.getElementById(elementName);
    if (hiddenTextContainer) {
      return hiddenTextContainer.offsetWidth;
    }
    return 0;
  };

  const hiddenTextContainerName = `${name}hiddenTextContainer`;

  const getRangeValueOffset = (): { progress: number; style: CSSProperties } => {
    if (!value || !max) {
      return { progress: 0, style: { left: "0%" } };
    }
    const thumbWidth = 20;
    const val = typeof value === "number" ? value : Number(value.replace(",", "."));
    const progress = (val - min) / (max - min);
    const offset = thumbWidth / 2 - thumbWidth * progress;
    const isOutOfRange = progress < 0 || progress > 1;
    const style = {
      left: `calc(${progress * 100}% + ${offset}px)`,
      display: isOutOfRange ? "none" : "block",
    };
    return { progress, style };
  };

  type RangeValueTranslation =
    | "-translate-x-1/4"
    | "-translate-x-1"
    | "-translate-x-3/4"
    | "-translate-x-full"
    | "-translate-x-1/2";
  const getRangeValueTranslation = (): RangeValueTranslation => {
    const { progress } = getRangeValueOffset();

    const totalTextWidth = measureWidth(hiddenTextContainerName);
    const sliderWidth = measureWidth(name);

    const marginLeft = progress * sliderWidth;
    const marginRight = sliderWidth - marginLeft;

    const maxTranslationLeft = marginLeft - totalTextWidth / 2;
    const maxTranslationRight = marginRight - totalTextWidth / 2;

    const fullTranslationThreshold = totalTextWidth / 2;
    const quarterTranslationThreshold = totalTextWidth / 4;

    if (withinThreshold(marginLeft, fullTranslationThreshold)) {
      return withinThreshold(maxTranslationLeft, quarterTranslationThreshold) ? "-translate-x-1/4" : "-translate-x-1";
    }

    if (withinThreshold(marginRight, fullTranslationThreshold)) {
      return withinThreshold(maxTranslationRight, quarterTranslationThreshold)
        ? "-translate-x-3/4"
        : "-translate-x-full";
    }

    return "-translate-x-1/2";
  };

  const handlePointerUp = (e: PointerEvent): void => {
    onPointerUp(e as unknown as ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className={classNames("relative", className)} {...props}>
      <div id={hiddenTextContainerName} className="invisible absolute left-0 top-0">
        {value}
      </div>
      <Label
        label={label}
        required={required}
        showClearBtn={showClearBtn}
        onClear={onClear}
        clearLabel={clearLabel}
        htmlFor={name}
      />
      <div className="group flex">
        <input
          name={name}
          id={name}
          className={inputClasses}
          type="range"
          inputMode={inputMode}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onPointerUp={handlePointerUp}
          autoComplete="off"
          ref={inputRef}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        />
      </div>
      <div className="-mx-2.5 h-12">
        <div className={`absolute -mt-8 ${getRangeValueTranslation()}`} style={getRangeValueOffset().style}>
          <Text weight="semibold">{value}</Text>
        </div>
        {!disabled && (
          <input
            name={name}
            id={name}
            tabIndex={-1}
            className="range-thumb mt-2 w-full appearance-none bg-transparent outline-none"
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            onPointerUp={handlePointerUp}
          />
        )}
      </div>
      {errorMessage && <Feedback className={!disabled ? "mt-5" : ""} status="error" message={errorMessage} />}
      {description && !errorMessage && (
        <Text className="mt-5" size="sm" color="medium">
          {description}
        </Text>
      )}
    </div>
  );
};
