import { useState } from "react";
import { sortBy } from "lodash-es";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { normalize } from "../../utils/stringUtil";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { RadioList } from "../../storybook/components/RadioList/RadioList";
import { CheckboxList } from "../../storybook/components/CheckboxList/CheckboxList";

interface WidgetLookupOption {
  id: string; // Persisted value
  name: string; // Display value
}

export interface WidgetLookupProperties {
  required: boolean;
  label_text: string;
  options: WidgetLookupOption[];
  is_multiple?: boolean;
  default_value?: string | string[];
  expand?: boolean;
  sort_alphabetically?: boolean;
}

const WidgetLookup: Widget<WidgetLookupProperties, WidgetResult<string[] | string>> = (props) => {
  const { t } = useTranslation();
  const isMultiple = props.field.properties.is_multiple;
  const isSorted = props.field.properties.sort_alphabetically;
  const items = props.field.properties.options
    .filter((x) => normalize(x.name))
    .map((x) => ({
      value: x.id,
      label: x.name,
    }));

  const lookupOptions = isSorted ? sortBy(items, [(item): string => item.label.toLowerCase()]) : items;
  const [expanded, setExpanded] = useState<boolean>(!!props.field.properties.expand);

  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetLookup.validate,
    {
      onChange: "none",
      onBlur: "none",
      valueFormat: (value): string[] | string | undefined => {
        if (value?.rawValue === undefined) {
          return undefined;
        }
        if (!value?.rawValue && isMultiple) {
          return [];
        }
        if (typeof value?.rawValue === "string" && isMultiple) {
          return [value.rawValue];
        }
        return value?.rawValue;
      },
    },
    props.fieldRx,
    props.entry,
  );

  const clearValue = async (): Promise<void> => {
    const clearedValue = props.field.properties.is_multiple ? [] : undefined;
    await helpers.persist(clearedValue);
  };

  const buttonLabel = !isDisabled ? t("SEARCH_SELECT_ITEM") : t("NO_ITEM_SELECTED");

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const componentProps = {
    inputRef: field.inputRef,
    label: props.field.properties.label_text,
    ariaLabel: expanded ? t("COLLAPSE") : t("EXPAND"),
    placeholder: buttonLabel,
    disabled: isDisabled,
    options: lookupOptions,
    expanded,
    onBtnClick: () => setExpanded((prevState) => !prevState),
    required: props.field.properties.required,
    clearLabel: t("CLEAR"),
    onClear: clearValue,
  };

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("LOOKUP_FIELD")}`}>
      {isMultiple ? (
        <CheckboxList
          {...field.props}
          {...componentProps}
          onChange={async (value) => {
            await helpers.persist(value);
            field.controller.field.onBlur();
          }}
        />
      ) : (
        <RadioList
          {...field.props}
          {...componentProps}
          onChange={async (value) => {
            await helpers.persist(value);
            field.controller.field.onBlur();
            setExpanded(false);
          }}
        />
      )}
    </article>
  );
};

WidgetLookup.defaultValue = (field, defaultMeta: any): WidgetResult<string[] | string> => {
  let rawValue: string | string[] | undefined;
  if (!field.properties.default_value) {
    rawValue = field.properties.is_multiple ? [] : undefined;
  } else if (!Array.isArray(field.properties.default_value)) {
    rawValue = field.properties.is_multiple ? [field.properties.default_value] : field.properties.default_value;
  } else if (!field.properties.is_multiple) {
    rawValue = field.properties.default_value.length > 0 ? field.properties.default_value[0] : undefined;
  } else {
    rawValue = field.properties.default_value;
  }

  return {
    type: "string",
    rawValue,
    meta: {
      widget: "lookup",
      ...defaultMeta,
    },
  };
};

WidgetLookup.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && (!val || val.length === 0)) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};
export default WidgetLookup;
