import { FC, MouseEventHandler, useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { CameraSource, Photo } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { FileResult, Widget } from "../../types/Widget";
import { UploadStatus, WidgetResult } from "../../types/Field";
import useFileHandler from "../../hooks/useFileHandler";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { validateUpload } from "../../utils/validationUtil";
import { PhotoQuality, PhotoQualitySetting } from "../../types/Photo";
import useDrawer from "../../hooks/useDrawer";
import Img from "../Img";
import WidgetError from "./WidgetError";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import logger from "../../utils/logger";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import usePhotoHandler from "../../hooks/usePhotoHandler";
import FileFeedback from "./file/FileFeedback";
import { noop } from "../../utils/noop";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Label } from "../../storybook/components/Label/Label";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { DropdownMenu } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Modal } from "../../storybook/components/Modal/Modal";
import FileUploadFailedModal from "../FileUploadFailedModal";
import { useFocussedField } from "../../context/FocusContext";
import { colors } from "../../storybook/colors";

export interface WidgetPhotoProperties {
  date_name: string;
  label_text: string;
  quality: PhotoQuality;
  filename: string;
  allow_library: boolean;
  save_to_gallery: boolean;
  required: boolean;
}

const WidgetPhoto: Widget<WidgetPhotoProperties, WidgetResult<FileResult>> = (props) => {
  const { t } = useTranslation();
  const [showPhoto, setShowPhoto] = useDrawer(props.field.uid);
  const [imgUrl, setImgUrl] = useState<string>();
  const [showDeletePhotoModal, setDeletePhotoModal] = useState(false);
  const [showFailedUploadModal, setFailedUploadModal] = useState(false);
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { getFileUrl } = useFileHandler();
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const { addTask } = useFocussedField();
  const { field, helpers, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetPhoto.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  const { addPhoto, current, isUploading, isUploadingPhoto, showCompletedMessage } = usePhotoHandler(
    props.context.submission.id,
  );

  useEffect(() => {
    const loadImgUrl = async (): Promise<void> => {
      if (!field.result?.rawValue?.id) {
        setImgUrl(undefined);
        return;
      }
      const fileUrl = await getFileUrl(field.result?.rawValue, props.context.submission.id);
      setImgUrl(Capacitor.convertFileSrc(fileUrl!));
    };
    loadImgUrl().catch((e) => logger.error("Could not load image", e));
  }, [field.result?.rawValue?.remoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const result = await addPhoto(
        {
          source,
          options: {
            ...PhotoQualitySetting[props.field.properties.quality],
            saveToGallery: props.field.properties.save_to_gallery,
          },
        },
        async (photo: Photo) => {
          setImgUrl(photo.webPath);
          setPercentageUploaded(0);
        },
        (uploadPercentage: number) => setPercentageUploaded(uploadPercentage),
      );
      await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus, {
        shouldWait: false,
      });
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      if (error.message !== "USER_CANCELLED") {
        logger.error("Failed to add photo", error);
      }
    }
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const getAddPhotoButton = (onClick: MouseEventHandler<HTMLButtonElement> = noop): JSX.Element => (
    <IconAndTextButton
      disabled={isDisabled}
      label={!isDisabled ? t("ADD_PHOTO") : t("NO_PHOTO_ADDED")}
      icon="CameraIcon"
      onClick={onClick}
      block
    />
  );

  const isInvalidExtensionStatus = field?.result?.meta.uploadStatus === "invalid_extension";

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("PHOTO_FIELD")}`}>
      <Label
        htmlFor={field.props.name}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        showClearBtn={!isNil(field.result?.rawValue) && !isDisabled && !isInvalidExtensionStatus}
        clearLabel={t("CLEAR")}
        onClear={() => {
          setDeletePhotoModal(true);
        }}
      />
      <ViewPhotoDrawer
        open={showPhoto}
        title={props.field.properties.label_text}
        onClose={() => setShowPhoto(false)}
        imgUrl={imgUrl}
      />
      {imgUrl ? (
        <PhotoPreview
          imgUrl={imgUrl}
          onClick={() => setShowPhoto(true)}
          isUploading={isUploadingPhoto}
          uploadStatus={field.result?.meta.uploadStatus}
          onUploadRetry={() => setFailedUploadModal(true)}
          showCompletedMessage={showCompletedMessage}
          percentageUploaded={percentageUploaded}
          readonly={isDisabled}
        />
      ) : props.field.properties.allow_library ? (
        <DropdownMenu
          className="w-full"
          menuButton={() => getAddPhotoButton()}
          disabled={isDisabled}
          items={[
            {
              label: t("CAPTURE_PHOTO"),
              onClick: () => addTask(handleAddPhoto(CameraSource.Camera)),
            },
            {
              label: t("BROWSE_PHOTOS"),
              onClick: () => addTask(handleAddPhoto(CameraSource.Photos)),
            },
          ]}
        />
      ) : (
        getAddPhotoButton(() => addTask(handleAddPhoto(CameraSource.Camera)))
      )}
      {isUploading && current?.id === field.result?.rawValue?.id ? (
        <Feedback className="mt-2" status="warning" message={t("RETRY_UPLOAD_MESSAGE")} />
      ) : (
        <FileFeedback field={field} />
      )}
      <DeletePhotoModal
        open={showDeletePhotoModal}
        onClose={() => setDeletePhotoModal(false)}
        onDelete={async () => {
          await helpers.persistWithUploadStatus();
          setImgUrl(undefined);
          setShowPhoto(false);
          setDeletePhotoModal(false);
        }}
      />

      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}

      {showFailedUploadModal && (
        <FileUploadFailedModal
          show={showFailedUploadModal}
          onClose={() => setFailedUploadModal(false)}
          widgetResult={field.result}
          onRetry={async (result) => {
            await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus);
          }}
        />
      )}
    </article>
  );
};

WidgetPhoto.defaultValue = (_field, defaultMeta: any): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "photo",
    ...defaultMeta,
  },
});

WidgetPhoto.validate = (val, properties, t, meta): string | undefined => {
  if (properties.required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return validateUpload(val, meta.uploadStatus, t);
};

type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeletePhotoModal: FC<DeleteModalProps> = ({ open, onClose, onDelete }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("DELETE_PHOTO")}
      content={{
        kind: "message",
        message: t("DELETE_PHOTO_CONFIRM"),
      }}
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: onClose,
        },
        {
          label: t("DELETE"),
          variant: "destructive",
          onClick: onDelete,
        },
      ]}
    />
  );
};

type ViewPhotoDrawerProps = {
  imgUrl: string | undefined;
  onClose: () => void;
  title: string;
  open: boolean;
};

const ViewPhotoDrawer: FC<ViewPhotoDrawerProps> = ({ imgUrl, onClose, title, open }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title,
        button: { kind: "icon", icon: "XIcon", onClick: onClose },
      }}
      className="bg-gray-100"
      onClose={onClose}
      contentPadding={false}
    >
      <TransformWrapper centerOnInit>
        <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
          <Img
            src={imgUrl}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "1000px",
              height: "1000px",
              objectFit: "scale-down",
            }}
            alt={t("PHOTO")}
          />
        </TransformComponent>
      </TransformWrapper>
    </Drawer>
  );
};

type PhotoPreviewProps = {
  imgUrl: string;
  onClick: () => void;
  onUploadRetry: () => void;
  percentageUploaded: number;
  uploadStatus: UploadStatus | undefined;
  showCompletedMessage: boolean;
  isUploading: boolean;
  readonly: boolean;
};

const PhotoPreview: FC<PhotoPreviewProps> = ({
  imgUrl,
  onClick,
  onUploadRetry,
  percentageUploaded,
  uploadStatus,
  showCompletedMessage,
  isUploading,
  readonly,
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <button
        aria-label={t("VIEW_ENTIRE_IMAGE")}
        className="relative z-10 block w-full rounded-lg border-2 bg-white outline-none focus-visible:ring"
        onClick={onClick}
      >
        {isUploading && (
          <div className="relative flex h-40 items-center justify-center">
            <Spinner className="size-8" />
          </div>
        )}

        {!isUploading && <Img src={imgUrl} alt={t("PHOTO")} className="relative z-10 mx-auto h-40 object-cover" />}
      </button>

      <div className="absolute right-4 top-4 z-10 flex space-x-5">
        {uploadStatus === "uploaded" && (
          <IconButton aria-label={t("VIEW_ENTIRE_IMAGE")} icon="EyeIcon" onClick={onClick} />
        )}

        {uploadStatus !== "uploaded" && !isUploading && !readonly && (
          <IconButton
            aria-label={t("UPLOAD_FAILED")}
            icon="ExclamationCircleIcon"
            iconColor="destructive"
            onClick={(e) => {
              e.stopPropagation();
              onUploadRetry();
            }}
          />
        )}
      </div>

      {(uploadStatus === "uploading" || showCompletedMessage || isUploading) && (
        <div
          data-testid="upload-bar"
          className="absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg"
          style={{
            background: `linear-gradient(to right, ${colors.green["700"]} ${percentageUploaded}%, ${colors.gray["200"]} ${percentageUploaded}% 100%)`,
          }}
        />
      )}

      {uploadStatus === "failed" && !readonly && (
        <div className="absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg bg-red-700" />
      )}
    </div>
  );
};

export default WidgetPhoto;
