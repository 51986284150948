import { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import { Icon } from "../Icon/Icon";
import { DropdownMenu, MenuItem } from "../DropdownMenu/DropdownMenu";

export interface WidgetContentButtonProps {
  className?: string;
  items?: MenuItem[];
  appearance?: "default" | "danger";
  singleLine?: boolean;
  disabled?: boolean;
}

export const WidgetContentButton: FC<PropsWithChildren<WidgetContentButtonProps>> = ({
  items = [],
  appearance = "default",
  className,
  disabled = false,
  singleLine = false,
  children,
}) => {
  const classes = classNames("w-full", className);
  const getMenuBtnClasses = (open: boolean): string =>
    classNames(
      "group relative flex w-full items-start justify-between rounded-lg py-2 pl-4 pr-5 text-left outline-none ring-inset",
      disabled && "cursor-not-allowed bg-gray-900/10 text-gray-700",
      !disabled && {
        "focus-visible:ring active:text-white active:!bg-brand-600 active:ring-brand-600 cursor-pointer":
          items.length > 0,
        "text-white bg-brand-600": open,
        "text-gray-700 bg-gray-100": !open,
        "pointer:hover:bg-gray-200": !open && items.length > 0,
        "ring-1 bg-red-100 ring-red-500 group-hover:ring-red-500 focus-visible:ring-blue-500":
          appearance === "danger" && !open,
      },
    );

  return (
    <DropdownMenu
      className={classes}
      items={items}
      disabled={disabled || items.length === 0}
      menuButton={(open) => (
        <button disabled={disabled || items.length === 0} type="button" className={getMenuBtnClasses(open)}>
          <span className={classNames("min-w-0 break-words leading-loose", { truncate: singleLine })}>{children}</span>
          {items.length > 0 && <Icon name="MenuAlt3Icon" className="ml-2 mt-1 shrink-0" />}
        </button>
      )}
      menuAlignment="end"
    />
  );
};
