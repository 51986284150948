import { useEffect } from "react";
import { RxCollection } from "rxdb";
import { isEqual } from "lodash-es";
import { AbstractForm, FormField } from "../types/FormVersion";
import { Field } from "../types/Field";
import { getFieldFromFormVersions } from "../utils/formUtil";
import { WidgetTimeDifferenceProps } from "../components/widgets/WidgetTimeDifference";
import { getTimeDifferenceResult } from "../utils/timeUtil";

export type FormTimeDiffContext = {
  field: Field;
  formField: FormField<WidgetTimeDifferenceProps>;
};

const useFormTimeDifference = (
  enabled: boolean,
  formVersions: AbstractForm[],
  fields: Field[],
  deviceId: string,
  readOnly: boolean = false,
  fieldsCollection?: RxCollection<Field>,
): void => {
  useEffect(() => {
    if (!enabled || !formVersions || !fieldsCollection || !fields || fields.length === 0) {
      return;
    }
    const timeDifferenceFields = fields?.filter((x) => x.widget === "timeDifference");
    const updatedTimeDifference = timeDifferenceFields
      .map(
        (timeDifference): FormTimeDiffContext => ({
          field: timeDifference,
          formField: getFieldFromFormVersions(
            formVersions,
            timeDifference.formFieldId,
          ) as FormField<WidgetTimeDifferenceProps>,
        }),
      )
      .map(({ field, formField }) => getTimeDifferenceResult(formField, field, fields));

    updatedTimeDifference.forEach((updatedField) =>
      fieldsCollection
        .findOne(updatedField.field.id)
        .exec()
        .then((field) => {
          const data = field?.data as number;
          if (isEqual(data, updatedField.data)) {
            return;
          }
          field?.incrementalPatch({
            data: updatedField.data,
            updatedAt: new Date().toISOString(),
            deviceId,
          });
        }),
    );
  }, [fields, formVersions, enabled, fieldsCollection, readOnly, deviceId]);
};

export default useFormTimeDifference;
