import { useCallback, useMemo } from "react";
import { RxCollection } from "rxdb";
import { JSONPath } from "jsonpath-plus";
import { useRxData } from "rxdb-hooks";
import { useDebounceValue } from "usehooks-ts";
import { AbstractForm, FieldProperties } from "../types/FormVersion";
import { Field } from "../types/Field";
import useFormCalculation from "./useFormCalculation";
import useFormRules from "./useFormRules";
import useFormTimeDifference from "./useFormTimeDifference";
import useFormDescription from "./useFormDescription";
import { SubmissionDocument } from "../utils/databaseUtil";
import useDeviceInfo from "./useDeviceInfo";

const useFormLogic = (
  formVersion: AbstractForm,
  fieldProperties: FieldProperties,
  submission: SubmissionDocument,
  readOnly: boolean = false,
  fieldsCollection?: RxCollection<Field>,
  enabled?: boolean,
): void => {
  const { id: deviceId } = useDeviceInfo();
  const formVersions: AbstractForm[] = useMemo(
    () =>
      JSONPath({
        path: "$..rules^",
        json: formVersion,
      }),
    [formVersion],
  );

  const queryConstructor = useCallback(
    (collection: RxCollection<Field>) => collection.find().where("submissionId").eq(submission.id),
    [submission.id],
  );
  const { result, isFetching } = useRxData<Field>("fields", queryConstructor);
  const [fields] = useDebounceValue(result, 50); // Throttle the amount of times this logic can run

  const isEnabled = useMemo(() => !!enabled && !isFetching && !!deviceId, [enabled, isFetching, deviceId]);

  useFormDescription(submission, formVersion, fields);
  useFormTimeDifference(isEnabled, formVersions, fields, deviceId, readOnly, fieldsCollection);
  useFormCalculation(isEnabled, formVersions, fields, deviceId, readOnly, fieldsCollection);
  useFormRules(isEnabled, formVersion, fieldProperties, fields, deviceId, submission, readOnly, fieldsCollection);
};

export default useFormLogic;
