import { useEffect } from "react";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { WidgetResult } from "../../types/Field";
import { Widget } from "../../types/Widget";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { getCurrentWeekNumber } from "../../utils/weekNumberUtil";
import WidgetError from "./WidgetError";
import logger from "../../utils/logger";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";

export type WidgetWeekNumberProperties = {
  label_text: string;
};

const WidgetWeekNumber: Widget<WidgetWeekNumberProperties, WidgetResult<number>> = (props) => {
  const { helpers, isDisabled, field } = useWidget(
    props.context,
    props.field,
    WidgetWeekNumber.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  useEffect(() => {
    if (!field.result?.rawValue && !isDisabled) {
      helpers
        .persist(getCurrentWeekNumber(), { humanEdit: false })
        .catch((e) => logger.error("Couldn't persist weeknumber", e));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <NumberInput
      name={props.field.uid}
      label={props.field.properties.label_text}
      inputMode="numeric"
      disabled
      value={field.result?.rawValue ?? 0}
      onChange={() => {}}
      onBlur={() => {}}
    />
  );
};

WidgetWeekNumber.defaultValue = (field, defaultMeta: any): WidgetResult<number> => ({
  type: "number",
  rawValue: undefined,
  meta: {
    widget: "weekNumber",
    ...defaultMeta,
  },
});

WidgetWeekNumber.validate = (): undefined => undefined;

export default WidgetWeekNumber;
