import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormField } from "../../types/FormVersion";
import { WidgetResult } from "../../types/Field";
import { Message } from "../../storybook/components/Message/Message";

const WidgetError: FC<{ field: FormField<any>; widgetResult?: WidgetResult<unknown> }> = ({ field, widgetResult }) => {
  const { t } = useTranslation();

  return widgetResult?.meta?.widget === "incompatible" ? (
    <Message
      className="mt-4"
      type="warning"
      title={t("WIDGET_INCOMPATIBLE_TITLE", { name: field.widget })}
      text={t("WIDGET_INCOMPATIBLE_DESCRIPTION")}
    />
  ) : (
    <Message
      className="mt-4"
      type="warning"
      title={t("WIDGET_UNAVAILABLE_TITLE", { name: field.widget })}
      text={t("WIDGET_UNAVAILABLE_DESCRIPTION")}
    />
  );
};

export default WidgetError;
