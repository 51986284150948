import { useMemo, useState } from "react";
import { clamp, round } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDebounceValue } from "usehooks-ts";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import { Currency } from "../../types/Currency";
import WidgetHidden from "./WidgetHidden";
import { DataSourceEntry } from "../../types/Datasource";
import useDrawer from "../../hooks/useDrawer";
import { asCurrency, getCatalogueItemTitle, getEnabledFields } from "../../utils/datasourceUtil";
import CatalogueSearchContent from "./search/CatalogueSearchContent";
import CatalogueListItem from "./search/CatalogueListItem";
import useDatasource from "../../hooks/useDatasource";
import CatalogueItemContent from "./search/CatalogueItemContent";
import WidgetError from "./WidgetError";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import logger from "../../utils/logger";
import { Label } from "../../storybook/components/Label/Label";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { NumberInput } from "../../storybook/components/NumberInput/NumberInput";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";
import { SearchField } from "../../storybook/components/SearchField/SearchField";
import { Modal } from "../../storybook/components/Modal/Modal";
import { toLocaleString } from "../../utils/stringUtil";
import CataloguePriceList from "./search/CataloguePriceList";
import { getFormValue } from "../../utils/numberUtil";

export interface WidgetCatalogueProperties {
  required: boolean;
  label_text: string;
  default_value?: string;
  use_barcode_scanner?: boolean;
  allow_remarks?: boolean;
  show_prices?: boolean;
  show_vat?: boolean;
  vat_rate?: number;
  currency?: Currency;
  precision?: number;
  data_source_configuration: {
    id: string;
    mapping: Record<string, boolean>;
  };
}

type CatalogueItem = {
  data: Record<string, string>;
  quantity: number;
  vatRate?: number;
  priceExVat?: number;
  priceIncVat?: number;
  vat?: number;
  notes?: string;
};

export type CatalogueResult = {
  items: CatalogueItem[];
  priceExVat: number;
  priceIncVat: number;
  vat: number;
};

type ActivelyEditing =
  | {
      itemId: string;
      value?: number;
    }
  | undefined;

const SEARCH_DEBOUNCE = 300;

const MAX_QUANTITY = 999_999; // Arbitrary limit, could be increased or decreased if necessary

const atMaxQuantity = (item: CatalogueItem): boolean => item.quantity >= MAX_QUANTITY;

const clampQuantity = (quantity?: number): number | undefined => {
  if (!quantity) {
    return undefined;
  }

  return clamp(quantity, 0, MAX_QUANTITY);
};

const WidgetCatalogue: Widget<WidgetCatalogueProperties, WidgetResult<CatalogueResult>> = (props) => {
  const { id: dataSourceId, mapping } = props.field.properties.data_source_configuration;
  const initialQuery = props.field.properties.default_value || "";
  const overallVatRate = props.field.properties.vat_rate || 0;
  const showPrices = props.field.properties.show_prices ?? false;
  const showVat = props.field.properties.show_vat ?? false;
  const precision = props.field.properties.precision || 2;
  const { t } = useTranslation();
  const { helpers, isDisabled, field } = useWidget(
    props.context,
    props.field,
    WidgetCatalogue.validate,
    {
      onChange: "set",
      onBlur: "persist-on-unmount",
      shouldPersist: (value) =>
        !value?.rawValue?.items.find((item) => item.quantity === 0 || !Number.isFinite(item.quantity)),
    },
    props.fieldRx,
    props.entry,
  );
  const [initialized, setInitialized] = useState(false);
  const [query, setQuery] = useState(initialQuery);
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<CatalogueItem | undefined>(undefined);
  const { startScan, isScanSupported, isScannerInstalling } = useBarcodeScanner();
  const { data: datasource, isLoading, isError } = useDatasource(dataSourceId);
  const [remarks, setRemarks] = useState<Map<string, string>>(() =>
    field.result?.rawValue ? new Map(field.result.rawValue.items.map((i) => [i.data.id, i.notes ?? ""])) : new Map(),
  );
  const enabledEntryFields = useMemo(() => getEnabledFields(mapping), [mapping]);
  const [debouncedQuery] = useDebounceValue<string>(query, SEARCH_DEBOUNCE);

  const [isSearchOpen, setIsSearchOpen] = useDrawer(`${props.field.uid}-widget-search`);

  const [activeItem, setActiveItem] = useState<Record<string, string>>();
  const [activeOpen, setActiveOpen] = useDrawer(`${props.field.uid}-catalogue-active`);
  const [activeEditing, setActiveEditing] = useState<ActivelyEditing>(undefined);

  const getRawValue = (): CatalogueResult => field.result?.rawValue || ({} as CatalogueResult);

  const buildTotals = (items: CatalogueItem[]): Omit<CatalogueResult, "items"> => {
    const priceExVat = items.reduce((acc, item) => acc + (item.priceExVat || 0), 0);
    const priceIncVat = items.reduce((acc, item) => acc + (item.priceIncVat || 0), 0);
    return {
      priceExVat: round(priceExVat, precision),
      priceIncVat: round(priceIncVat, precision),
      vat: round(priceIncVat - priceExVat, precision),
    };
  };

  const add = async (entry: DataSourceEntry): Promise<void> => {
    if (isDisabled) {
      return;
    }
    const rawValue = getRawValue();
    const existingItem = rawValue.items && rawValue.items.find((i) => i.data.id === entry.data.id);
    if (existingItem) {
      updateQuantity(existingItem, existingItem.quantity + 1).catch((e) => {
        logger.error("Could not update item quantity", e);
      });
    } else {
      const quantity = 1;
      const vatRate = round(parseFloat(entry.data.vatRate) || overallVatRate, precision);
      const price = parseFloat(entry.data.priceExVat);
      const priceExVat = price ? round(price * quantity, precision) : 0;
      const priceIncVat = price ? round(priceExVat * (1 + vatRate / 100), precision) : 0;
      const vat = price ? round(priceIncVat - priceExVat, precision) : 0;
      const newItem = { data: entry.data, quantity, priceExVat, priceIncVat, vatRate, vat };
      const items = [newItem, ...(rawValue.items || [])];
      const newValue = { ...buildTotals(items), items };
      await helpers.persist(newValue);
    }
    setIsSearchOpen(false);
  };

  const startScanning = async (): Promise<void> => {
    try {
      const result = await startScan();
      const exactIdMatch = datasource?.entries.find((e) => e.data.id === result);
      if (exactIdMatch) {
        await add(exactIdMatch);
      } else {
        openSearch(result);
      }
    } catch (e) {
      if (e instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      throw e;
    }
  };

  const openSearch = (newQuery?: string): void => {
    setIsSearchOpen(true);
    setQuery(newQuery || "");
  };

  const updateQuantity = async (item: CatalogueItem, quantity?: number): Promise<void> => {
    if (!quantity || quantity <= 0) {
      setDeleteItem(item);
      return;
    }

    setActiveEditing(undefined);
    await helpers.persist(getValue(getRawValue, item, precision, buildTotals, clampQuantity(quantity)));
  };

  const setQuantity = async (item: CatalogueItem, quantity?: number): Promise<void> => {
    const clampedQuantity = clampQuantity(quantity);
    setActiveEditing({ itemId: item.data.id, value: clampedQuantity });
    helpers.setValue(getValue(getRawValue, item, precision, buildTotals, clampedQuantity));
  };

  const getDisplayQuantity = (item: CatalogueItem): string | number | undefined => {
    if (isDisabled || activeEditing?.itemId !== item.data.id) {
      return toLocaleString(item.quantity);
    }
    return activeEditing.value;
  };

  const remove = async (item: CatalogueItem): Promise<void> => {
    const rawValue = getRawValue();
    const items = rawValue.items.filter((i) => i.data.id !== item.data.id);
    await helpers.persist({ ...rawValue, ...buildTotals(items), items });
  };

  const onRemarksBlur = (item: CatalogueItem) => async () => {
    const rawValue = getRawValue();
    const items = rawValue.items.map((i) =>
      i.data.id !== item.data.id ? i : { ...item, notes: remarks.get(item.data.id) },
    );
    await helpers.persist({ ...rawValue, items });
  };

  const showRemarks = (item: CatalogueItem): boolean | string | undefined =>
    (props.field.properties.allow_remarks && !isDisabled) || (remarks.get(item.data.id) && isDisabled);

  const buttonLabel = !isDisabled ? t("ADD_ITEM") : t("NO_ITEM_ADDED");

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const showAddBtn =
    (isDisabled && props.context.readOnly && (!field.result?.rawValue || field.result.rawValue.items.length === 0)) ||
    !isDisabled;
  const onDeleteCancel = (): void => {
    const fieldRx = props.fieldRx?.getLatest();
    if (fieldRx) {
      helpers.setValue(fieldRx.data as CatalogueResult);
    }
    setDeleteItem(undefined);
    setActiveEditing(undefined);
  };

  const drawerSubtitle = activeItem && `${t("ARTICLE_NUMBER")}: ${activeItem.id}`;

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("CATALOGUE_FIELD")}`} ref={field.inputRef}>
      <Label label={props.field.properties.label_text} required={props.field.properties.required} />
      {showAddBtn && (
        <div className="flex gap-x-2">
          <IconAndTextButton
            disabled={isDisabled || isLoading || isError}
            block
            icon="PlusIcon"
            label={buttonLabel}
            onClick={() => openSearch()}
          />
          {props.field.properties.use_barcode_scanner && (
            <IconButton
              aria-label={t("OPEN_BARCODE_SCANNER")}
              icon="QrcodeIcon"
              onClick={() => startScanning().catch((e) => logger.error("Could not start scanner", e))}
              disabled={!isScanSupported || isDisabled || isLoading || isError}
              loading={isLoading}
            />
          )}
        </div>
      )}
      {field.result?.rawValue &&
        field.result.rawValue.items.map((item: any) => (
          <CatalogueListItem
            key={item.data.id}
            onClickInfo={() => {
              setActiveItem(item.data);
              setActiveOpen(true);
            }}
            name={item.data.name}
            description={item.data.description}
            thumbnail={item.data.thumbnail ?? item.data.photo}
            priceExVat={asCurrency(
              item.priceExVat || 0,
              props.field.properties.currency,
              props.field.properties.precision,
            )}
            showPrices={showPrices}
          >
            <div className="relative mb-2 flex flex-wrap items-center justify-end gap-x-2 2xs:justify-normal">
              <Label label={t("QUANTITY")} htmlFor="quantity" className="sr-only" />
              <IconButton
                className="shrink-0"
                aria-label={t("DECREASE_QUANTITY")}
                icon="MinusIcon"
                disabled={isDisabled}
                onClick={() =>
                  updateQuantity(item, item.quantity - 1).catch((e) => logger.error("Could not update quantity", e))
                }
              />
              <NumberInput
                name="quantity"
                inputMode="decimal"
                label=""
                min={0}
                max={MAX_QUANTITY}
                className="-mt-1 w-16"
                value={getDisplayQuantity(item)}
                disabled={isDisabled}
                onBlur={(e) => {
                  updateQuantity(item, getFormValue(e)).catch((err) => logger.error("Could not update quantity", err));
                  field.props.onBlur(e);
                }}
                onChange={(e) =>
                  setQuantity(item, e.floatValue).catch((err) => logger.error("Could not update quantity", err))
                }
                showThousandSeparator
                clamp
              />
              <IconButton
                className="shrink-0"
                aria-label={t("INCREASE_QUANTITY")}
                icon="PlusIcon"
                disabled={isDisabled || atMaxQuantity(item)}
                onClick={() =>
                  updateQuantity(item, item.quantity + 1).catch((e) => logger.error("Could not update quantity", e))
                }
              />
              <IconButton
                className="shrink-0"
                variant="transparentMedium"
                aria-label={t("REMOVE_ITEM")}
                icon="TrashIcon"
                disabled={isDisabled}
                onClick={() => setDeleteItem(item)}
              />
            </div>
            {showRemarks(item) && (
              <TextInput
                type="textarea"
                disabled={isDisabled}
                name={item.data.id}
                label={t("REMARKS")}
                value={remarks.get(item.data.id)}
                onChange={(e) => setRemarks((current) => new Map(current).set(item.data.id, e.target.value))}
                onBlur={(blurEvent) => {
                  onRemarksBlur(item)().catch((e) => logger.error("Could not add remark to catalogue item", e));
                  field.props.onBlur(blurEvent);
                }}
              />
            )}
          </CatalogueListItem>
        ))}
      {field.result?.rawValue && field.result.rawValue.items.length > 0 && showPrices && (
        <CataloguePriceList
          priceIncVat={field.result.rawValue.priceIncVat}
          priceExVat={field.result.rawValue.priceExVat}
          vat={field.result.rawValue.vat}
          currency={props.field.properties.currency}
          precision={props.field.properties.precision}
          showVat={showVat}
        />
      )}
      {datasource?.fallback && <Feedback status="warning" message={t("DATASOURCE_USES_FALLBACK")} />}
      {isError && <Feedback status="error" message={t("CATALOGUE_DATASOURCE_NOT_EXIST_OR_NOT_AVAILABLE")} />}
      {field.props.errorMessage && <Feedback status="error" message={field.props.errorMessage} />}
      <Drawer
        open={isSearchOpen}
        header={{
          kind: "simple",
          title: t("SEARCH_SELECT_ITEM"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setIsSearchOpen(false),
          },
          content: initialized && (
            <div className="flex gap-x-2 px-5 pb-4">
              <SearchField className="w-full" placeholder={t("SEARCH_PLACEHOLDER")} value={query} onChange={setQuery} />
              {props.field.properties.use_barcode_scanner && (
                <IconButton
                  aria-label={t("OPEN_BARCODE_SCANNER")}
                  size="lg"
                  icon="QrcodeIcon"
                  onClick={() => {
                    startScanning().catch((e) => logger.error("Could not start scanner", e));
                  }}
                  disabled={!isScanSupported || isDisabled || isLoading || isError}
                  loading={isScannerInstalling}
                />
              )}
            </div>
          ),
        }}
        onClose={() => setIsSearchOpen(false)}
        contentPadding={false}
      >
        {datasource && (
          <CatalogueSearchContent
            datasource={datasource}
            query={debouncedQuery}
            entryFields={enabledEntryFields}
            subtitle={drawerSubtitle}
            onAdd={(e) => {
              add(e).catch((err) => logger.error("Could not add datasource entry", err));
            }}
            onInit={setInitialized}
            showPrices={showPrices}
            showVat={showVat}
            currency={props.field.properties.currency}
            precision={props.field.properties.precision}
          />
        )}
      </Drawer>
      <Drawer
        open={activeOpen}
        header={{
          kind: "simple",
          title: getCatalogueItemTitle(activeItem || {}, enabledEntryFields),
          subtitle: drawerSubtitle,
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: () => setActiveOpen(false),
          },
        }}
        onClose={() => {
          setActiveOpen(false);
        }}
        contentPadding={false}
      >
        <CatalogueItemContent item={activeItem} showPrice={showPrices} showVat={showVat} />
      </Drawer>
      <Modal
        title={t("CATALOGUE_ITEM_REMOVE_MODAL_TITLE")}
        content={{
          kind: "message",
          message: t("CATALOGUE_ITEM_REMOVE_MODAL_DESCRIPTION"),
        }}
        open={!!deleteItem}
        onClose={onDeleteCancel}
        buttons={[
          {
            label: t("CANCEL"),
            onClick: onDeleteCancel,
          },
          {
            label: t("REMOVE"),
            variant: "destructive",
            onClick: (): void => {
              remove(deleteItem!)
                .catch((e) => logger.error("Could not remove", e))
                .finally(() => {
                  setActiveEditing(undefined);
                  setDeleteItem(undefined);
                });
            },
          },
        ]}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </article>
  );
};

WidgetCatalogue.defaultValue = (_field, defaultMeta): WidgetResult<CatalogueResult> => ({
  type: "object",
  meta: {
    widget: "catalogue",
    ...defaultMeta,
  },
});

WidgetCatalogue.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  return required && !val ? t("VALIDATION_REQUIRED") : undefined;
};

const getValue = (
  getRawValue: () => CatalogueResult,
  item: CatalogueItem,
  precision: number,
  buildTotals: (items: CatalogueItem[]) => {
    priceExVat: number;
    priceIncVat: number;
    vat: number;
  },
  quantity: number = 0,
): CatalogueResult => {
  const rawValue = getRawValue();
  const price = parseFloat(item.data.priceExVat);
  const priceHasValue = Number.isFinite(price);
  const priceExVat = priceHasValue ? round(price * quantity, precision) : 0;
  const priceIncVat = priceHasValue ? round(priceExVat * (1 + item.vatRate! / 100), precision) : 0;
  const vat = priceHasValue ? round(priceIncVat - priceExVat, precision) : 0;
  const items = rawValue.items.map((i) =>
    i.data.id !== item.data.id ? i : { ...item, quantity, priceExVat, priceIncVat, vat },
  );
  return { ...rawValue, ...buildTotals(items), items };
};

export default WidgetCatalogue;
