import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { Text } from "../../storybook/components/Text/Text";

export interface WidgetLabelProperties {
  label_text: string;
}

const WidgetLabel: Widget<WidgetLabelProperties, WidgetResult<void>> = (props) => {
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetLabel.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <div className="pb-4">
      <Text>{props.field.properties.label_text}</Text>
    </div>
  );
};

WidgetLabel.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "label",
    ...defaultMeta,
  },
});

WidgetLabel.validate = (): undefined => undefined;

export default WidgetLabel;
