import { isEmpty } from "lodash-es";
import { Duration } from "luxon";
import { DataSourceEntry } from "../types/Datasource";
import { traversePath } from "./interpolateUtil";
import { CatalogueResult } from "../components/widgets/WidgetCatalogue";
import { CurrencyResult, DurationResult } from "../types/Widget";
import { FormFieldTerm } from "./calculationUtil";
import { Field, WidgetDataTypes } from "../types/Field";
import { DateTimeValue } from "../storybook/components/DateTimeInput/DateTimeInput";

const excludedTermTypes: WidgetDataTypes[] = ["file", "none", "location", "array"];
export const getTermFields = (fields: Field[], terms: string[], entryId?: string): FormFieldTerm[] => {
  const subformWidgets = fields?.filter((value) => !isEmpty(value.entries) && value.entryId === entryId);

  return (
    fields
      ?.filter((value) => findTerm(terms, value))
      .filter((value) => value.entryId === entryId || isNestedField(value, subformWidgets))
      // eslint-disable-next-line no-underscore-dangle
      .filter((value) => !value._deleted)
      .map((field) => ({ field, term: findTerm(terms, field) }) as FormFieldTerm)
  );
};

export const getTermData = ({ field: f, term }: FormFieldTerm): unknown[] => {
  if (!f.data && f.data !== 0 && f.data !== "") {
    return [];
  }
  if (f.data === "") {
    return [0];
  }
  if (excludedTermTypes.includes(f.type)) {
    return [];
  }
  if (f.widget === "search") {
    const searchResult = f.data as DataSourceEntry;
    const pathElements = term?.split(".");
    const value = traversePath(pathElements.slice(1).join("."), searchResult?.data ?? {});
    return value ? [value] : [];
  }
  if (f.widget === "catalogue" && f.data) {
    const result = f.data as CatalogueResult;
    return [result.priceIncVat];
  }

  if (f.type === "object") {
    const pathElements = term?.split(".");
    const value = traversePath(pathElements.slice(1).join("."), f?.data ?? {});
    return value ? [value] : [];
  }
  if (f.type === "duration") {
    // It's stored in the registration data as hours in decimal, so convert to that number
    const duration = f.data as DurationResult;
    return duration?.duration ? [Duration.fromISO(duration?.duration).as("hours")] : [];
  }
  if (f.type === "datetime") {
    const dateTime = f.data as DateTimeValue;
    return dateTime ? [`${dateTime.date} ${dateTime.time}`] : [];
  }
  if (f.type === "currency" && f.data) {
    const result = f.data as CurrencyResult;
    return [result.value ?? 0];
  }
  if (f.widget === "lookup") {
    if (typeof f.data === "string") {
      return [f.data];
    }
    const data = f.data as string[];
    if (data && data.length > 0) {
      return data;
    }
    return [];
  }

  return [f.data];
};

const isNestedField = (nestedField: Field, subformFields: Field[]): Field | undefined =>
  subformFields?.find((subformWidget) => {
    const isParent = subformWidget.id === nestedField.parentId;
    const isActiveEntry = subformWidget.entries.find((entry) => entry.id === nestedField.entryId && !entry.deleted);
    // Only return fields that belong to a parent with an entry that hasn't been deleted
    return isParent && isActiveEntry;
  });

const findTerm = (terms: string[], field: Field): string | undefined =>
  terms.find((t) => t.split(".")[0] === field.formFieldId);
