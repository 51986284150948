import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import ResourceImage from "../ResourceImage";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { Radio, RadioOption } from "../../storybook/components/Radio/Radio";

interface RadioOptionProperties {
  id: string;
  name: string;
  imgResource?: string;
}

export interface WidgetRadioProperties {
  label_text: string;
  radio_options: RadioOptionProperties[];
  default_value?: string;
  required: boolean;
  use_images_as_labels?: boolean;
  vertical_alignment?: boolean;
}

const WidgetRadio: Widget<WidgetRadioProperties, WidgetResult<string>> = (props) => {
  const { t } = useTranslation();

  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetRadio.validate,
    { onChange: "none", onBlur: "none", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );

  const options: RadioOption[] = useMemo(
    () =>
      props.field.properties.use_images_as_labels
        ? props.field.properties.radio_options.map(({ imgResource, id }) => ({
            label: {
              kind: "image",
              image: imgResource ? (
                <ResourceImage className="size-full object-contain mix-blend-darken" resourceId={imgResource} />
              ) : (
                <span />
              ),
            },
            value: id,
          }))
        : props.field.properties.radio_options.map(({ name, id }) => ({
            label: { kind: "text", value: name },
            value: id,
          })),
    [props.field.properties.radio_options, props.field.properties.use_images_as_labels],
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("RADIO_FIELD")}`}>
      <Radio
        {...field.props}
        inputRef={field.inputRef}
        label={props.field.properties.label_text}
        disabled={isDisabled}
        options={options}
        layout={props.field.properties.vertical_alignment ? "vertical" : "horizontal"}
        required={props.field.properties.required}
        onChange={async (e) => {
          await helpers.persist(e.target.value);
          field.controller.field.onBlur();
        }}
        clearLabel={t("CLEAR")}
        onClear={() => helpers.persist()}
      />
    </article>
  );
};

WidgetRadio.defaultValue = (field, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: field.properties.default_value,
  meta: {
    widget: "radio",
    ...defaultMeta,
  },
});

WidgetRadio.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetRadio;
