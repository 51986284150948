import { useRxData } from "rxdb-hooks";
import { RememberedField } from "../types/Field";
import type { RememberedFieldDocument } from "../utils/databaseUtil";

type UseLocalRememberedFields = {
  rememberedFields: RememberedFieldDocument[];
  isFetchingRememberedFields: boolean;
};

const useLocalRememberedFields = (formId?: string): UseLocalRememberedFields => {
  const { result, isFetching } = useRxData<RememberedField>("remembered-fields", (c) =>
    c
      .find()
      .where("formId")
      .eq(formId ?? "-1"),
  );

  return {
    rememberedFields: result,
    isFetchingRememberedFields: isFetching,
  };
};

export default useLocalRememberedFields;
