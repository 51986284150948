import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { isEmail } from "../../utils/stringUtil";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { TextInput } from "../../storybook/components/TextInput/TextInput";

export interface WidgetEmailProperties {
  required: boolean;
  label_text: string;
  text_placeholder?: string;
  text_default_value?: string;
}

const WidgetEmail: Widget<WidgetEmailProperties, WidgetResult<string>> = (props) => {
  const { t } = useTranslation();
  const { isDisabled, field } = useWidget(
    props.context,
    props.field,
    WidgetEmail.validate,
    { onChange: "set", onBlur: "persist", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("EMAIL_FIELD")}`}>
      <TextInput
        {...field.props}
        inputRef={field.inputRef}
        disabled={isDisabled}
        name={props.field.uid}
        type="email"
        label={props.field.properties.label_text}
        placeholder={props.field.properties.text_placeholder}
        required={props.field.properties.required}
      />
    </article>
  );
};

WidgetEmail.defaultValue = (field, defaultMeta): WidgetResult<string> => ({
  type: "string",
  rawValue: field.properties.text_default_value,
  meta: {
    widget: "email",
    ...defaultMeta,
  },
});

WidgetEmail.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  // Minimal email validation
  if (val && !isEmail(val)) {
    return t("VALIDATION_EMAIL_INVALID");
  }

  return undefined;
};

export default WidgetEmail;
