import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import WidgetError from "./WidgetError";
import useLocalValue from "../../hooks/useLocalValue";
import { Checkbox } from "../../storybook/components/Checkbox/Checkbox";

export interface WidgetCheckboxProperties {
  required: boolean;
  label_text: string | ReactNode;
  checkbox_default_value?: boolean;
}

const WidgetCheckbox: Widget<WidgetCheckboxProperties, WidgetResult<boolean>> = (props) => {
  const { t } = useTranslation();
  const { helpers, isDisabled, field } = useWidget(
    props.context,
    props.field,
    WidgetCheckbox.validate,
    { onChange: "set", onBlur: "none", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );
  const [value, setValue] = useLocalValue<boolean>(field.props.value, helpers, field);

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("CHECKBOX_FIELD")}`} ref={field.inputRef}>
      <Checkbox
        {...field.props}
        selected={value}
        disabled={isDisabled}
        onChange={(e) => setValue(e.target.checked)}
        onBlur={() => field.controller.field.onBlur()}
        className="text-gray-900"
      >
        {props.field.properties.label_text}
      </Checkbox>
    </article>
  );
};

WidgetCheckbox.defaultValue = (field, defaultMeta): WidgetResult<boolean> => ({
  type: "boolean",
  rawValue: field.properties.checkbox_default_value ?? false,
  meta: {
    widget: "checkbox",
    ...defaultMeta,
  },
});

WidgetCheckbox.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetCheckbox;
