/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { CaptureContext } from "@sentry/types";

const logger = {
  log: console.log,
  error: (message: string, error?: unknown, captureContext?: CaptureContext): void => {
    console.error(message, error);
    Sentry.captureException(new Error(message, { cause: error }), captureContext);
  },
  debug: console.debug,
  warn: console.warn,
  dir: console.dir,
};

export default logger;
