import React, { FC, PropsWithChildren } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { SubmissionFormData } from "./Form";

interface StaticFormProps {
  defaultValues: SubmissionFormData;
}
const StaticForm: FC<StaticFormProps & PropsWithChildren> = ({ defaultValues, children }) => {
  const methods: UseFormReturn<SubmissionFormData, any> = useForm<SubmissionFormData>({
    defaultValues,
    mode: "all",
    reValidateMode: "onChange",
  });

  return (
    <div className="flex flex-col gap-y-6">
      <FormProvider {...methods}>{children}</FormProvider>
    </div>
  );
};
export default StaticForm;
