import { useCallback, useEffect } from "react";
import { RxChangeEvent } from "rxdb";
import { useFormContext, UseFormSetValue } from "react-hook-form";
import { useRxCollection } from "rxdb-hooks";
import { useBeforeunload } from "react-beforeunload";
import { Field, rxToForm, WidgetResult } from "../types/Field";
import { SubmissionFormData } from "../components/Form";
import { INITIAL_DATE } from "./useWidget";
import { useFocussedField } from "../context/FocusContext";
import { SubmissionDocument } from "../utils/databaseUtil";
import { updateHumanEdited } from "../utils/formUtil";
import { isFieldChanged } from "../utils/fieldUtil";

const FOCUSABLE_FIELD_TYPES = ["string", "number", "currency"];

const useSync = (
  submission: SubmissionDocument,
  readOnly: boolean,
  setHumanEdited?: (value: boolean) => void,
): void => {
  const { getValues, setValue } = useFormContext<SubmissionFormData>();
  const fieldsCollection = useRxCollection<Field>("fields");
  const { focussed } = useFocussedField();

  const updateField = useCallback(
    (input: RxChangeEvent<Field>) => {
      const field = input.documentData;
      const formField = getValues(field.id);
      if (field.submissionId !== submission.id || !isFieldChanged(field, formField)) {
        return;
      }
      updateHumanEdited([field], setHumanEdited);
      const focussedField = field.id === focussed.current && FOCUSABLE_FIELD_TYPES.includes(field.type);
      setFormField(field, formField, setValue, focussedField);
    },
    [focussed, getValues, setHumanEdited, setValue, submission.id],
  );

  // update local FormContext with field-changes that came in via sync
  useEffect(() => {
    const subscription = fieldsCollection?.$.subscribe(updateField);
    return () => subscription?.unsubscribe();
  }, [fieldsCollection, updateField]);

  // Show warning before navigating away if form data doesn't match persisted state
  // i.e. you input text and try reloading before blurring the field
  useBeforeunload(async (event) => {
    if (!readOnly) {
      const formData = getValues();
      const fields = await fieldsCollection?.find().where("submissionId").eq(submission.id).exec();
      if (fields?.some((field) => isFieldChanged(field, formData[field.id]))) {
        event.preventDefault();
      }
    }
  });
};

const setFormField = (
  field: Field,
  widgetResult: WidgetResult<unknown>,
  setValue: UseFormSetValue<SubmissionFormData>,
  focussed: boolean,
): void => {
  if (!(field?.data || field?.entries)) {
    return; // Only set when data is loaded
  }

  if (field.updatedAt === widgetResult?.updatedAt) {
    return; // Don't process data that hasn't changed
  }

  const hasRawValueAndUpdated = widgetResult?.rawValue && field.updatedAt === INITIAL_DATE; // When initial value has already been set, don't set it again
  if (hasRawValueAndUpdated || focussed) {
    return; // Don't re-render initial data
  }

  setValue(field.id, rxToForm(field), {
    shouldValidate: !!field.updatedBy,
    shouldTouch: !!field.updatedBy,
    shouldDirty: !!field.updatedBy,
  });
};

export default useSync;
