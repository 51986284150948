import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

export type Color =
  | "dark"
  | "dark-light"
  | "medium"
  | "medium-light"
  | "light"
  | "danger"
  | "white"
  | "success"
  | "warning";

export interface TextProps extends PropsWithChildren {
  className?: string;
  size?: "3xl" | "2xl" | "xl" | "lg" | "base" | "sm" | "xs" | "2xs" | "3xs";
  weight?: "normal" | "medium" | "semibold" | "bold";
  color?: Color;
  font?: "sans" | "mono";
}

export const Text: FC<TextProps> = ({
  children,
  size = "base",
  weight,
  color = "dark",
  font = "sans",
  className,
  ...props
}) => {
  const classes = classNames(
    "overflow-hidden break-words",
    {
      // weights
      "font-bold": weight === "bold",
      "font-semibold": weight === "semibold",
      "font-medium": weight === "medium",
      "font-normal": weight === "normal",

      // sizes
      "text-3xl": size === "3xl",
      "text-2xl": size === "2xl",
      "text-xl": size === "xl",
      "text-lg": size === "lg",
      "text-base": size === "base",
      "text-sm": size === "sm",
      "text-xs": size === "xs",
      "text-2xs": size === "2xs",
      "text-3xs": size === "3xs",

      // colors
      "text-white": color === "white",
      "text-gray-100": color === "light",
      "text-gray-300": color === "medium-light",
      "text-gray-500": color === "medium",
      "text-gray-600": color === "dark-light",
      "text-gray-700": color === "dark",
      "text-red-500": color === "danger",
      "text-green-700": color === "success",
      "text-orange-500": color === "warning",
    },
    font === "mono" && "font-mono",
    className,
  );

  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
};
