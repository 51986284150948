import { useTranslation } from "react-i18next";
import { extractIBAN, friendlyFormatIBAN, validateIBAN } from "ibantools";
import { isEmpty } from "lodash-es";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { TextInput } from "../../storybook/components/TextInput/TextInput";

export interface WidgetIBANProperties {
  required: boolean;
  label_text: string;
}

export type IBANResult = {
  ibanNumber: string;
};

const WidgetIBAN: Widget<WidgetIBANProperties, WidgetResult<IBANResult>> = (props) => {
  const { t } = useTranslation();
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetIBAN.validate,
    {
      onChange: "set",
      onBlur: "persist",
      valueFormat: (value) => {
        const rawIbanNumber = value?.rawValue?.ibanNumber;
        if (rawIbanNumber === undefined) {
          return undefined;
        }

        // Only format if valid IBAN number
        return extractIBAN(rawIbanNumber).valid ? friendlyFormatIBAN(rawIbanNumber) : rawIbanNumber;
      },
    },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("IBAN_FIELD")}`}>
      <TextInput
        {...field.props}
        inputRef={field.inputRef}
        type="text"
        disabled={isDisabled}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        placeholder={t("IBAN_PLACEHOLDER_TEXT")}
        onChange={(e) => {
          const iban = extractIBAN(e.target.value);
          helpers.setValue({
            ibanNumber: iban.valid ? iban.iban : e.target.value,
          });
        }}
        errorMessage={field.props.errorMessage || ""}
        leftIcon={{ name: "CreditCardIcon" }}
      />
    </article>
  );
};

WidgetIBAN.defaultValue = (_field, defaultMeta: any): WidgetResult<IBANResult> => ({
  type: "object",
  meta: {
    widget: "iban",
    ...defaultMeta,
  },
});

WidgetIBAN.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && isEmpty(val?.ibanNumber)) {
    return t("VALIDATION_REQUIRED");
  }

  if (!isEmpty(val?.ibanNumber) && !validateIBAN(val?.ibanNumber).valid) {
    return t("VALIDATION_IBAN_INVALID");
  }

  return undefined;
};

export default WidgetIBAN;
