import { useTranslation } from "react-i18next";
import { useMemo, useRef, useState } from "react";
import { CameraSource, Photo } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import { useWindowSize } from "usehooks-ts";
import { isEmpty } from "lodash-es";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { ActiveEntry, FileResult, PinIcon, PinResultMeta, Widget } from "../../types/Widget";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import logger from "../../utils/logger";
import useFileHandler from "../../hooks/useFileHandler";
import uuidv4 from "../../utils/uuid";
import { SubformEntry } from "./WidgetSubform";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { validateUpload } from "../../utils/validationUtil";
import useResource from "../../hooks/useResource";
import { WidgetResult } from "../../types/Field";
import { SubForm } from "../../types/FormVersion";
import useDatasourceImages from "../../hooks/useDatasourceImages";
import DatasourceImageSelect from "./search/DatasourceImageSelect";
import Img from "../Img";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import WidgetError from "./WidgetError";
import PinAddDrawer from "./pin/PinAddDrawer";
import PinMoveDrawer from "./pin/PinMoveDrawer";
import PinPreviewImage from "./pin/PinPreviewImage";
import PinEntries from "./pin/PinEntries";
import FormDrawer from "./pin/FormDrawer";
import { getFormVersion } from "../../utils/pinUtil";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { noopAsync } from "../../utils/noop";
import usePhotoHandler from "../../hooks/usePhotoHandler";
import { getInvalidFields } from "../../utils/submissionUtil";
import useRememberFields from "../../hooks/useRememberFields";
import { FormMethods } from "../Form";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { DropdownMenu } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Label } from "../../storybook/components/Label/Label";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { useFocussedField } from "../../context/FocusContext";
import PdfImageUpload, { ConvertStatus, PdfImageUploadMethods } from "./pin/PdfImageUpload";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import FileFeedback from "./file/FileFeedback";
import FileUploadFailedModal from "../FileUploadFailedModal";

export interface PinConfig {
  form?: SubForm;
  target_form_id?: string;
  icon: PinIcon;
  itemMarkup?: string;
  name?: string;
}

export interface WidgetPinProperties {
  required: boolean;
  label_text: string;
  default_image?: string;
  allow_change_image?: boolean;
  pins?: PinConfig[];
}

const WidgetPin: Widget<WidgetPinProperties, WidgetResult<FileResult>> = (props) => {
  const { t } = useTranslation();
  const { rememberFields } = useRememberFields();
  const { addTask } = useFocussedField();
  const [activeEntry, setActiveEntry] = useState<ActiveEntry>();
  const [pinViewOpen, setPinViewOpen] = useState(false);
  const [movePin, setMovePin] = useState<SubformEntry<PinResultMeta>>();
  const [imgUrl, setImgUrl] = useState<string>();
  const pins = props.field.properties.pins ?? [];
  const pdfRef = useRef<PdfImageUploadMethods>(null);
  const [convertState, setConvertState] = useState<ConvertStatus>("pending");
  const { isOnline } = useOnlineStatus();
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetPin.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );
  const images = useDatasourceImages(props.context.submission.id);
  const [selectImageDrawer, setSelectImageDrawer] = useState(false);
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const [showFailedUploadModal, setFailedUploadModal] = useState(false);
  const { current, addPhoto, isUploadingPhoto, showCompletedMessage } = usePhotoHandler(props.context.submission.id);
  const { storeAndUploadFile, getFileUrl } = useFileHandler();
  const { data, isError, isLoading } = useResource(props.field.properties.default_image);
  const windowSize = useWindowSize();
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const formRef = useRef<FormMethods>(null);

  useAsyncEffect(
    async () => {
      if (field.result?.rawValue?.id) {
        const fileUrl = await getFileUrl(field.result.rawValue, props.context.submission.id);
        setImgUrl(Capacitor.convertFileSrc(fileUrl!));
        return;
      }
      if (!isLoading && data && data.dataUrl) {
        setImgUrl(data.dataUrl);
        const { fileResult, uploadStatus } = await storeAndUploadFile(
          data.dataUrl,
          props.context.submission.id,
          uuidv4(),
        );
        await helpers.persistWithUploadStatus(fileResult, uploadStatus, { humanEdit: false });
      }
    },
    noopAsync,
    [field.result?.rawValue?.remoteId, data, isLoading],
  );

  const getOptionalSelectImageButton = useMemo(
    () => (images.length > 0 ? [{ label: t("SELECT_PHOTO"), onClick: () => setSelectImageDrawer(true) }] : []),
    [images], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getOptionalUploadPdfButton = useMemo(
    () => (isOnline ? [{ label: t("UPLOAD_PDF"), onClick: () => pdfRef.current?.onClick() }] : []),
    [isOnline], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (props.field.properties.default_image && isLoading) {
    return <Spinner />;
  }

  if (isError || (data && data.dataUrl?.length === 0)) {
    return <Img className="mx-auto" src="/assets/image_error.svg" alt={t("IMAGE_FAILED_TO_LOAD_ALT")} />;
  }

  const remove = (entryId: string): void => {
    const entry = field.result?.entries!.find((i) => i.id === entryId);
    if (entry) {
      helpers.removeEntry(entry).catch((e) => logger.error("Can't remove pin entry", e));
    } else {
      logger.warn(`Tried removing entry ${entryId} which wasn't available`);
    }
  };

  const edit = (pin: SubformEntry<any>): void => {
    // It has been saved before, otherwise it wouldn't appear in the Pin entry list.
    // Consider it a humanEdited entry to avoid deleting entry when entry is empty.
    setActiveEntry({ id: pin.id, isHumanEdited: true });
  };

  const handleClose = (): void => setPinViewOpen(false);

  const handleAddPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const onPhotoFetched = async (photo: Photo): Promise<void> => {
        setImgUrl(photo.webPath);
        setUploadPercentage(0);
      };
      const onUploadProgress = (percentage: number): void => setUploadPercentage(percentage);
      const result = await addPhoto({ source }, onPhotoFetched, onUploadProgress);
      if (field.result) {
        await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus, {
          shouldWait: false,
        });
      }
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      if (error.message !== "USER_CANCELLED") {
        logger.error("Failed to add Pin-photo", error);
      }
    }
  };

  const addSelectedImage = async (url: string): Promise<void> => {
    if (!url) {
      return;
    }
    const { data: blob } = await axios.get(url, { responseType: "blob" });
    const dataUrl = URL.createObjectURL(blob);
    const id = uuidv4();
    const result = await storeAndUploadFile(dataUrl, props.context.submission.id, id);
    URL.revokeObjectURL(dataUrl);
    if (field.result) {
      await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus);
    }
  };

  const emptyState = (
    <>
      <DropdownMenu
        className="w-full"
        menuButton={() => (
          <IconAndTextButton
            block
            disabled={isDisabled}
            label={!isDisabled ? t("ADD_AN_IMAGE") : t("NO_IMAGE_ADDED")}
            icon="CameraIcon"
          />
        )}
        items={[
          { label: t("CAPTURE_PHOTO"), onClick: () => addTask(handleAddPhoto(CameraSource.Camera)) },
          {
            label: t("BROWSE_PHOTOS"),
            onClick: () => addTask(handleAddPhoto(CameraSource.Photos)),
          },
          ...getOptionalUploadPdfButton,
          ...getOptionalSelectImageButton,
        ]}
        disabled={isDisabled}
      />
      <PdfImageUpload
        submissionId={props.context.submission.id}
        helpers={helpers}
        ref={pdfRef}
        setConvertState={setConvertState}
        onUploadProgress={(percentage: number): void => setUploadPercentage(percentage)}
      />
    </>
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const getErrorElement = (): JSX.Element => {
    const isUploading = field.result?.meta?.uploadStatus === "uploading";
    if (isUploading && current?.id === field.result?.rawValue?.id) {
      return <Feedback className="mt-2" status="warning" message={t("RETRY_UPLOAD_MESSAGE")} />;
    }

    if (field.props.errorMessage && field.controller.fieldState.error?.type === "entries") {
      return <Feedback status="error" message={t("VALIDATION_SUBFORM_ENTRY_INVALID")} />;
    }

    return <FileFeedback field={field} />;
  };

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("PIN_FIELD")}`}>
      <Label label={props.field.properties.label_text} required={props.field.properties.required} />
      <DatasourceImageSelect
        open={selectImageDrawer}
        setOpen={setSelectImageDrawer}
        images={images}
        onSelect={addSelectedImage}
      />
      {!imgUrl && (convertState === "pending" || convertState === "error") ? (
        emptyState
      ) : (
        <>
          <PinPreviewImage
            imgUrl={imgUrl}
            field={field}
            isDisabled={isDisabled}
            windowSize={windowSize}
            onClick={() => !isDisabled && setPinViewOpen(true)}
            isUploadingPhoto={isUploadingPhoto || convertState === "converting" || convertState === "uploading"}
            percentageUploaded={uploadPercentage}
            showCompletedMessage={showCompletedMessage}
            onUploadRetry={() => setFailedUploadModal(true)}
          />
          <PinEntries
            field={field}
            context={props.context}
            helpers={helpers}
            isDisabled={isDisabled}
            pins={pins}
            onDelete={(intent) => remove(intent.id)}
            onEdit={edit}
            onMove={setMovePin}
          />
          <PinAddDrawer
            pins={pins}
            widgetProps={props.field.properties}
            open={pinViewOpen}
            onClose={handleClose}
            helpers={helpers}
            field={field}
            context={props.context}
            windowSize={windowSize}
            imgUrl={imgUrl}
            addSelectedImage={addSelectedImage}
          />
          <PinMoveDrawer
            pin={movePin}
            onClose={() => setMovePin(undefined)}
            field={field}
            helpers={helpers}
            imgUrl={imgUrl}
          />
          {activeEntry && (
            <FormDrawer
              {...props.context}
              ref={formRef}
              label={props.field.properties.label_text}
              formVersion={getFormVersion(
                field.result?.entries?.find((entry) => entry.id === activeEntry?.id)?.meta.scope.target,
                pins,
                props.context.fieldProperties,
              )}
              parentId={field.result!.meta.fieldId}
              activeEntry={activeEntry}
              setActiveEntry={setActiveEntry}
              closeForm={async (entryId, formVersion, discard, enforceValidation) => {
                const { submission } = props.context;
                // Trigger validation in nested Fields
                await formRef.current?.onIdle();
                await helpers.trigger(field.result?.meta.fieldId);
                const { error } = field.controller.fieldState;
                const entryError = error?.type === "entries" && error.message?.indexOf(entryId) !== -1;
                if (!discard && submission && !entryError) {
                  rememberFields(submission.id, submission.formId, formVersion?.fields ?? [], entryId);
                }
                if (!entryError || !enforceValidation) {
                  setActiveEntry(undefined);
                  return;
                }

                if (enforceValidation && props.context.fieldsCollection) {
                  const invalidFields = await getInvalidFields(props.context.fieldsCollection, submission.id, entryId);

                  if (!isEmpty(invalidFields)) {
                    formRef.current?.onInvalid(invalidFields);
                    return;
                  }
                }
                setActiveEntry(undefined);
              }}
              removeForm={() => remove(activeEntry?.id)}
            />
          )}
        </>
      )}
      {getErrorElement()}
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
      {showFailedUploadModal && (
        <FileUploadFailedModal
          show={showFailedUploadModal}
          onClose={() => setFailedUploadModal(false)}
          widgetResult={field.result}
          onRetry={async (result) => {
            await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus);
          }}
        />
      )}
    </article>
  );
};

WidgetPin.defaultValue = (_field, defaultMeta: any): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "pin",
    ...defaultMeta,
  },
  entries: [],
});

WidgetPin.validate = (val, _properties, t, meta): string | undefined => validateUpload(val, meta.uploadStatus, t);

export default WidgetPin;
