import { FC } from "react";
import { useTranslation } from "react-i18next";
import { optimizationImage } from "../../../utils/stringUtil";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";
import { NavItem } from "../../../storybook/components/NavItem/NavItem";

interface DatasourceImageSelectProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  images: any[];
  onSelect: (url: string) => void;
}

const DatasourceImageSelect: FC<DatasourceImageSelectProps> = ({ open, setOpen, images, onSelect }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={open}
      header={{
        kind: "simple",
        title: t("SEARCH_SELECT_IMAGE"),
        button: { kind: "icon", icon: "XIcon", onClick: () => setOpen(false) },
      }}
      onClose={() => setOpen(false)}
      contentPadding={false}
    >
      {images.map((item) => (
        <NavItem
          className="py-1"
          key={item.name}
          label={item.name}
          icon={{ name: "CameraIcon" }}
          onClick={async () => {
            try {
              onSelect(optimizationImage(item.url));
            } finally {
              setOpen(false);
            }
          }}
        />
      ))}
    </Drawer>
  );
};

export default DatasourceImageSelect;
