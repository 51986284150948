import { useEffect, useState } from "react";

/**
 * This hook allows you to derive a state value from a given dependency once.
 * The derived state value will be calculated using a provided callback function
 * and the dependency will trigger the calculation only once, when it is not null
 * or undefined.
 *
 * @template T - The type of the derived state value.
 * @template U - The type of the dependency value.
 * @param {function(dep: U): T} callback - A callback function that accepts the dependency value
 * and returns a derived state value.
 * @param {U | null | undefined} dep - A dependency value that is used to calculate the derived state value.
 * @returns {T | undefined} - The derived state value or undefined.
 */
const useDerivedStateOnce = <T, U>(callback: (dep: U) => T, dep: U | null | undefined): T | undefined => {
  // Create a boolean state variable to keep track of whether the calculation has been done or not.
  const [done, setDone] = useState(false);

  // Create a state variable to store the derived state value.
  const [val, setVal] = useState<T>();

  // Use the useEffect hook to calculate the derived state value only once, when the dependency is not null or undefined.
  useEffect(() => {
    if (dep !== undefined && dep !== null && !done) {
      setDone(true);
      setVal(callback(dep));
    }
  }, [dep, callback, done]);

  // Return the derived state value or undefined.
  return val;
};

export default useDerivedStateOnce;
