import { useEffect, useMemo, useState } from "react";
import MiniSearch, { Options as MiniSearchOptions } from "minisearch";
import { isEqual } from "lodash-es";

type Options<T> = {
  miniSearchOptions: MiniSearchOptions<T>;
  matchAllOnEmptyQuery: boolean;
};

export type MiniSearchResult<T> = {
  result: WithScore<T>[];
  isInitializing: boolean;
  isError: boolean;
};

export type WithScore<T> = {
  item: T;
  score?: number;
};

const CHUNK_SIZE = 10_000;

// If a number between 0 and 1 is given, fuzzy search is performed within a maximum edit distance corresponding to that fraction of the term length,
// approximated to the nearest integer. For example, 0.2 would mean an edit distance of 20% of the term length, so 1 character in a 5-characters term.
// The calculated fuzziness value is limited by the maxFuzzy option, to prevent slowdown for very long queries.
// This effectively means you may misspell 2 characters in every individual word
const FUZZINESS = 0.3;

// This effectively means you may misspell 2 characters in every individual word, no matter the length of the string
const MAX_FUZZINESS = 2;
const useMiniSearch = <T>(
  query: string,
  enabled: boolean,
  options: Options<T>,
  getId: (i: T) => string,
  list: T[] = [],
  filters: string[] = [],
): MiniSearchResult<T> => {
  const { matchAllOnEmptyQuery, miniSearchOptions } = options;
  const [isInitializing, setInitializing] = useState(true);
  const [searchList, setSearchList] = useState(list);
  const [searchFilters, setSearchFilters] = useState<string[]>([]);

  useEffect(() => {
    if (!isEqual(list, searchList)) {
      setSearchList(list);
    }
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEqual(filters, searchFilters)) {
      setSearchFilters(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const miniSearch = useMemo(() => {
    if (!enabled) {
      return undefined;
    }
    setInitializing(true);
    const search = new MiniSearch<T>({
      ...miniSearchOptions,
      searchOptions: {
        fuzzy: FUZZINESS,
        maxFuzzy: MAX_FUZZINESS,
        ...(miniSearchOptions.searchOptions ?? {}),
      },
    });
    (async (): Promise<void> => {
      await search.addAllAsync(searchList, { chunkSize: CHUNK_SIZE });
      setInitializing(false);
    })();
    return search;
  }, [searchList, enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  const mappedList = useMemo(
    () => searchList.reduce((acc, curr) => acc.set(getId(curr), curr), new Map<string, T>()),
    [getId, searchList],
  );

  const queryResult = useMemo(() => {
    if (!enabled || !miniSearch || isInitializing) {
      return [];
    }
    if (!query && matchAllOnEmptyQuery && searchFilters.length === 0) {
      return searchList.map((i) => ({ item: i }));
    }
    return miniSearch
      .search({
        combineWith: "AND",
        prefix: true,
        queries: [
          ...(searchFilters.length > 0 ? [{ queries: searchFilters, prefix: false, fuzzy: false }] : []),
          ...(query.length > 0 ? [query] : []),
        ],
      })
      .map((i) => ({ item: mappedList.get(i.id)!, score: i.score }));
  }, [isInitializing, mappedList, searchFilters, query, enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    result: queryResult,
    isInitializing,
    isError: false,
  };
};

export default useMiniSearch;
