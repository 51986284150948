import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { Radio } from "../../storybook/components/Radio/Radio";
import { Icon } from "../../storybook/components/Icon/Icon";

export interface WidgetRatingProps {
  required: boolean;
  label_text: string;
  rateScale: number;
}

const WidgetRating: Widget<WidgetRatingProps, WidgetResult<number>> = (props) => {
  const { t } = useTranslation();

  const { field, helpers, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetRating.validate,
    { onChange: "none", onBlur: "none", valueFormat: (value) => value?.rawValue?.toString() },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("RATING_FIELD")}`}>
      <Radio
        {...field.props}
        inputRef={field.inputRef}
        label={props.field.properties.label_text}
        layout="horizontal"
        activeStyle={false}
        scroll
        disabled={isDisabled}
        clearLabel={t("CLEAR")}
        onClear={() => helpers.persist()}
        options={[...Array(Math.ceil(props.field.properties.rateScale))].map((key, i) => ({
          label: {
            kind: "image",
            image: (
              <Icon
                name="StarIcon"
                type={i < (field.result?.rawValue || 0) ? "solid" : "outline"}
                className={classNames("size-8", {
                  "text-yellow-500": !isDisabled,
                  "text-gray-500": isDisabled,
                })}
              />
            ),
          },
          value: (i + 1).toString(),
        }))}
        required={props.field.properties.required}
        onChange={(e) => helpers.persist(parseInt(e.target.value, 10))}
      />
    </article>
  );
};

WidgetRating.defaultValue = (field, defaultMeta): WidgetResult<number> => ({
  type: "number",
  meta: {
    widget: "rating",
    ...defaultMeta,
  },
});

WidgetRating.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetRating;
