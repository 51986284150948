import { useTranslation } from "react-i18next";
import { CameraSource } from "@capacitor/camera";
import { useMemo, useState } from "react";
import { fabric } from "fabric";
import { isNil, startsWith } from "lodash-es";
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import { FileResult, Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import { validateUpload } from "../../utils/validationUtil";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { DropdownMenu } from "../../storybook/components/DropdownMenu/DropdownMenu";
import { Label } from "../../storybook/components/Label/Label";
import Img from "../Img";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { IconButton } from "../../storybook/components/IconButton/IconButton";
import { Sketchbook } from "../Sketchbook";
import DatasourceImageSelect from "./search/DatasourceImageSelect";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import useDatasourceImages from "../../hooks/useDatasourceImages";
import { API_URL } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useMoreAppClient } from "../../context/MoreAppContext";
import uuidv4 from "../../utils/uuid";
import useFileHandler, { type UploadResult } from "../../hooks/useFileHandler";
import {
  backgroundUrlUpdateNeeded,
  enforceBoundaries,
  generateImage,
  updateBackgroundUrl,
} from "../../utils/drawingUtil";
import { useAsyncEffect } from "../../hooks/useAsyncEffect";
import { noopAsync } from "../../utils/noop";
import useCamera, { InsufficientPermissionError } from "../../hooks/useCamera";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import logger from "../../utils/logger";
import { getRemoteResourceUrl } from "../../utils/fileUtil";
import FileFeedback from "./file/FileFeedback";
import { colors } from "../../storybook/colors";

export interface WidgetDrawingProperties {
  required: boolean;
  label_text: string;
  background_image?: string;
}

export interface DrawingData {
  canvas?: any;
  dimensions?: {
    width: number;
    height: number;
  };
  defaultImageUsed: boolean;
}

export type DrawingResult = FileResult & DrawingData;

const WidgetDrawing: Widget<WidgetDrawingProperties, WidgetResult<DrawingResult>> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [selectImageDrawer, setSelectImageDrawer] = useState(false);
  const images = useDatasourceImages(props.context.submission.id);
  const [percentageUploaded, setPercentageUploaded] = useState<number>(0);
  const [drawing, setDrawing] = useState<string | undefined>(undefined);
  const [showPermissionsModal, setShowPermissionsModal] = useState<boolean>(false);
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>(undefined);
  const { customerId } = useAuth();
  const client = useMoreAppClient();
  const { getFileUrl, storeAndUploadFile } = useFileHandler();
  const { getPhoto } = useCamera();
  const [drawingCanvas, setDrawingCanvas] = useState<fabric.Canvas | undefined>(undefined);

  const { field, isDisabled, helpers } = useWidget(
    props.context,
    props.field,
    WidgetDrawing.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
    true,
  );

  useAsyncEffect(
    async () => {
      if (!field.result?.rawValue) {
        // No drawing, abort
        return;
      }
      const { rawValue } = field.result;
      if (!rawValue.id && !rawValue.canvas) {
        setOpen(false);
        setDrawing(undefined);
        setBackgroundUrl(undefined);
        return;
      }

      const background = await getBackgroundImage();

      if (!rawValue.canvas && rawValue.remoteId) {
        setDrawing(background);
        setBackgroundUrl(background);
        return;
      }

      // check if we need to update the background url in the canvas. Get the remote path for it
      const fileUrl = await getFileUrl(rawValue, props.context.submission.id, true);
      const fileSrc = Capacitor.convertFileSrc(fileUrl!);
      if (backgroundUrlUpdateNeeded(fileSrc, rawValue)) {
        await helpers.persist(updateBackgroundUrl(rawValue, fileSrc));
        return;
      }

      const canvasWithUpdatedBackgroundUrl = updateBackgroundUrl(rawValue, background!);
      const previewImage = await generateImage(canvasWithUpdatedBackgroundUrl);
      setDrawing(previewImage);
      setBackgroundUrl(background);
      setDrawingCanvas(canvasWithUpdatedBackgroundUrl.canvas);
    },
    noopAsync,
    [field.result?.rawValue],
  );

  const getBackgroundImage = async (): Promise<string | undefined> => {
    if (!field.result?.rawValue) {
      return undefined;
    }
    // Check if default background image is used, if not return (remote) file URL
    const { rawValue } = field.result;

    if (!rawValue.defaultImageUsed) {
      const fileUrl = await getFileUrl(rawValue, props.context.submission.id);
      return Capacitor.convertFileSrc(fileUrl!);
    }

    // Default image is used, let's return that instead
    return getDefaultImage();
  };

  const getDefaultImage = async (): Promise<string | undefined> => {
    const resourceUrl = getResourceUrl(props.field.properties.background_image);
    if (!resourceUrl) {
      return undefined;
    }

    const { data } = await client!.get(resourceUrl, { responseType: "blob" });
    return URL.createObjectURL(data);
  };

  const uploadBackgroundImage = async (format: string, dataUrl: string): Promise<UploadResult> => {
    const imageId = uuidv4();
    await helpers.persistWithUploadStatus(
      {
        id: imageId,
        name: `photo.${format}`,
      } as DrawingResult,
      "uploading",
    );
    setPercentageUploaded(0);

    return storeAndUploadFile(dataUrl, props.context.submission.id, imageId, ({ loaded, total = 1 }) =>
      setPercentageUploaded((loaded / total) * 100),
    );
  };

  const clear = (): void => {
    setDrawing(undefined);
    setBackgroundUrl(undefined);
    setDrawingCanvas(undefined);
    helpers.persistWithUploadStatus(undefined, undefined);
    setOpen(false);
  };

  const save = async (canvas: fabric.Canvas | undefined): Promise<void> => {
    if (!canvas) {
      setOpen(false);
      return;
    }

    canvas.setZoom(1); // Render full frame
    enforceBoundaries(canvas);
    const canvasObj = canvas.toObject();
    const dimensions = {
      height: canvas.getHeight(),
      width: canvas.getWidth(),
    };

    setDrawing(canvas.toDataURL());

    if (startsWith(canvasObj?.backgroundImage?.src, "data:")) {
      // Don't persist base64 in RxDB
      canvasObj.backgroundImage.src = null;
      canvasObj.backgroundImage.crossOrigin = "anonymous"; // Ensure we allow CORS on loading the initial image
    }

    await helpers.persist({
      ...(field.result?.rawValue as DrawingResult),
      canvas: canvasObj,
      dimensions,
    });
    setOpen(false);
  };

  const createNewDrawing = async (): Promise<void> => {
    const image = await getDefaultImage();

    if (image === undefined) {
      setOpen(true);
      return;
    }

    setBackgroundUrl(image);
    setDrawing(image);
    const result = await uploadBackgroundImage("png", image);
    await helpers.persistWithUploadStatus({ ...result.fileResult, defaultImageUsed: true }, result.uploadStatus);
    setOpen(true);
  };

  const createDrawingFromPhoto = async (source: CameraSource): Promise<void> => {
    try {
      const photo = await getPhoto(source);
      const result = await uploadBackgroundImage(photo.format, photo.webPath!);
      await helpers.persistWithUploadStatus({ ...result.fileResult, defaultImageUsed: false }, result.uploadStatus);
      setBackgroundUrl(photo.webPath);
      setDrawing(photo.webPath);
      setOpen(true);
    } catch (error: any) {
      if (error instanceof InsufficientPermissionError) {
        setShowPermissionsModal(true);
        return;
      }

      logger.error("Could not create new drawing from photo", error);
    }
  };

  const createDrawingFromDataSource = async (url: string): Promise<void> => {
    if (!url) {
      return;
    }
    const { data } = await axios.get(url, { responseType: "blob" });
    const dataUrl = URL.createObjectURL(data);
    setBackgroundUrl(dataUrl);
    setDrawing(dataUrl);
    const result = await uploadBackgroundImage("png", dataUrl);
    await helpers.persistWithUploadStatus({ ...result.fileResult, defaultImageUsed: false }, result.uploadStatus);
    setOpen(true);
  };

  const getResourceUrl = (resourceUrl?: string): string | undefined =>
    resourceUrl ? `${API_URL}/customers/${customerId}/resources/${resourceUrl}/download/direct` : undefined;

  const optionalSelectImageButton = useMemo(
    () => (images.length > 0 ? [{ label: t("SELECT_PHOTO"), onClick: () => setSelectImageDrawer(true) }] : []),
    [images, t],
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const menuBtn = (
    <IconAndTextButton
      block
      disabled={isDisabled}
      label={!isDisabled ? t("ADD_DRAWING") : t("NO_DRAWING_ADDED")}
      icon="PencilIcon"
    />
  );

  const dropdownMenu = (
    <DropdownMenu
      className="w-full"
      menuButton={() => menuBtn}
      items={[
        {
          label: t("ADD_BLANK_DRAWING"),
          onClick: createNewDrawing,
        },
        { label: t("CAPTURE_PHOTO"), onClick: () => createDrawingFromPhoto(CameraSource.Camera) },
        { label: t("BROWSE_PHOTOS"), onClick: () => createDrawingFromPhoto(CameraSource.Photos) },
        ...optionalSelectImageButton,
      ]}
    />
  );

  const emptyBtn = !isDisabled ? dropdownMenu : menuBtn;
  const isUploading = field.result?.meta.uploadStatus === "uploading";
  const uploadFailed = field.result?.meta.uploadStatus === "failed";

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("DRAWING_FIELD")}`} ref={field.inputRef}>
      <Label
        htmlFor={field.props.name}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        showClearBtn={!!field.result?.rawValue && !isDisabled}
        onClear={clear}
        clearLabel={t("CLEAR")}
      />
      {field.result?.rawValue ? (
        <div className="relative">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="relative z-10 cursor-pointer rounded-lg border-2 bg-white"
            onClick={() => !isDisabled && !isUploading && setOpen(true)}
          >
            {!isUploading && <Img src={drawing} alt={t("DRAWING")} className="z-10 mx-auto h-40" />}
            {isUploading && (
              <div className="relative flex h-40 items-center justify-center">
                <Spinner className="size-8" />
              </div>
            )}
            {!isDisabled && !isUploading && (
              <div className="absolute right-4 top-4">
                <IconButton aria-label={t("EDIT_DRAWING")} icon="PencilIcon" />
              </div>
            )}
          </div>

          {isUploading && (
            <div
              data-testid="upload-bar"
              className="absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg"
              style={{
                background: `linear-gradient(to right, ${colors.green["700"]} ${percentageUploaded}%, ${colors.gray["200"]} ${percentageUploaded}% 100%)`,
              }}
            />
          )}

          {uploadFailed && <div className="absolute bottom-0 z-0 -mb-2 h-5 w-full rounded-lg bg-red-700" />}
        </div>
      ) : (
        emptyBtn
      )}
      {field.props.errorMessage && <FileFeedback field={field} />}
      <Sketchbook
        open={open}
        setOpen={setOpen}
        save={save}
        clear={clear}
        initialCanvas={drawingCanvas}
        backgroundUrl={backgroundUrl}
        initialSize={field.result?.rawValue?.dimensions}
      />
      <DatasourceImageSelect
        open={selectImageDrawer}
        setOpen={setSelectImageDrawer}
        images={images}
        onSelect={createDrawingFromDataSource}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setShowPermissionsModal(false)} />
      )}
    </article>
  );
};

WidgetDrawing.defaultValue = (_field, defaultMeta): WidgetResult<DrawingResult> => ({
  type: "file",
  meta: {
    widget: "drawing",
    ...defaultMeta,
  },
});

WidgetDrawing.validate = (val, properties, t, meta): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  if (val?.id) {
    // Check upload when background image is attached
    return validateUpload(val, meta.uploadStatus, t);
  }
  return undefined;
};

WidgetDrawing.onUploadComplete = async (value: DrawingResult | undefined): Promise<DrawingResult> => {
  if (isNil(value?.remoteId)) {
    throw new Error("Remote ID not found");
  }

  if (isNil(value?.canvas?.backgroundImage)) {
    return value;
  }

  return updateBackgroundUrl(value, getRemoteResourceUrl(value.remoteId));
};

export default WidgetDrawing;
