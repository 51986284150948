import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { to24hTime, toIsoDate } from "../../utils/dateUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { DateTimeInput, DateTimeValue } from "../../storybook/components/DateTimeInput/DateTimeInput";

export interface WidgetDateTimeProperties {
  required: boolean;
  label_text: string;
  now_as_default: boolean;
}

const WidgetDateTime: Widget<WidgetDateTimeProperties, WidgetResult<DateTimeValue>> = (props) => {
  const { t } = useTranslation();
  const { helpers, isDisabled, field } = useWidget(
    props.context,
    props.field,
    WidgetDateTime.validate,
    { onChange: "none", onBlur: "none", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }
  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("DATETIME_FIELD")}`}>
      <DateTimeInput
        {...field.props}
        inputRef={field.inputRef}
        type="datetime"
        name={props.field.uid}
        label={props.field.properties.label_text}
        disabled={isDisabled}
        required={props.field.properties.required}
        showClearBtn={!!field.result?.rawValue && !isDisabled}
        clearLabel={t("CLEAR")}
        onClear={() => helpers.persist(undefined)}
        onChange={async (value) => {
          const now = new Date();
          let prefilledValue = value;
          if (!value.date) {
            prefilledValue = { ...value, date: toIsoDate(now) };
          }
          if (!value.time) {
            prefilledValue = { ...value, time: to24hTime(now) };
          }
          await helpers.persist(prefilledValue);
          field.controller.field.onBlur();
        }}
      />
    </article>
  );
};

WidgetDateTime.defaultValue = (field, defaultMeta: any): WidgetResult<DateTimeValue> => {
  const now = new Date();
  return {
    type: "datetime",
    rawValue: field.properties.now_as_default ? { date: toIsoDate(now), time: to24hTime(now) } : undefined,
    meta: {
      widget: "datetime",
      ...defaultMeta,
    },
  };
};

WidgetDateTime.validate = (val, properties, t): string | undefined => {
  const { required } = properties;

  if ((!val?.date || !val?.time) && !(!val?.date && !val?.time)) {
    return t("VALIDATION_DATETIME_BOTH_REQUIRED");
  }

  if (required && !val?.date && !val?.time) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetDateTime;
