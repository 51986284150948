import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { ReadOnlyTextArea } from "../../storybook/components/ReadOnlyTextArea/ReadonlyTextArea";

export interface WidgetReadOnlyTextProperties {
  label_text: string;
  showheader: boolean;
}

const WidgetReadOnlyText: Widget<WidgetReadOnlyTextProperties, WidgetResult<string>> = (props) => {
  const { t } = useTranslation();
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetReadOnlyText.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const labelText = props.field.properties.label_text;
  const articleLabel = `${labelText ? `${labelText} - ` : ""}${t("READ_ONLY_TEXT_FIELD")}`;

  return (
    <article aria-label={articleLabel}>
      <ReadOnlyTextArea
        id={props.field.uid}
        value={field.result?.rawValue}
        label={props.field.properties.showheader ? labelText : undefined}
      />
    </article>
  );
};

WidgetReadOnlyText.defaultValue = (_field, defaultMeta): WidgetResult<string> => ({
  type: "string",
  meta: {
    widget: "readOnlyText",
    ...defaultMeta,
  },
});

WidgetReadOnlyText.validate = (): undefined => undefined;

export default WidgetReadOnlyText;
