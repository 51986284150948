import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import ResourceImage from "../ResourceImage";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import WidgetError from "./WidgetError";
import { Label } from "../../storybook/components/Label/Label";
import MissingImage from "../MissingImage";
import ClickableImage from "../ClickableImage";

export interface WidgetImageProperties {
  label_text: string;
  resource_id?: string;
  max_height?: number;
  max_width?: number;
  click_to_view?: boolean;
}

const WidgetImage: Widget<WidgetImageProperties, WidgetResult<void>> = (props) => {
  const { t } = useTranslation();
  const {
    resource_id: resourceId,
    max_width: maxWidth,
    max_height: maxHeight,
    click_to_view: clickToView,
    label_text: labelText,
  } = props.field.properties;
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetImage.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  const style = {
    ...(maxHeight && { maxHeight: `${maxHeight}px` }),
    ...(maxWidth && { maxWidth: `${maxWidth}px` }),
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }

  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const getImageComponent = (): JSX.Element => {
    if (!resourceId) {
      return <MissingImage />;
    }

    return clickToView ? (
      <ClickableImage resourceId={resourceId} style={style} />
    ) : (
      <ResourceImage className="mx-auto w-full" resourceId={resourceId} style={style} />
    );
  };

  const articleLabel = `${labelText ? `${labelText} - ` : ""}${t("IMAGE_FIELD")}`;

  return (
    <article aria-label={articleLabel}>
      <Label label={labelText} />
      {getImageComponent()}
    </article>
  );
};

WidgetImage.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "image",
    ...defaultMeta,
  },
});

WidgetImage.validate = (): undefined => undefined;

export default WidgetImage;
