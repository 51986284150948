import * as Sentry from "@sentry/capacitor";
import { init as sentryReactInit, BrowserTracing } from "@sentry/react";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import objectHash from "object-hash";
import { RewriteFrames } from "@sentry/integrations";
import { SENTRY_DSN } from "./constants";
import brandingUtil from "./utils/brandingUtil";

Sentry.init(
  {
    dsn: SENTRY_DSN,
    dist: process.env.VITE_COMMIT_SHA,
    integrations: [new BrowserTracing(), new RewriteFrames()],
    release: process.env.VITE_COMMIT_SHA,
    environment: process.env.VITE_ENV,
    tracesSampleRate: 0.05,
    sendDefaultPii: false,
    ignoreErrors: [
      "Invalid message 'type' property \"connection_error\"",
      "Failed to execute 'postMessage' on 'BroadcastChannel': Channel is closed",
      "Transaction aborted",
      "Non-Error promise rejection captured",
      "captured as promise rejection",
      "TimeoutError: Transaction timed out due to inactivity",
      "No Location permissions", // Generic location error
      "Location services are not enabled", // Android location Permission issue
      "Location permission was denied", // Android location Permission issue
      "[object GeolocationPositionError]", // Edge location permission issue
      "kCLErrorDomain", // iOS location permission issue
      "Could not verify JWT", // Auto-refreshing will only trigger when this error occurs, this is expected
      'null value in column "owner_id" of relation "submission_fields" violates not-null constraint', // This means the field replication is ahead of the submission replication (trying to insert a field before the submission exists). This in itself isn't an issue.
      "No image picked", // User cancellation
      "User cancelled photos app", // User cancellation
      "ResizeObserver loop completed with undelivered notifications", // Resize listener already triggered before rendering.
    ],
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === "ui.click" && hint) {
        let message = window.location.pathname;

        const { target } = hint.event;
        const ariaLabel = target.ariaLabel || target.parentNode?.ariaLabel;
        if (ariaLabel) {
          message += ` - "${ariaLabel}"`;
        }

        message += ` - ${breadcrumb.message}`;

        // eslint-disable-next-line no-param-reassign
        breadcrumb.message = message;
      }

      return breadcrumb;
    },
    enabled: process.env.VITE_MOREAPP_SENTRY_ENABLE_OVERRIDE === "true" || process.env.VITE_ENV === "production",
    beforeSend: (event) => {
      if (event.user) {
        // eslint-disable-next-line no-param-reassign
        delete event.user.ip_address;
      }

      return event;
    },
  },
  sentryReactInit,
);

(async (): Promise<void> => {
  const context: Record<string, any> = {};

  if (Capacitor.isNativePlatform()) {
    context.app_version = (await App.getInfo()).version;
    context.device_uuid = objectHash((await Device.getId()).identifier, { algorithm: "md5" }); // Hash uuid, so we can't link it to a specific device
    context.device_info = { ...(await Device.getInfo()), name: undefined }; // Scrub device name
  }

  context.brandingKey = brandingUtil.key;

  Sentry.setContext("app", context);
})();
