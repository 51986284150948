/* eslint-disable react/no-unused-prop-types */
import React, {
  forwardRef,
  RefObject,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { RxCollection } from "rxdb";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import { ErrorOption, useFormContext } from "react-hook-form";
import { useTimeout } from "usehooks-ts";
import { isEmpty, isNil } from "lodash-es";
import { RememberedFieldDocument, SubmissionDocument } from "../utils/databaseUtil";
import { Field, WidgetResult } from "../types/Field";
import { AbstractForm, FieldProperties, FormField } from "../types/FormVersion";
import useFields from "../hooks/useFields";
import { removeWidgetVersionNumber } from "../utils/stringUtil";
import WidgetError from "./widgets/WidgetError";
import { getCalculatedFieldId, WidgetComponents } from "../utils/formUtil";
import { WidgetContext, WidgetEntryProp } from "../types/Widget";
import { ErrorBoundary } from "./ErrorBoundary";
import { useFocussedField } from "../context/FocusContext";
import { Text } from "../storybook/components/Text/Text";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import SyncOfflineContent from "../pages/SyncOfflineContent";
import { DrawerContext } from "../context/DrawerContext";
import WidgetInvisible from "./widgets/WidgetInvisible";
import { getInvalidFields } from "../utils/submissionUtil";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { noopAsync } from "../utils/noop";

export type SubmissionFormData = Record<string, WidgetResult<any>>;

/**
 * Pick your validation poison, this applies to the entire Form component
 * - default: Shows validation on touched fields and after failing to save/send
 * - trigger_immediately: Shows validation immediately after loading Form component
 * - disabled: Disables validation completely.🐉 Used for partial search forms where data doesn't have to be completely valid.
 */
export type ValidationMode = "default" | "disabled" | "trigger_immediately";

export type FormProps = {
  formVersion: AbstractForm;
  fieldProperties: FieldProperties;
  data?: SubmissionFormData;
  parentId?: string;
  entryId?: string;
  submission: SubmissionDocument;
  fieldsCollection?: RxCollection<Field>;
  rememberedFields?: RememberedFieldDocument[];
  isInitialized?: boolean;
  setInitialized?: (value: boolean) => void;
  setHumanEdited?: (value: boolean) => void;
  readOnly?: boolean;
  handleSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  footer?: React.JSX.Element;
  containerRef?: RefObject<HTMLDivElement | null>;
  validationMode?: ValidationMode;
  shouldSync?: boolean;
  setSyncFailed?: (value: boolean) => void;
};

export type FormMethods = {
  onInvalid: (errors: FormFieldErrors[]) => void;
  onIdle: () => Promise<void>;
};

export type FormFieldErrors = {
  id: string;
  formFieldId: string;
  entryId?: string;
  error: ErrorOption;
};

const INITIALIZE_TIMEOUT = 15_000;

const Form = forwardRef<FormMethods, FormProps>(
  (
    {
      containerRef,
      formVersion,
      submission,
      fieldProperties,
      rememberedFields,
      fieldsCollection,
      readOnly,
      setHumanEdited,
      entryId,
      parentId,
      setInitialized,
      validationMode,
      shouldSync,
      setSyncFailed,
    },
    ref,
  ) => {
    const {
      initializedFields,
      visibility,
      isInitialized: fieldsInitialized,
    } = useFields(
      formVersion,
      fieldProperties,
      submission,
      rememberedFields,
      readOnly,
      shouldSync,
      fieldsCollection,
      setHumanEdited,
      entryId,
      parentId,
    );
    const { setError: setFormError, trigger } = useFormContext<SubmissionFormData>();
    const viewportRef = useRef<ViewportListRef>(null);
    const { onIdle } = useFocussedField();
    const [isInitiallyValidated, setInitiallyValidated] = useState(false);

    const [initTimeout, setInitTimeout] = useState(false);
    useTimeout(() => setInitTimeout(true), INITIALIZE_TIMEOUT);

    const { activeDrawer } = useContext(DrawerContext);
    useImperativeHandle(ref, () => ({ onInvalid, onIdle }));

    useAsyncEffect(
      async () => {
        if (isInitiallyValidated || !fieldsCollection || !fieldsInitialized) {
          return;
        }
        if (validationMode !== "trigger_immediately") {
          // Skip this behaviour for this Form and avoid triggering when the conditions change
          setInitiallyValidated(true);
          return;
        }

        // Show invalid fields right away, instead of showing organically due to touching fields
        const invalidFields = await getInvalidFields(fieldsCollection, submission.id, entryId);
        if (!isEmpty(invalidFields)) {
          onInvalid(invalidFields);
        }
        // Ensure this only runs once
        setInitiallyValidated(true);
      },
      noopAsync,
      [fieldsCollection, fieldsInitialized, trigger, validationMode, submission, parentId, entryId],
    );

    useEffect(() => {
      if (!setInitialized) {
        return;
      }

      setInitialized(fieldsInitialized);
    }, [fieldsInitialized, setInitialized]);

    useEffect(() => {
      if (initTimeout && setSyncFailed) {
        setSyncFailed(!fieldsInitialized);
      }
    }, [fieldsInitialized, initTimeout, setSyncFailed]);

    const entry: WidgetEntryProp = useMemo(() => ({ id: entryId, parentId }), [entryId, parentId]);

    const overscan = activeDrawer && !!entry ? Infinity : 5; // To prevent open subform widgets from being removed from the dom and freezing the app we dynamically disable ViewportList when a drawer is open.

    const context: WidgetContext = useMemo(
      () => ({
        formVersion,
        fieldProperties,
        submission,
        rememberedFields,
        fieldsCollection,
        readOnly,
        setHumanEdited,
        validationMode,
      }),
      [
        formVersion,
        fieldProperties,
        submission,
        rememberedFields,
        fieldsCollection,
        readOnly,
        setHumanEdited,
        validationMode,
      ],
    );

    const onInvalid = (errors: FormFieldErrors[]): void => {
      const visibleFields = formVersion.fields.filter((i) => visibility.get(i.uid));
      const visibleErrors = visibleFields
        .map((i) => errors.find((e) => e.formFieldId === i.uid))
        .filter((i) => !isNil(i)) as FormFieldErrors[];

      visibleErrors.forEach((i, index) => {
        // Only focus on first error, that's the one we want to scroll to
        const shouldFocus = index === 0;
        setFormError(i.id, i.error, { shouldFocus });

        if (shouldFocus) {
          viewportRef.current?.scrollToIndex({
            index: visibleFields.findIndex((j) => j.uid === i.formFieldId),
            alignToTop: true,
            offset: -160,
          });
        }
      });
    };

    if (!fieldsInitialized && !initTimeout) {
      return <Spinner className="mx-auto my-8" />;
    }

    if (initTimeout && !fieldsInitialized) {
      return <SyncOfflineContent />;
    }

    return (
      <ViewportList
        ref={viewportRef}
        viewportRef={containerRef}
        items={formVersion.fields}
        overflowAnchor="none"
        overscan={overscan}
        renderSpacer={({ ref: spacerRef, style: { margin, marginTop, ...style } }) => (
          <div ref={spacerRef} className="-mt-6" style={style} />
        )}
      >
        {(formField) => {
          const widgetId = removeWidgetVersionNumber(formField.widget);
          const WidgetComponent = WidgetComponents[widgetId]?.component || WidgetError;
          const fieldId = getCalculatedFieldId(formField.uid, context.submission.id, entry?.id, entry?.parentId);

          return (
            <ErrorBoundary key={formField.uid}>
              {(hasError) => {
                if (hasError) {
                  return getErrorElement(formField, widgetId);
                }
                const fieldRx = initializedFields?.has(fieldId) ? initializedFields?.get(fieldId) : undefined;
                if (!visibility?.get(formField.uid)) {
                  // This is element is needed to make sure react-viewport-list handles indexes correctly
                  return <WidgetInvisible withHeight={!!entry.parentId} />;
                }

                return (
                  <WidgetComponent
                    fieldRx={fieldRx}
                    context={context}
                    field={setRequiredProp(formField, validationMode)}
                    entry={entry}
                  />
                );
              }}
            </ErrorBoundary>
          );
        }}
      </ViewportList>
    );
  },
);
// Have to set Display name manually for forward refs https://julesblom.com/writing/component-displayname
Form.displayName = "Form";

const getErrorElement = (formField: FormField<any>, widgetId: string): JSX.Element => (
  <div className="border border-red-500 bg-red-100 p-2">
    <Text size="xs">{`Failed to render '${formField.properties.label_text || "Unknown"}' (${widgetId})`}</Text>
  </div>
);

const setRequiredProp = (formField: FormField<any>, validationMode?: ValidationMode): FormField<any> =>
  validationMode === "disabled"
    ? {
        ...formField,
        properties: { ...formField.properties, required: false },
      }
    : formField;

export default Form;
