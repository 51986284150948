import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import WidgetError from "./WidgetError";
import { InsufficientPermissionError } from "../../hooks/useCamera";
import InsufficientPermissionsModal from "../InsufficientPermissionsModal";
import logger from "../../utils/logger";
import { TextInput } from "../../storybook/components/TextInput/TextInput";

export interface WidgetBarcodeProperties {
  required: boolean;
  label_text: string;
}

const WidgetBarcode: Widget<WidgetBarcodeProperties, WidgetResult<string>> = (props) => {
  const { isDisabled, field, helpers } = useWidget(
    props.context,
    props.field,
    WidgetBarcode.validate,
    { onChange: "set", onBlur: "persist", valueFormat: (value) => value?.rawValue },
    props.fieldRx,
    props.entry,
  );
  const [showPermissionsModal, setPermissionsModal] = useState(false);
  const { startScan, isScanSupported, isScannerInstalling } = useBarcodeScanner();
  const { t } = useTranslation();

  const startScanning = async (): Promise<void> => {
    try {
      const result = await startScan();
      await helpers.persist(result);
    } catch (e) {
      if (e instanceof InsufficientPermissionError) {
        setPermissionsModal(true);
        return;
      }
      throw e;
    }
  };

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("BARCODE_FIELD")}`}>
      <TextInput
        {...field.props}
        inputRef={field.inputRef}
        type="text"
        disabled={isDisabled}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        rightBtn={{
          "aria-label": t("OPEN_BARCODE_SCANNER"),
          icon: "QrcodeIcon",
          size: "md",
          onClick: () => {
            startScanning().catch((e) => logger.error("Barcode scanner could not start", e));
          },
          disabled: !isScanSupported || isDisabled,
          loading: isScannerInstalling,
        }}
      />
      {showPermissionsModal && (
        <InsufficientPermissionsModal show={showPermissionsModal} onClose={() => setPermissionsModal(false)} />
      )}
    </article>
  );
};

WidgetBarcode.defaultValue = (field, defaultMeta: any): WidgetResult<string> => ({
  type: "string",
  meta: {
    widget: "barcode",
    ...defaultMeta,
  },
});

WidgetBarcode.validate = (val, properties, t): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }

  return undefined;
};

export default WidgetBarcode;
