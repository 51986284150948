import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { clone } from "rxdb";
import { FileResult, PinResultMeta } from "../../../types/Widget";
import { SubformEntry } from "../WidgetSubform";
import { ControlledField, WidgetHelpers } from "../../../hooks/useWidget";
import { WidgetResult } from "../../../types/Field";
import { calculateClickCoords } from "../../../utils/pinUtil";
import logger from "../../../utils/logger";
import { Text } from "../../../storybook/components/Text/Text";
import { Drawer } from "../../../storybook/components/Drawer/Drawer";

interface PinMoveDrawerProps {
  pin?: SubformEntry<PinResultMeta>;
  onClose: () => void;
  helpers: WidgetHelpers<FileResult>;
  imgUrl?: string;
  field: ControlledField<WidgetResult<FileResult>>;
}

const PinMoveDrawer: FC<PinMoveDrawerProps> = ({ pin, helpers, imgUrl, field, onClose }) => {
  const { t } = useTranslation();
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
  const [img, setImg] = useState<HTMLImageElement>();
  const imgRef = useCallback((ref: HTMLImageElement) => ref && setImg(ref), []);

  const handleMove = (e: any): void => {
    const entry = field.result?.entries!.find((i) => i.id === pin?.id);
    if (entry) {
      const newEntry = clone(entry);
      newEntry.meta.location = calculateClickCoords(e, originalDimensions, img);
      helpers.updateEntry(newEntry).catch((err) => logger.error("Can't update pin entry", err));
    }
    onClose();
  };
  return (
    <Drawer
      open={!!pin}
      header={{
        kind: "simple",
        title: t("MOVE_PIN", { pin: pin?.meta.order }),
        button: {
          kind: "icon",
          icon: "XIcon",
          onClick: () => onClose(),
        },
        content: (
          <Text className="pb-4 text-center" color="medium">
            {t("CHOOSE_PIN_LOCATION")}
          </Text>
        ),
      }}
      onClose={() => onClose()}
    >
      <TransformWrapper centerOnInit>
        <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div onClick={(e) => handleMove(e)}>
            <img
              src={imgUrl}
              ref={imgRef}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt={t("PIN_IMAGE")}
              onLoad={() =>
                setOriginalDimensions({
                  width: img?.naturalWidth ?? 0,
                  height: img?.naturalHeight ?? 0,
                })
              }
            />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </Drawer>
  );
};

export default PinMoveDrawer;
