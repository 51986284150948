import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import WidgetLabel from "./WidgetLabel";

export interface WidgetParagraphProperties {
  paragraph: string;
}

const WidgetParagraph: Widget<WidgetParagraphProperties, WidgetResult<void>> = ({ ...props }) => {
  const labelField = {
    uid: props.field.uid,
    widget: props.field.widget,
    properties: {
      label_text: props.field.properties.paragraph,
    },
  };
  return <WidgetLabel {...props} field={labelField} />;
};

WidgetParagraph.defaultValue = (_field, defaultMeta: any): WidgetResult<void> => ({
  type: "none",
  meta: {
    widget: "paragraph",
    ...defaultMeta,
  },
});

WidgetParagraph.validate = (): undefined => undefined;

export default WidgetParagraph;
