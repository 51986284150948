import { isNil, round } from "lodash-es";
import { DateTime } from "luxon";
import { FormField } from "../types/FormVersion";
import { Field } from "../types/Field";
import { getTermFields } from "./termUtil";
import { WidgetTimeDifferenceProps } from "../components/widgets/WidgetTimeDifference";

interface DateTimeValue {
  date: string;
  time: string;
}

export type FormTimeDifferenceResult = {
  field: Field;
  data: number | null;
};

export const seconds = (sec: number): number => sec * 1000;

export const minutes = (min: number): number => min * seconds(60);

export const hours = (hrs: number): number => hrs * minutes(60);

export const getTimeDifferenceResult = (
  formField: FormField<WidgetTimeDifferenceProps>,
  field: Field,
  fields: Field[],
): FormTimeDifferenceResult => {
  const { start, end } = formField.properties;

  if (isNil(start) || isNil(end)) {
    return { field, data: null };
  }
  const startTerm = getTermFields(fields, [start], field.entryId);
  const endTerm = getTermFields(fields, [end], field.entryId);
  if (startTerm.length === 0 || endTerm.length === 0) {
    return { field, data: null };
  }
  const startTime = startTerm[0].field.data as string | DateTimeValue;
  const endTime = endTerm[0].field.data as string | DateTimeValue;
  if (!startTime || !endTime) {
    return { field, data: null };
  }
  const msDifference = parseAsDate(endTime).diff(parseAsDate(startTime)).toMillis();
  return { field, data: round(msDifference / 36e5, 2) };
};

const parseAsDate = (input: string | DateTimeValue): DateTime<true> | DateTime<false> => {
  const date = typeof input === "string" ? { time: input, date: new Date().toISOString().slice(0, 10) } : input;
  return DateTime.fromISO(`${date.date}T${date.time}`);
};
