import { round } from "lodash-es";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { Widget } from "../../types/Widget";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import { FormField } from "../../types/FormVersion";
import WidgetError from "./WidgetError";
import { TextInput } from "../../storybook/components/TextInput/TextInput";
import { Label } from "../../storybook/components/Label/Label";
import { Message } from "../../storybook/components/Message/Message";

export interface WidgetTimeDifferenceProps {
  label_text: string;
  start: string;
  end: string;
}

const formatAsString = (inputHours: number, t: TFunction): string => {
  const milliseconds = round(inputHours * 60 * 60 * 1000, 0);
  const absMilliseconds = Math.abs(milliseconds);
  const minutes = Math.round((absMilliseconds / 6e4) % 60);
  const hours = Math.floor((absMilliseconds / 36e5) % 24);
  const days = Math.floor(absMilliseconds / 864e5);

  const array = [];
  days > 0 && array.push(days + t("DAY_ABBREVIATION"));
  hours > 0 && array.push(hours + t("HOUR_ABBREVIATION"));
  minutes > 0 && array.push(minutes + t("MINUTE_ABBREVIATION"));
  return `${round(milliseconds / 36e5, 2) + t("HOUR_ABBREVIATION")} (${array.join(" ")})`;
};

const findField = (fields: FormField<any>[], uid: string): FormField<any> | undefined =>
  fields.find((field) => field.uid === uid);

const WidgetTimeDifference: Widget<WidgetTimeDifferenceProps, WidgetResult<number>> = (props) => {
  const { t } = useTranslation();
  const { field } = useWidget(
    props.context,
    props.field,
    WidgetTimeDifference.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  const startField = findField(props.context.formVersion.fields, props.field.properties.start);
  const endField = findField(props.context.formVersion.fields, props.field.properties.end);

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  if (!props.field.properties.start || !props.field.properties.end) {
    return (
      <div>
        <Label label={props.field.properties.label_text} />
        <Message type="info" text={t("START_END_CONFIGURATION_REQUIRED")} />
      </div>
    );
  }

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("TIME_DIFFERENCE_FIELD")}`}>
      {field.result?.rawValue ? (
        <TextInput
          name={props.field.uid}
          type="text"
          label={props.field.properties.label_text}
          value={formatAsString(field.result?.rawValue, t)}
          disabled
        />
      ) : (
        <div>
          <Label label={props.field.properties.label_text} />
          <Message
            type="info"
            text={t("START_END_REQUIRED", {
              start: startField?.properties.label_text,
              end: endField?.properties.label_text,
            })}
          />
        </div>
      )}
    </article>
  );
};

WidgetTimeDifference.defaultValue = (field, defaultMeta): WidgetResult<number> => ({
  type: "number",
  meta: {
    widget: "timeDifference",
    ...defaultMeta,
  },
});

WidgetTimeDifference.validate = (): undefined => undefined;

export default WidgetTimeDifference;
