import { CameraSource, ImageOptions, Photo } from "@capacitor/camera";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import useCamera from "./useCamera";
import uuidv4 from "../utils/uuid";
import useFileHandler, { UploadResult } from "./useFileHandler";
import { useUploadManager } from "./useUploadManager";
import { startForegroundService, stopForegroundService } from "../utils/foregroundServiceUtil";
import { getCurrentAppInfo } from "../utils/deviceUtil";
import { fileTypes, getExtensionUri, isExtensionAllowedMimeType } from "../utils/fileUtil";
import type { UploadAttempt } from "../context/UploadManagerContext";

export type CameraOptions = {
  source: CameraSource;
  options?: Partial<ImageOptions>;
};

type UsePhotoHandlerResult = {
  addPhoto: (
    camera: CameraOptions,
    onPhotoFetched?: (photo: Photo) => Promise<void>,
    onUploadProgress?: (uploadPercentage: number) => void,
  ) => Promise<UploadResult>;
  current?: UploadAttempt;
  isUploading: boolean;
  isUploadingPhoto: boolean;
  showCompletedMessage: boolean;
};

const usePhotoHandler = (submissionId: string): UsePhotoHandlerResult => {
  const { t } = useTranslation();
  const { getPhoto } = useCamera();
  const { storeAndUploadFile } = useFileHandler();
  const { current, isUploading } = useUploadManager();
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false);
  const addPhoto = async (
    camera: CameraOptions,
    onPhotoFetched?: (photo: Photo) => Promise<void>,
    onUploadProgress?: (uploadPercentage: number) => void,
  ): Promise<UploadResult> => {
    try {
      await startService(t);
      const photo = await getPhoto(camera.source, camera.options);
      const id = uuidv4();

      const extension = await getExtensionUri(photo.webPath!);

      if (!extension || !isExtensionAllowedMimeType(extension, fileTypes.images)) {
        return { fileResult: { id, extension }, uploadStatus: "invalid_extension" };
      }

      if (onPhotoFetched) {
        await onPhotoFetched(photo);
      }
      setIsUploadingPhoto(true);
      const result = await storeAndUploadFile(photo.webPath!, submissionId, id, ({ loaded, total = 1 }) => {
        if (!onUploadProgress) {
          return;
        }
        onUploadProgress((loaded / total) * 100);
      });
      setIsUploadingPhoto(false);
      setShowCompletedMessage(true);
      setTimeout(() => setShowCompletedMessage(false), 4000);
      return result;
    } catch (e) {
      return await Promise.reject(e);
    } finally {
      await stopForegroundService();
    }
  };

  return { addPhoto, current, isUploading, isUploadingPhoto, showCompletedMessage };
};

const startService = async (t: TFunction): Promise<void> => {
  const appInfo = await getCurrentAppInfo();
  // For the user, this state is considered to be in the background, as we're killing this notification the moment that the user re-enters the application.
  // So the translation should reflect that.
  await startForegroundService(appInfo.name ?? "Forms", t("APP_RUNNING_IN_BACKGROUND", { appName: appInfo.name }));
};

export default usePhotoHandler;
