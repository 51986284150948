import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../storybook/components/Modal/Modal";
import useFileHandler, { UploadResult } from "../hooks/useFileHandler";
import { WidgetResult } from "../types/Field";
import { FileResult } from "../types/Widget";

interface FileUploadFailedModalProps {
  show: boolean;
  onClose: () => void;
  onRetry?: (result: UploadResult) => Promise<void>;
  widgetResult?: WidgetResult<FileResult>;
}

const FileUploadFailedModal: FC<FileUploadFailedModalProps> = ({ show, onClose, widgetResult, onRetry }) => {
  const { t } = useTranslation();
  const { retryUploadLocalFile } = useFileHandler();
  const [isRetrying, setRetrying] = useState(false);

  return (
    <Modal
      title={t("UPLOAD_FAILED_MODAL_TITLE")}
      content={{
        kind: "message",
        message: t("UPLOAD_FAILED_MODAL_MESSAGE"),
      }}
      open={show}
      onClose={onClose}
      buttons={[
        {
          label: t("CANCEL"),
          onClick: onClose,
        },
        {
          label: t("RETRY"),
          variant: "primary",
          icon: "RefreshIcon",
          loading: isRetrying,
          onClick: async (): Promise<void> => {
            if (!widgetResult?.rawValue || !widgetResult.meta.submissionId || !onRetry) {
              onClose();
              return;
            }
            try {
              setRetrying(true);
              const result = await retryUploadLocalFile(
                widgetResult.rawValue.id,
                widgetResult.meta.submissionId,
                widgetResult.rawValue?.name,
                widgetResult.rawValue?.extension,
              );
              await onRetry(result);
            } finally {
              setRetrying(false);
              onClose();
            }
          },
        },
      ]}
    />
  );
};

export default FileUploadFailedModal;
