import { Directory } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import mime from "mime";
import { isNil } from "lodash-es";
import { getUri, stat } from "./fileSystemUtil";
import { API_URL } from "../constants";

export type FileTypes = {
  [key: string]: string[];
  images: string[];
  video: string[];
  audio: string[];
  documents: string[];
};

export const fileTypes: FileTypes = {
  images: ["image/*"],
  video: ["video/*"],
  audio: [
    "audio/3gpp",
    "audio/3gpp2",
    "audio/aac",
    "audio/aiff",
    "audio/amr",
    "audio/midi",
    "audio/mp3",
    "audio/mp4",
    "audio/mpeg",
    "audio/mpeg3",
    "audio/ogg",
    "audio/opus",
    "audio/wav",
    "audio/wave",
    "audio/webm",
    "audio/x-aiff",
    "audio/x-m4a",
    "audio/x-m4b",
    "audio/x-m4p",
    "audio/x-midi",
    "audio/x-mp3",
    "audio/x-mpeg",
    "audio/x-mpeg3",
    "audio/x-pn-wav",
    "audio/x-wav",
  ],
  documents: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.text",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/rtf",
    "text/plain",
    "application/vnd.visio",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
};

export const base64ToBlob = async (base64: string): Promise<Blob> => (await fetch(base64)).blob();

export const filePathToUri = async (
  path: string,
  getWebFile: (path: string, fileName?: string) => Promise<string>,
  fileName: string | undefined,
): Promise<string> => {
  await stat({ path, directory: Directory.Data });
  if (Capacitor.getPlatform() === "web") {
    return getWebFile(path, fileName);
  }
  const fileUri = await getUri({ path, directory: Directory.Data });
  return Capacitor.convertFileSrc(fileUri.uri);
};

export const getExtensionUri = async (path: string): Promise<string | undefined> =>
  getExtensionBlob(await fetch(path).then((it) => it.blob()));

export const getExtensionBlob = (blob: Blob): string | undefined => mime.getExtension(blob.type) || undefined;

export const getFilePath = (
  username: string,
  customerId: number,
  submissionId: string,
  fileName: string,
  ext?: string,
): string => `${username}/${customerId}/submissionFiles/${submissionId}/${fileName}${ext ? "." : ""}${ext || ""}`;

export const isExtensionAllowedMimeType = (extension: string, allowedMimeTypes: string[]): boolean => {
  const mimeType = mime.getType(extension.toLowerCase());
  if (isNil(mimeType)) {
    return false;
  }

  return allowedMimeTypes.includes(mimeType) || allowedMimeTypes.includes(`${mimeType.split("/")[0]}/*`);
};

export const getRemoteResourceUrl = (remoteId: string): string => `${API_URL}/client/registrations/files/${remoteId}`;
