import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { ControlledField, WidgetHelpers } from "./useWidget";
import { WidgetResult } from "../types/Field";
import logger from "../utils/logger";
import { useFocussedField } from "../context/FocusContext";

type Options = {
  debounce?: number;
};

const useLocalValue = <T>(
  initialState: T | undefined,
  helpers: WidgetHelpers<T>,
  field: ControlledField<WidgetResult<T>>,
  options?: Options,
): [T | undefined, (result: T | undefined) => void] => {
  const [value, setValue] = useState<T | undefined>(initialState);
  const [lastPersistedValue, setLastPersistedValue] = useState<T | undefined>(initialState);
  const [valueDebounced] = useDebounceValue(value, options?.debounce ?? 0);
  const [initialized, setInitialized] = useState<boolean>(false);

  // System to ensure that the debounced value is actually stored and persisted before a submission can go through
  const { addTask } = useFocussedField();
  const [debouncePersistedPromise, setDebouncePersistedPromise] = useState<Promise<void> | undefined>(undefined);
  const [resolveDebouncePersistedPromise, setResolveDebouncePersistedPromise] = useState<(() => void) | undefined>(
    undefined,
  );

  useEffect(() => {
    if (debouncePersistedPromise) {
      addTask(debouncePersistedPromise).catch((e) => logger.error("Could not add task", e));
    }
  }, [debouncePersistedPromise, addTask]);

  const storeValue = (result: T | undefined): void => {
    setValue(result);
    if (!options?.debounce) {
      helpers.persist(result).catch((e) => logger.error("Could not persist value", e));
      setLastPersistedValue(result);
    } else if (!debouncePersistedPromise) {
      setDebouncePersistedPromise(
        new Promise<void>((resolve): void => {
          setResolveDebouncePersistedPromise(() => resolve);
        }).finally(() => {
          setDebouncePersistedPromise(undefined);
          setResolveDebouncePersistedPromise(undefined);
        }),
      );
    }
  };

  useEffect(() => {
    if (lastPersistedValue !== field.props.value) {
      // Don't update local value with out-of-date persisted value
      setValue(field.props.value);
    }
  }, [field.props.value, lastPersistedValue]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    } else if (options?.debounce) {
      helpers.persist(valueDebounced).catch((e) => logger.error("Could not persist debounced value", e));
      setLastPersistedValue(valueDebounced);

      if (resolveDebouncePersistedPromise) {
        resolveDebouncePersistedPromise();
      }
    }
  }, [valueDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, storeValue];
};

export default useLocalValue;
