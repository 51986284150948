import { useEffect, useRef, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { isErrorWidget, isFieldVisible } from "../../utils/formUtil";
import { FileResult, Widget } from "../../types/Widget";
import useClientRect from "../../hooks/useClientRect";
import { WidgetResult } from "../../types/Field";
import useWidget from "../../hooks/useWidget";
import WidgetHidden from "./WidgetHidden";
import uuidv4 from "../../utils/uuid";
import { validateUpload } from "../../utils/validationUtil";
import useFileHandler from "../../hooks/useFileHandler";
import useDrawer from "../../hooks/useDrawer";
import WidgetError from "./WidgetError";
import { FabricProps, initializeEvents } from "../../utils/drawingUtil";
import logger from "../../utils/logger";
import { Label } from "../../storybook/components/Label/Label";
import { Text } from "../../storybook/components/Text/Text";
import { Spinner } from "../../storybook/components/Spinner/Spinner";
import { IconAndTextButton } from "../../storybook/components/IconAndTextButton/IconAndTextButton";
import { Feedback } from "../../storybook/components/Feedback/Feedback";
import { Drawer } from "../../storybook/components/Drawer/Drawer";

export interface WidgetSignatureProperties {
  required: boolean;
  label_text: string;
  wide?: boolean;
  stroke_width?: number;
}

const WidgetSignature: Widget<WidgetSignatureProperties, WidgetResult<FileResult>> = ({ ...props }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useDrawer(props.field.uid);
  const [initialized, setInitialized] = useState(false);
  const [touched, setTouched] = useState(false);
  const { getFileUrl, storeAndUploadFile } = useFileHandler();
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [rect, wrapperRef] = useClientRect();
  const { editor, onReady } = useFabricJSEditor();
  const [signature, setSignature] = useState<string | undefined>(undefined);
  const { field, helpers, isDisabled } = useWidget(
    props.context,
    props.field,
    WidgetSignature.validate,
    { onChange: "none", onBlur: "none" },
    props.fieldRx,
    props.entry,
  );

  const closeAndReset = (): void => {
    setOpen(false);
    setTouched(false);
  };

  const fabricProps = useRef<FabricProps>({
    lastPosX: 0,
    lastPosY: 0,
    isDragging: false,
    isDown: false,
    activeMode: "draw",
    color: "black",
    fabricObject: undefined,
  });

  useEffect(() => {
    if (editor && !initialized) {
      setInitialized(true);

      editor.canvas.discardActiveObject();
      editor.canvas.renderAll();
      editor.canvas.isDrawingMode = true;
      editor.canvas.freeDrawingBrush.width = props.field.properties.stroke_width ?? 1;

      editor.canvas.on("mouse:up", () => {
        setTouched(true);
      });

      initializeEvents(editor.canvas, fabricProps.current, () => null);
    }
  }, [editor, initialized, props.field.properties.stroke_width]);

  useEffect(() => {
    const loadSignature = async (): Promise<void> => {
      if (!field.result?.rawValue?.id) {
        setSignature(undefined);
        return;
      }
      const fileUrl = await getFileUrl(field.result?.rawValue, props.context.submission.id);
      setSignature(Capacitor.convertFileSrc(fileUrl!));
    };
    loadSignature().catch((e) => logger.error("Could not load signature", e));
  }, [field.result?.rawValue?.remoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const clear = async (): Promise<void> => {
    if (editor?.canvas) {
      editor.canvas.clear();
      setTouched(false);
      setSignature(undefined);
    }
  };

  const save = async (): Promise<void> => {
    if (!editor) {
      closeAndReset();
      return;
    }

    const dataURL = editor.canvas.toDataURL();
    const id = uuidv4();

    setSignature(dataURL); // render locally first
    await helpers.persistWithUploadStatus({ id, name: `signature.png` }, "uploading");
    setPercentageUploaded(0);

    const result = await storeAndUploadFile(dataURL, props.context.submission.id, id);
    await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus);

    closeAndReset();
  };

  const buttonLabel = !isDisabled ? t("ADD_SIGNATURE") : t("NO_SIGNATURE_ADDED");

  if (!isFieldVisible(field)) {
    return <WidgetHidden />;
  }
  if (isErrorWidget(field)) {
    return <WidgetError field={props.field} widgetResult={field.result} />;
  }

  const remove = async (): Promise<void> => {
    setSignature(undefined);
    await helpers.persistWithUploadStatus(undefined, undefined);
  };

  return (
    <article aria-label={`${props.field.properties.label_text} - ${t("SIGNATURE_FIELD")}`} ref={field.inputRef}>
      <Label
        htmlFor={field.props.name}
        label={props.field.properties.label_text}
        required={props.field.properties.required}
        showClearBtn={!isDisabled && !!field.result?.rawValue}
        clearLabel={t("CLEAR")}
        onClear={remove}
      />
      {field.result?.rawValue ? (
        <div className="relative rounded-lg border-2">
          <img src={signature} alt={t("SIGNATURE")} className="mx-auto h-40" />
          {field.result?.meta?.uploadStatus === "uploading" && (
            <>
              <Text color="medium" size="2xs" weight="semibold" className="absolute left-6 top-6">
                {percentageUploaded.toFixed(0)}%
              </Text>
              <Spinner className="absolute left-4 top-4" />
            </>
          )}
        </div>
      ) : (
        <IconAndTextButton
          disabled={isDisabled}
          label={buttonLabel}
          icon="PencilIcon"
          block
          onClick={() => setOpen(true)}
        />
      )}
      {field.props.errorMessage && <Feedback status="error" message={field.props.errorMessage} />}

      <Drawer
        open={open}
        header={{
          kind: "simple",
          title: t("SIGNATURE"),
          button: {
            kind: "icon",
            icon: "XIcon",
            onClick: closeAndReset,
          },
        }}
        footer={{
          kind: "default",
          primaryButton: {
            label: t("SAVE"),
            onClick: save,
            disabled: !touched,
          },
          secondaryButton: {
            label: t("CLEAR"),
            onClick: clear,
            disabled: !touched,
          },
        }}
        onClose={() => {
          try {
            editor?.canvas.clear();
          } catch (e) {
            logger.warn("Can't clear canvas, already gone or not initialized yet", e);
          } finally {
            closeAndReset();
          }
        }}
        className="bg-gray-100"
      >
        <div className="flex size-full items-center justify-center">
          <div
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={
              props.field.properties.wide
                ? "aspect-h-1 aspect-w-2 my-auto w-full rounded-lg border-2 bg-white"
                : "aspect-h-1 aspect-w-1 my-auto w-full rounded-lg border-2 bg-white"
            }
            ref={wrapperRef}
          >
            {rect && (
              <FabricJSCanvas
                onReady={(canvas) => {
                  onReady(canvas);
                  setInitialized(false);
                }}
              />
            )}
          </div>
        </div>
      </Drawer>
    </article>
  );
};

WidgetSignature.defaultValue = (_field, defaultMeta): WidgetResult<FileResult> => ({
  type: "file",
  meta: {
    widget: "signature",
    ...defaultMeta,
  },
});

WidgetSignature.validate = (val, properties, t, meta): string | undefined => {
  const { required } = properties;
  if (required && !val) {
    return t("VALIDATION_REQUIRED");
  }
  return validateUpload(val, meta.uploadStatus, t);
};

export default WidgetSignature;
