import { ChangeEvent, FC, MutableRefObject, ReactNode, Ref, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useWindowSize } from "usehooks-ts";
import { Label } from "../Label/Label";
import { Text } from "../Text/Text";
import { Icon } from "../Icon/Icon";
import { Feedback } from "../Feedback/Feedback";

export interface RadioLabelImage {
  kind: "image";
  image: ReactNode;
}

export interface RadioLabelText {
  kind: "text";
  value: string;
}

export interface RadioOption {
  value: string;
  label: RadioLabelImage | RadioLabelText;
}

export interface RadioProps {
  className?: string;
  name: string;
  label: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  options: RadioOption[];
  layout?: "vertical" | "horizontal";
  activeStyle?: boolean;
  scroll?: boolean;
  onChange: (e: ChangeEvent<any>) => void;
  onClear?: () => void;
  clearLabel?: string;
  description?: string;
  errorMessage?: string;
  inputRef?: Ref<any>;
}

interface ScrollIndicators {
  left: boolean;
  right: boolean;
}

const SCROLL_OFFSET = 100;

export const Radio: FC<RadioProps> = ({
  required = false,
  name,
  label = "",
  value = "",
  disabled = false,
  options = [],
  layout = "vertical",
  activeStyle = true,
  scroll = true,
  onChange = (): void => {},
  onClear,
  clearLabel,
  description = "",
  errorMessage = "",
  className,
  inputRef,
  ...props
}) => {
  const optionWrapperRef: MutableRefObject<any> = useRef();
  const firstOptionRef: MutableRefObject<any> = useRef();
  const scrollIndicators = useHorizontalScrollIndicators(
    { left: false, right: false },
    firstOptionRef,
    optionWrapperRef,
  );

  const isSelected = (option: RadioOption): boolean => value === option.value;

  const getLabelClasses = (option: RadioOption): string =>
    classNames(
      "group relative flex min-h-12 select-none rounded-lg border-1.5 p-3",
      layout === "vertical" && "max-w-full",
      scroll && "flex-1",
      layout === "horizontal" && "shrink-0 justify-center whitespace-nowrap first:ml-0.5 last:mr-0.5",

      option.label.kind === "image" && "min-w-10",

      isSelected(option) && {
        "text-gray-500": disabled,
        "border-gray-400 bg-gray-200": disabled && layout === "horizontal" && activeStyle,

        "peer-focus-visible:border-blue-500": !disabled,
        "border-brand-300 bg-brand-50 hover:border-brand-400 active:border-brand-300 active:bg-brand-100":
          !errorMessage && !disabled && activeStyle,
        "text-gray-700": !disabled && activeStyle,

        "border-red-500 bg-red-50 hover:border-red-600 active:border-brand-300 active:bg-brand-100":
          errorMessage && layout === "vertical" && !disabled,
        "border-red-500 bg-red-200 hover:border-red-600 active:border-brand-300 active:bg-brand-100":
          errorMessage && layout === "horizontal" && !disabled,
      },

      !isSelected(option) && {
        "text-gray-500": true,
        "hover:text-gray-600 active:text-gray-700": !disabled,
        "bg-white border-gray-200 hover:border-gray-300 active:border-brand-300 active:bg-brand-100":
          !errorMessage && !disabled,

        "border-gray-300 bg-gray-100": disabled && layout === "horizontal",
        "border-red-500 bg-red-50 hover:border-red-600 active:border-brand-300 active:bg-brand-100":
          errorMessage && !disabled,
      },
      disabled
        ? { "cursor-not-allowed": true, "border-gray-300 bg-gray-100": layout === "vertical" }
        : {
            "cursor-pointer": true,
            "pointer:first-of-type:peer-focus-visible:border-blue-500": !value,
          },
    );

  const getRadioClasses = (option: RadioOption): string =>
    classNames(
      "mr-4 mt-1 size-4 shrink-0 rounded-full border-1.5",
      isSelected(option) && {
        "ring ring-inset ring-white": true,
        "border-brand-500 bg-brand-500 group-active:border-gray-200 group-active:bg-white": !disabled,
        "border-gray-500 bg-gray-500": disabled,
      },
      !isSelected(option) && {
        "border-gray-300": true,
        "group-active:border-brand-500 group-active:bg-brand-500 group-active:ring group-active:ring-inset group-active:ring-white":
          !disabled,
        "bg-gray-100": disabled,
      },
    );

  const optionWrapperClasses = classNames(
    layout === "vertical" && "flex flex-col gap-y-1",
    layout === "horizontal" && {
      "flex gap-2 justify-start scrollbar-none": true,
      "grid grid-flow-col": scrollIndicators.left || scrollIndicators.right,
      "overflow-x-auto": scroll,
    },
    !scroll && "flex-wrap",
  );

  const getScrollIndicatorClasses = (position: "left" | "right"): string =>
    classNames(
      "absolute mt-4 flex size-5 items-center justify-center rounded-full bg-brand-200 text-gray-700 shadow transition duration-300",
      "pointer:hover:bg-brand-300 pointer:hover:shadow-lg",
      position === "right" && {
        "right-0": true,
        "opacity-0 pointer-events-none": !scrollIndicators.right,
      },
      position === "left" && {
        "left-0": true,
        "opacity-0 pointer-events-none": !scrollIndicators.left,
      },
    );

  return (
    <div className={classNames(className, "relative")} ref={inputRef} {...props}>
      {(label || label === "") && (
        <Label
          as="legend"
          showClearBtn={!!value && !disabled}
          onClear={onClear}
          clearLabel={clearLabel}
          label={label}
          required={required}
          clickFocus={firstOptionRef}
        >
          {layout === "horizontal" && (
            <div className="relative z-10">
              <button
                aria-hidden
                tabIndex={-1}
                className={getScrollIndicatorClasses("left")}
                onClick={() => {
                  optionWrapperRef.current.scrollLeft -= optionWrapperRef.current.clientWidth - SCROLL_OFFSET;
                }}
              >
                <Icon name="ChevronLeftIcon" className="size-4" />
              </button>
              <button
                aria-hidden
                tabIndex={-1}
                className={getScrollIndicatorClasses("right")}
                onClick={() => {
                  optionWrapperRef.current.scrollLeft += optionWrapperRef.current.clientWidth - SCROLL_OFFSET;
                }}
              >
                <Icon name="ChevronRightIcon" className="size-4" />
              </button>
            </div>
          )}
          <div className={optionWrapperClasses} ref={optionWrapperRef}>
            {options.map((option, i) => (
              <div className={`relative flex${scroll ? " w-full" : ""}`} key={option.value}>
                <input
                  aria-label={option.label.kind === "text" ? option.label.value : option.value}
                  className="peer absolute size-0 opacity-0"
                  type="radio"
                  id={`${name}-${i}`}
                  disabled={disabled}
                  name={name}
                  value={option.value}
                  checked={isSelected(option)}
                  onChange={onChange}
                  tabIndex={(i === 0 && !value) || isSelected(option) ? 0 : -1}
                />
                <label
                  className={getLabelClasses(option)}
                  htmlFor={`${name}-${i}`}
                  {...(i === 0 && { ref: firstOptionRef })}
                >
                  {layout === "vertical" && <span className={getRadioClasses(option)} />}
                  {option.label.kind === "text" && <span className="min-w-0 break-words">{option.label.value}</span>}
                  {option.label.kind === "image" && (
                    <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
                      {option.label.image}
                    </div>
                  )}
                </label>
              </div>
            ))}
          </div>
          {errorMessage && <Feedback status="error" message={errorMessage} />}
          {description && !errorMessage && (
            <Text className="mt-1" size="sm" color="medium">
              {description}
            </Text>
          )}
        </Label>
      )}
    </div>
  );

  // eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
  function useHorizontalScrollIndicators(
    initialState: ScrollIndicators,
    element: MutableRefObject<any>,
    boundingElement: MutableRefObject<any>,
  ): ScrollIndicators {
    const [indicators, setIndicators] = useState<ScrollIndicators>(initialState);
    const { width } = useWindowSize();

    // Determine if scroll indicators should be visible, based on the
    // the horizontal scroll-position of the first option compared to the wrapper
    useScrollPosition(
      ({ currPos: { x } }) => {
        const el = boundingElement.current;
        const reachedEnd = Math.round(el.scrollWidth - el.scrollLeft) <= el.clientWidth;
        setIndicators({ left: x > 0, right: !reachedEnd });
      },
      [],
      element,
      false, // because we determine scroll-position in bounding (parent) element instead
      undefined,
      boundingElement,
    );

    // set scroll indicators correctly on first load
    useEffect(() => {
      if (layout === "vertical" || !boundingElement.current?.children) {
        return; // no need for scroll indicators
      }
      const scrollable = boundingElement.current.scrollWidth > boundingElement.current.clientWidth;
      if (scrollable) {
        setIndicators({ ...indicators, right: true });
        if (value) {
          // scroll selected option into position
          // eslint-disable-next-line no-restricted-syntax
          for (const child of boundingElement.current.children) {
            const input = child.firstElementChild as HTMLInputElement;
            if (input.checked) {
              // eslint-disable-next-line no-param-reassign
              boundingElement.current.scrollLeft =
                input.offsetLeft - boundingElement.current.offsetLeft - SCROLL_OFFSET;
            }
          }
        }
      } else {
        setIndicators({ left: false, right: false });
      }
      // Don't add `scrollIndicators` as deps, would result in endless loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return indicators;
  }
};
