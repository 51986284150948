import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from "react";
import axios from "axios";
import uuidv4 from "../../../utils/uuid";
import { seconds } from "../../../utils/timeUtil";
import { IMAGE_OPT_URL } from "../../../constants";
import useFileHandler from "../../../hooks/useFileHandler";
import { useFocussedField } from "../../../context/FocusContext";
import { WidgetHelpers } from "../../../hooks/useWidget";
import { UploadStatus } from "../../../types/Field";

export type ConvertStatus = UploadStatus | "pending" | "converting";
interface PdfImageUploadProps {
  submissionId: string;
  helpers: WidgetHelpers<any>;
  setConvertState: (value: ConvertStatus) => void;
  onUploadProgress?: (uploadPercentage: number) => void;
}

export type PdfImageUploadMethods = {
  onClick: () => void;
};

const PdfImageUpload = forwardRef<PdfImageUploadMethods, PdfImageUploadProps>(
  ({ submissionId, helpers, setConvertState, onUploadProgress }, ref) => {
    const { storeAndUploadFile } = useFileHandler();
    const { addTask } = useFocussedField();
    const inputFile = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => ({ onClick }));

    const onClick = (): void => {
      inputFile.current?.removeAttribute("capture");
      inputFile.current?.click();
    };

    const addSelectedPdf = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!event.currentTarget.files || !event.currentTarget.files[0]) {
        return;
      }
      try {
        setConvertState("converting");
        const convertedPdf = await convertPDF(event.currentTarget.files[0]);
        await uploadPdf(convertedPdf);
      } catch (e) {
        setConvertState("error");
      }
    };

    const convertPDF = async (file: File): Promise<string> => {
      const pdfObjectURL = URL.createObjectURL(file);
      const pdfBlob = await fetch(pdfObjectURL).then((r) => r.blob());

      const data = new FormData();
      data.append("file", pdfBlob);
      const response = await axios.post(`${IMAGE_OPT_URL}/pdf/convert`, data, {
        headers: { "Content-Type": "application/pdf" },
        responseType: "blob",
        timeout: seconds(90), // Overriding the default. File uploads can take longer on unstable connections
      });
      URL.revokeObjectURL(pdfObjectURL);
      return URL.createObjectURL(response.data);
    };

    const uploadPdf = async (objectUrl: string): Promise<void> => {
      setConvertState("uploading");
      const id = uuidv4();
      try {
        const result = await storeAndUploadFile(objectUrl, submissionId, id, ({ loaded, total = 1 }) => {
          if (!onUploadProgress) {
            return;
          }
          onUploadProgress((loaded / total) * 100);
        });
        URL.revokeObjectURL(objectUrl);
        await helpers.persistWithUploadStatus(result.fileResult, result.uploadStatus);
        setConvertState("uploaded");
      } catch (e) {
        setConvertState("error");
      }
    };

    return (
      <input
        className="hidden"
        type="file"
        accept="application/pdf"
        ref={inputFile}
        onChange={(e) => addTask(addSelectedPdf(e))}
      />
    );
  },
);

PdfImageUpload.displayName = "PdfUpload";

export default PdfImageUpload;
