import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { type RxChangeEvent, type RxCollection, type RxDocument, RxQuery } from "rxdb";
import { useFormContext } from "react-hook-form";
import { TFunction } from "i18next";
import { useMap } from "usehooks-ts";
import { getRememberedFieldId, removeWidgetVersionNumber } from "../utils/stringUtil";
import {
  FieldStatus,
  getCalculatedFieldId,
  getFieldStatus,
  getInitialValue,
  shouldCalculateDefault,
  isSameValue,
  updateHumanEdited,
  WidgetComponents,
} from "../utils/formUtil";
import WidgetError from "../components/widgets/WidgetError";
import { INITIAL_DATE } from "./useWidget";
import { Field, rxToForm } from "../types/Field";
import { AbstractForm, FieldProperties, FormField } from "../types/FormVersion";
import { Submission } from "../types/Submission";
import { RememberedFieldDocument } from "../utils/databaseUtil";
import useDeviceInfo from "./useDeviceInfo";
import logger from "../utils/logger";
import { calculateActions } from "../utils/ruleEvaluationUtil";
import useAuth, { Authorization } from "./useAuth";
import { SubmissionFormData } from "../components/Form";
import { useAsyncEffect } from "./useAsyncEffect";
import { noop } from "../utils/noop";
import type { FieldResult } from "./useFormRules";

const findFields = (
  fieldsCollection: RxCollection<Field>,
  submissionId: string,
  entryId?: string,
): RxQuery<Field, RxDocument<Field, {}>[], {}, unknown> =>
  fieldsCollection.find().where("submissionId").eq(submissionId).where("entryId").eq(entryId);

const getFields = async (
  fieldsCollection: RxCollection<Field>,
  submissionId: string,
  entryId?: string,
): Promise<RxDocument<Field, {}>[]> => findFields(fieldsCollection, submissionId, entryId).exec();

const isFieldCreationAllowed = (submission: Submission, entryId?: string): string | boolean | undefined =>
  !submission.task || entryId;

type UseFieldsResult = {
  initializedFields: Map<string, RxDocument<Field>> | void;
  visibility: Omit<Map<string, boolean>, "set" | "clear" | "delete">;
  isInitialized: boolean;
};

const useFields = (
  formVersion: AbstractForm,
  fieldProperties: FieldProperties,
  submission: Submission,
  rememberedFields?: RememberedFieldDocument[],
  readOnly?: boolean,
  shouldSync?: boolean,
  fieldsCollection?: RxCollection<Field>,
  setHumanEdited?: (value: boolean) => void,
  entryId?: string,
  parentId?: string,
): UseFieldsResult => {
  const { t } = useTranslation();
  const { authorization } = useAuth();
  const { id: deviceId } = useDeviceInfo();
  const {
    getValues,
    setValue,
    formState: { isLoading: isFormLoading },
  } = useFormContext<SubmissionFormData>();

  const [isLoading, setLoading] = useState(true);
  const [isWaitingForSync, setIsWaitingForSync] = useState(!!shouldSync);
  const [visibility, actions] = useMap<string, boolean>();
  const [initializedFields, setInitializedFields] = useState<Map<string, RxDocument<Field>>>();

  // update local field-visibility with field-changes that came in via sync
  const updateVisibility = useCallback(
    (input: RxChangeEvent<Field>) => {
      const field = input.documentData;
      const isCorrectScope = field.submissionId === submission.id && field.entryId === entryId;
      const visibilityEntry = visibility.get(field.formFieldId);
      const isVisible = !field.hidden;
      if (!isCorrectScope || visibilityEntry === isVisible) {
        return; // Not relevant or no changes
      }
      actions.set(field.formFieldId, isVisible);
    },
    [actions, entryId, submission.id, visibility],
  );
  useEffect(() => {
    const subscription = fieldsCollection?.$.subscribe(updateVisibility);
    return (): void | undefined => subscription?.unsubscribe();
  }, [fieldsCollection, updateVisibility]);

  // show syncing-indicator until all FormVersion-fields are stored in RxDB
  useEffect(() => {
    if (!fieldsCollection || !formVersion || !isWaitingForSync || !submission) {
      return noop;
    }

    if (isFieldCreationAllowed(submission, entryId)) {
      setIsWaitingForSync(false);
      return noop;
    }

    const subscription = findFields(fieldsCollection, submission.id, entryId).$.subscribe((fields) => {
      const synced = fields.length >= formVersion.fields.length;
      setIsWaitingForSync(!synced);
    });

    return (): void => subscription?.unsubscribe();
  }, [entryId, fieldsCollection, formVersion, isWaitingForSync, submission]);

  // initially load all fields and their visibility into FormContext (only once on mount)
  useAsyncEffect(
    async () => {
      if (!formVersion || !isLoading || isFormLoading || isWaitingForSync) {
        return;
      }
      if (!fieldsCollection) {
        setStaticVisibility();
        setLoading(false); // Everything is set already
        return;
      }
      const fields = await getFields(fieldsCollection, submission.id, entryId);
      try {
        await insertUninitializedFields(fields);
        const currentFields = await getFields(fieldsCollection, submission.id, entryId);
        const fieldMap = new Map(currentFields.map((i) => [i.id, i]));
        // Render existing fields in form controllers
        currentFields.forEach((field) => setValue(field.id, rxToForm(field)));
        // Set initial visibility
        actions.setAll(currentFields.filter((x) => x.entryId === entryId).map((x) => [x.formFieldId, !x.hidden]));
        // Set initial human edited status
        updateHumanEdited(currentFields, setHumanEdited);
        setInitializedFields(fieldMap);
      } finally {
        setLoading(false);
      }
    },
    async () => undefined,
    [fieldsCollection, formVersion, submission.id, isFormLoading, isWaitingForSync],
  );

  const setStaticVisibility = (): void => {
    const fields = getValues();
    actions.setAll(
      Object.values(fields)
        .filter((x) => x.meta.entryId === entryId)
        .map((x) => [x.meta.formFieldId, !x.meta.hidden]),
    );
  };

  const insertUninitializedFields = async (fields: Field[]): Promise<void> => {
    if (!fieldsCollection) {
      return;
    }
    try {
      const defaultFields = await Promise.allSettled(
        formVersion.fields.map((field, index) =>
          getDefaultField(field, index, submission, fields, deviceId, t, readOnly, rememberedFields, entryId, parentId),
        ),
      );
      const insertFields = defaultFields
        .filter((value) => value.status === "fulfilled" && !!value?.value)
        .map((value) => value.status === "fulfilled" && value.value) as Field[];
      const hiddenFields = await calculateHiddenFields(
        parentId,
        fieldsCollection,
        submission,
        insertFields,
        formVersion,
        fieldProperties,
        authorization,
      );

      await fieldsCollection.bulkUpsert(
        insertFields.map((field) => {
          const hiddenRule = hiddenFields.find((y) => y.id === field.id);
          return !hiddenRule
            ? field
            : {
                ...field,
                hidden: !!hiddenRule.hidden,
                evaluatedRules: [
                  {
                    type: "SET_VISIBILITY",
                    visible: !hiddenRule.hidden,
                  },
                ],
              };
        }),
      );
    } catch (e) {
      logger.error("Error inserting fields in form", e, {
        extra: { submissionId: submission.id, formId: submission.formId },
      });
    }
  };

  return {
    initializedFields,
    visibility,
    isInitialized: !isLoading && !isWaitingForSync,
  };
};

const calculateHiddenFields = async (
  parentId: string | undefined,
  fieldsCollection: RxCollection<Field>,
  submission: Submission,
  fields: Field[],
  formVersion: AbstractForm,
  fieldProperties: FieldProperties,
  authorization: Authorization,
): Promise<FieldResult[]> => {
  const parentFields = parentId ? await getParentFields(fieldsCollection, parentId, submission) : [];
  const formFields = fields.concat(parentFields);
  return calculateActions(submission, formFields, formVersion, fieldProperties, authorization).filter(
    (x) => x.hidden !== undefined,
  );
};

const getParentFields = async (
  fieldsCollection: RxCollection<Field>,
  parentId: string,
  submission: Submission,
): Promise<RxDocument<Field, {}>[]> => {
  const parentWidget = await fieldsCollection.findOne(parentId).exec();
  return getFields(fieldsCollection, submission.id, parentWidget?.entryId);
};

const getDefaultField = async (
  formField: FormField<any>,
  index: number,
  submission: Submission,
  fields: Field[],
  deviceId: string,
  t: TFunction,
  readOnly?: boolean,
  rememberedFields?: RememberedFieldDocument[],
  entryId?: string,
  parentId?: string,
): Promise<Field | undefined> => {
  const widgetId = removeWidgetVersionNumber(formField.widget);
  const WidgetComponent = WidgetComponents[widgetId]?.component || WidgetError;
  const fieldId = getCalculatedFieldId(formField.uid, submission.id, entryId, parentId);
  const fieldRx = fields.find((field) => field.id === fieldId);

  const status = getFieldStatus(submission, fieldRx, readOnly);

  if (status === FieldStatus.Final || status === FieldStatus.Draft) {
    return undefined;
  }
  // Replace default value with remembered data
  const rememberedField = formField.properties?.remember_input
    ? rememberedFields?.find((x) => x.id === getRememberedFieldId(formField.uid, submission.formId)) ??
      rememberedFields?.find((x) => x.id === fieldId) // DEV-4296: cleanup this fallback after some time
    : undefined;

  // Skip if remembered data isn't available, the field has been touched, or already has the same as current data
  // Continue if it's different to replace initial value
  const hasUntouchedDefaultValue = !fieldRx?.updatedBy || !rememberedField || isSameValue(rememberedField, fieldRx);
  if (status === FieldStatus.DefaultValue && hasUntouchedDefaultValue && !shouldCalculateDefault(formField, fieldRx)) {
    return undefined;
  }

  // Calculate initial field data
  const widgetResult = getInitialValue(fieldId, formField, submission, rememberedField, entryId, parentId);

  // Set new value
  return {
    id: fieldId,
    submissionId: submission.id,
    updatedAt: INITIAL_DATE,
    formFieldId: widgetResult.meta.formFieldId,
    dataName: widgetResult.meta.dataName,
    widget: widgetResult.meta.widget,
    type: widgetResult.type,
    data: widgetResult.rawValue,
    deviceId,
    hidden: false,
    compressed: widgetResult.meta.compressed,
    entryId: widgetResult.meta.entryId,
    parentId: widgetResult.meta.parentId,
    entries: [],
    status: "draft",
    evaluatedRules: [],
    order: index,
    error: WidgetComponent.validate(
      widgetResult.rawValue,
      formField.properties,
      t,
      widgetResult.meta,
      widgetResult.entries,
    ),
    _deleted: false,
  };
};

export default useFields;
